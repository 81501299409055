import type {APIFaction, APIGuild, APIPlayer, PunishmentProperties, PunishmentsBulk} from '@app/common/api'
import {FactionRole} from '@app/common/api'
import {GuildMemberFlags, Rank, dateFormatter, numberFormatter} from '@app/common/constants'
import {hasFlag} from '@app/common/utils'
import {Avatar} from '@app/components/Avatar/Avatar'
import styles from '@app/components/Dropdown/Dropdown.module.css'
import {MemberUpdateModal} from '@app/components/Member/MemberUpdateModal'
import {PlayerHoverCard} from '@app/components/Player/PlayerHoverCard'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import type {BoxProps} from '@chakra-ui/react'
import {Box, IconButton, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {EllipsisVerticalIcon} from '@heroicons/react/24/solid'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {useQueryClient} from '@tanstack/react-query'
import clsx from 'clsx'
import {m as motion} from 'framer-motion'
import React from 'react'
import {FaGavel, FaVolumeMute} from 'react-icons/fa'
import {Link as RouterLink} from 'react-router-dom'
import {useHover} from 'usehooks-ts'

const ACTIVE_STYLES = {
  bg: 'rgba(0, 0, 0, 0.2)',
  boxShadow: '0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%)',
  transform: 'translateY(-0.25rem)',
} as const

type MemberListProps = {
  currentRole: FactionRole | null
  entity: APIFaction | APIGuild
  members: (APIPlayer & {memberFlags?: number})[]
  punishments: PunishmentsBulk
  role: FactionRole
  type: 'faction' | 'guild'
}

export function MemberList(props: MemberListProps): React.JSX.Element {
  return (
    <Stack direction="row" flexWrap="wrap" gap={4} justify="center" maxW="3xl" pt={4}>
      {props.members.map(member => (
        <MemberListItem key={member.xuid} member={member} {...props} />
      ))}
    </Stack>
  )
}

type MemberListItemProps = MemberListProps & {
  member: APIPlayer & {memberFlags?: number}
}

function MemberListItem(props: MemberListItemProps): React.JSX.Element {
  const queryClient = useQueryClient()
  const [role, setRole] = React.useState<FactionRole | null>(null)
  const hoverRef = React.useRef(null)
  const isHovering = useHover(hoverRef)
  const punishments = props.punishments[props.member.xuid]
  const updateModal = useDisclosure()

  function getHandler(role: FactionRole | null): () => void {
    return () => {
      setRole(role)
      updateModal.onOpen()
    }
  }

  const showDropdown =
    props.currentRole != null && props.currentRole !== FactionRole.MEMBER && props.role !== props.currentRole
  const autoKickExempt = hasFlag(props.member.memberFlags ?? 0, GuildMemberFlags.AUTO_KICK_EXEMPT)

  return (
    <Box position="relative" ref={hoverRef}>
      {showDropdown && (
        <Dropdown
          items={[
            {
              label: 'Transfer Leadership',
              onSelect: getHandler(FactionRole.LEADER),
              disabled:
                props.currentRole !== FactionRole.LEADER ||
                (props.type === 'guild' && !props.member.ranks?.includes(Rank.LEGEND) && !props.member.titan),
            },
            {
              label: 'Promote to Officer',
              onSelect: getHandler(FactionRole.OFFICER),
              disabled: props.currentRole !== FactionRole.LEADER || props.role === FactionRole.OFFICER,
            },
            {
              label: 'Demote to Member',
              onSelect: getHandler(FactionRole.MEMBER),
              disabled: props.currentRole !== FactionRole.LEADER || props.role === FactionRole.MEMBER,
            },
            {
              label: autoKickExempt ? 'Remove Auto-Kick Exemption' : 'Add Auto-Kick Exemption',
              disabled: props.currentRole !== FactionRole.LEADER,
              onSelect: async () => {
                await api.patch(`/${props.type}s/${props.entity.name}/members/${props.member.xuid}`, {
                  role: props.role,
                  autoKickExempt: !autoKickExempt,
                })
                await queryClient.invalidateQueries({queryKey: [`${props.type}s`, props.entity.name]})
              },
            },
            {
              label: 'Kick',
              onSelect: getHandler(null),
              danger: true,
              disabled: props.role >= props.currentRole!,
            },
          ]}
        >
          <IconButton
            aria-label="Manage Member"
            colorScheme="gray"
            icon={<EllipsisVerticalIcon height={16} width={16} />}
            pos="absolute"
            right={-2}
            rounded="full"
            size="sm"
            top={-2}
            zIndex={1}
          />
        </Dropdown>
      )}

      {props.type === 'guild' && isHovering && (
        <Tooltip
          label={`Approximate guild XP earned, including from when they weren't members of ${props.entity.name}.`}
          padding={16}
        >
          <motion.div
            animate={{opacity: 1, scale: 1}}
            exit={{opacity: 0, scale: 0.5, transition: {duration: 0.2}}}
            initial={{opacity: 0, scale: 0.3}}
            style={{
              backgroundColor: '#ea580c',
              borderRadius: 9999,
              fontSize: 12,
              fontWeight: 600,
              left: -2,
              padding: 4,
              position: 'absolute',
              bottom: -2,
              zIndex: 1,
            }}
          >
            {numberFormatter.format(props.member.extra?.gxp ?? 0)} GXP
          </motion.div>
        </Tooltip>
      )}

      {punishments && (punishments.banned || punishments.muted) && (
        <PlayerPunishmentIndicator {...punishments} left={-2} pos="absolute" top={-2} zIndex={1} />
      )}

      <MemberUpdateModal
        {...updateModal}
        name={props.entity.name}
        player={props.member.name}
        role={role}
        type={props.type}
        xuid={props.member.xuid}
      />

      <PlayerHoverCard username={props.member.name}>
        <Stack
          _focusVisible={ACTIVE_STYLES}
          _hover={ACTIVE_STYLES}
          align="center"
          as={RouterLink}
          borderRadius="8px"
          justify="center"
          p={2}
          to={`/player/${props.member.name}`}
          transitionDuration="0.3s"
          transitionProperty="box-shadow, transform"
          transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
          w="140px"
        >
          <Avatar name={props.member.name} online={props.member.online} skinHash={props.member.skinHash} />
          <Text fontSize="sm" fontWeight={600} isTruncated textAlign="center">
            {props.member.name}
          </Text>
        </Stack>
      </PlayerHoverCard>
    </Box>
  )
}

type MenuItem = {
  label: string
  onSelect(): void
  danger?: boolean
  disabled?: boolean
  hidden?: boolean
}

type DropdownProps = {
  children: React.ReactNode
  items: MenuItem[]
}

export const DropdownMenuContent = React.forwardRef<HTMLDivElement, DropdownMenu.DropdownMenuContentProps>(
  ({children, ...props}, forwardedRef) => {
    return (
      <DropdownMenu.Portal>
        <DropdownMenu.Content ref={forwardedRef} {...props}>
          {children}
          <DropdownMenu.Arrow className={styles.arrow} />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    )
  },
)

export function Dropdown(props: DropdownProps): React.JSX.Element {
  return (
    <div>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>{props.children}</DropdownMenu.Trigger>
        <DropdownMenuContent className={styles.content}>
          {props.items
            .filter(item => !item.hidden)
            .map(item => (
              <DropdownMenu.Item
                className={clsx(styles.item, {[styles.danger as string]: item.danger})}
                disabled={Boolean(item.disabled)}
                key={item.label}
                onSelect={() => item.onSelect()}
              >
                {item.label}
              </DropdownMenu.Item>
            ))}
        </DropdownMenuContent>
      </DropdownMenu.Root>
    </div>
  )
}

type PunishmentType = 'Banned' | 'Muted'

function formatMessage(type: PunishmentType, validUntil: number | null, alt: boolean): string {
  const validity = validUntil && validUntil > 0 ? `until ${dateFormatter.format(validUntil * 1000)}` : 'permanently'
  return `${type} ${validity} ${alt ? ' (alt punishment)' : ''}`
}

function getPunishmentIcon(type: PunishmentType, iconSize: number) {
  const Icon = type === 'Banned' ? FaGavel : FaVolumeMute
  const color = type === 'Banned' ? 'white' : 'black'
  return <Icon color={color} size={iconSize} />
}

function getPunishmentColor(type: PunishmentType, alt: boolean) {
  if (type === 'Banned') return alt ? 'orange.400' : 'red.500'
  return alt ? 'yellow.200' : 'yellow.300'
}

export function PlayerPunishmentIndicator(
  props: BoxProps & PunishmentProperties & {iconSize?: number},
): React.JSX.Element | null {
  const {banned, bannedUntil, muted, mutedUntil, iconSize = 16, alt, ...rest} = props

  if (!banned && !muted) return null

  const punishmentType: PunishmentType = banned ? 'Banned' : 'Muted'
  const validUntil = banned ? bannedUntil : mutedUntil

  return (
    <Tooltip label={formatMessage(punishmentType, validUntil, alt)} padding={8}>
      <Box bg={getPunishmentColor(punishmentType, alt)} p={1} rounded="full" {...rest}>
        {getPunishmentIcon(punishmentType, iconSize)}
      </Box>
    </Tooltip>
  )
}
