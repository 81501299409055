import React from 'react'
import {Box, FormControl, FormHelperText, FormLabel, IconButton} from '@chakra-ui/react'
import {ClipboardDocumentIcon} from '@heroicons/react/24/solid'
import {FormType} from '@app/common/constants'

interface ResponseTemplatesProps {
  onClick: (s: string) => void
  formType: FormType
}

export default function ResponseTemplates(props: ResponseTemplatesProps): React.JSX.Element | null {
  let templates: React.JSX.Element[] = []

  switch (props.formType) {
    case FormType.TRAINEE_APPLICATION:
      templates = TraineeApplicationResponseTemplates(props)
      break
    case FormType.PLAYER_REPORT:
      templates = PlayerReportResponseTemplates(props)
      break
    case FormType.SERVER_BAN_APPEAL:
    case FormType.SERVER_MUTE_APPEAL:
      templates = ServerAppealResponseTemplates(props)
      break
    case FormType.FACTIONS_ROLLBACK:
    case FormType.SKYBLOCK_ROLLBACK:
      templates = RollbackResponseTemplates(props)
      break
    case FormType.DISCORD_REPORT:
      templates = DiscordReportResponseTemplates(props)
      break
    case FormType.DISCORD_BAN_APPEAL:
      templates = DiscordAppealResponseTemplates(props)
      break
    case FormType.YOUTUBE_APPLICATION:
    case FormType.PARTNER_APPLICATION:
      templates = YoutubeApplicationResponseTemplates(props)
      break
  }

  return templates.length > 0 ? (
    <FormControl>
      <FormLabel fontSize="sm" letterSpacing="tight">
        Response Templates
      </FormLabel>
      <FormHelperText>
        Below are the official template responses. Click on one to insert it into the review message.
      </FormHelperText>
      {templates}
    </FormControl>
  ) : null
}

function toString(lines: string[]): string {
  return lines.join('\n')
}

function TraineeApplicationResponseTemplates(props: ResponseTemplatesProps): React.JSX.Element[] {
  const list = [
    'Denied - Lack of detail',
    'Denied - punishment history',
    'Denied - Lack of professionalism',
    'Denied - AI/plagiarism',
    'Denied - Lack of quality',
    'Approved',
    'Escalated to admins',
  ]

  return list.map((title, index) =>
    ResponseTemplate(index, list.length, title, () => {
      let template = ''

      const currentDate = new Date()
      const oneMonthFromNow = new Date(currentDate.setMonth(currentDate.getMonth() + 1)).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })

      switch (index) {
        case 0:
          template = toString([
            "Thank you for taking the time to submit an application. Although we appreciate your interest in helping out, we've decided not to proceed due to insufficient detail in your submission.",
            '',
            'Here are some general tips to improve your application:',
            '- Proofread each response to catch any spelling or grammatical errors.',
            '- Review our rules and main links.',
            '- When answering open-ended questions, aim for 100 words or more.',
            '',
            "Please note that we cannot offer specific feedback on how to improve your application; this is a process you'll need to approach yourself.",
            '',
            `You are welcome to apply again on ${oneMonthFromNow}. Please keep in mind that any applications submitted before this date will be automatically denied.`,
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 1:
          template = toString([
            "Thank you for taking the time to submit an application. While we appreciate your interest in helping out, we've decided not to proceed due to your punishment history.",
            '',
            'Although there are no strict rules regarding the number of punishments or the time that must pass after one to be accepted, our team has determined that you are not suitable for the role at this time. Please be aware that we cannot provide a specific timeline for reapplying after a punishment, as each application is reviewed on an individual basis.',
            '',
            `You are welcome to apply again on ${oneMonthFromNow}. Please keep in mind that any applications submitted before this date will be automatically denied.`,
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 2:
          template = toString([
            "Thank you for taking the time to submit an application. While we appreciate your interest in helping out, we've decided not to proceed due to a lack of professionalism on the network.",
            '',
            'Our team is unable to provide specific examples of unprofessional behavior or suggestions for improvement—this is up to you to improve. However, we encourage you to carefully consider how you present yourself around the network if you plan to reapply in the future.',
            '',
            `You are welcome to apply again on ${oneMonthFromNow}. Please keep in mind that any applications submitted before this date will be automatically denied.`,
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 3:
          template = toString([
            "Thank you for taking the time to submit an application. While we appreciate your interest in helping out, we've decided not to proceed due to the detection of plagiarism.",
            '',
            "Our team uses multiple detector tools to verify that all answers are authentic and original. We won't be able to provide you with the exact plagiarism score or precisely where plagiarism was flagged, but we kindly ask that you refrain from using automated responses or any form of plagiarism in the future.",
            '',
            `You are welcome to apply again on ${oneMonthFromNow}. Please keep in mind that any applications submitted before this date will be automatically denied.`,
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 4:
          template = toString([
            "Thank you for taking the time to submit an application. Although we appreciate your interest in helping out, we've decided not to proceed due to a lack of quality in your submission.",
            '',
            'Here are some general tips to improve your application:',
            '- Proofread each response to catch any spelling or grammatical errors.',
            '- Review our rules and main links.',
            '- When answering open-ended questions, aim for 100 words or more.',
            '',
            "Please note that we cannot offer specific feedback on how to improve your application; this is a process you'll need to approach yourself.",
            '',
            `You are welcome to apply again on ${oneMonthFromNow}. Please keep in mind that any applications submitted before this date will be automatically denied.`,
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 5:
          template = toString([
            'Congratulations, your application has been Accepted! Please refer to the email you will receive shortly for further instructions.',
            '',
            'Thank you!',
          ])
          break
        case 6:
          template = toString([
            'Thank you for taking the time to submit an application. Your application has been selected to move to the next stage and is now awaiting confirmation from our admin team.',
            '',
            'This process may take up to 7 days, you will receive an email with an update.',
          ])
      }

      props.onClick(template)
    }),
  )
}

function PlayerReportResponseTemplates(props: ResponseTemplatesProps): React.JSX.Element[] {
  const list = [
    'Approved',
    'Approved - Already punished',
    'Denied - Username not clear',
    'Denied - Action not severe enough',
    'Denied - Not a punishable offence',
    'Denied - Insufficient evidence',
  ]

  return list.map((title, index) =>
    ResponseTemplate(index, list.length, title, () => {
      let template = ''

      switch (index) {
        case 0:
          template = toString([
            'After careful consideration, we have come to the decision to accept your report. Thank you for your efforts in keeping NetherGames rule-breaker free!',
            '',
            'The player you reported will be punished shortly in accordance with our enforcement system - https://support.nethergames.org/enforcement-system',
          ])
          break
        case 1:
          template = toString([
            'After careful consideration, we have come to the decision to accept your report. Thank you for your efforts in keeping NetherGames rule-breaker free!',
            '',
            'However, the player you reported has actually already been punished by a previous report or staff member in accordance with our enforcement system - https://support.nethergames.org/enforcement-system',
          ])
          break
        case 2:
          template = toString([
            'After careful consideration, we have come to the decision to deny your report. Our reasoning for this was that the username in the evidence was not clear enough in order for us to identify the player.',
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 3:
          template = toString([
            'After careful consideration, we have come to the decision to deny your report. Our reasoning for this is that the action committed in the evidence is not severe enough to warrant a punishment.',
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 4:
          template = toString([
            'After careful consideration, we have come to the decision to deny your report. Our reasoning for this is that the evidence does not appear to include a punishable offence - https://support.nethergames.org/enforcement-system',
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 5:
          template = toString([
            'After careful consideration, we have come to the decision to deny your report. Our reasoning for this is that the evidence provided is insufficient to prove that the player has committed the reported offence. If you are able to obtain more evidence, please create a new report accordingly.',
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
      }

      props.onClick(template)
    }),
  )
}

function ServerAppealResponseTemplates(props: ResponseTemplatesProps): React.JSX.Element[] {
  const list = ['Denied', 'Approved - Error in judgement', 'Approved - Generic']

  return list.map((title, index) =>
    ResponseTemplate(index, list.length, title, () => {
      let template = ''

      switch (index) {
        case 0:
          template = toString([
            'After careful consideration, we have come to the decision to deny your appeal. Our reasoning for this was that there appears to be no error in judgement on behalf of the staff team regarding your specific punishment.',
            '',
            'Please understand that in order to protect the privacy of the player who reported you, we are unable to provide evidence with your punishment. Our appeal system involves an impartial staff member verifying the evidence with the punishment and making a decision.',
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 1:
          template = toString([
            'After careful consideration, we have come to the decision to accept your appeal. Your punishment should shortly be removed across all servers of the NetherGames Network.',
            '',
            "We apologise for the error in judgement in issuing your punishment. If you had an active Titan subscription at any point during the duration of your punishment you can apply for an extension to your subscription by creating a 'Billing Issue' request.",
          ])
          break
        case 2:
          template = toString([
            'After careful consideration, we have come to the decision to accept your appeal. Your punishment should shortly be removed across all servers of the NetherGames Network.',
            '',
            "If you had an active Titan subscription at any point during the duration of your punishment you can apply for an extension to your subscription by creating a 'Billing Issue' request.",
          ])
          break
      }

      props.onClick(template)
    }),
  )
}

function RollbackResponseTemplates(props: ResponseTemplatesProps): React.JSX.Element[] {
  const list = ['Approved', 'Denied - Insufficient rule-breaker evidence']

  return list.map((title, index) =>
    ResponseTemplate(index, list.length, title, () => {
      let template = ''

      switch (index) {
        case 0:
          template = toString([
            'Your rollback request has been accepted and your inventory should be available shortly using the /rollback command.',
          ])
          break
        case 1:
          template = toString([
            'Your rollback request has been denied due to the evidence being insufficient to punish the player. For us to issue a rollback, you will need to provide valid evidence of you dying to a specific rule-breaker while having the inventory you would like to restore.',
          ])
          break
      }

      props.onClick(template)
    }),
  )
}

function DiscordReportResponseTemplates(props: ResponseTemplatesProps): React.JSX.Element[] {
  const list = [
    'Approved',
    'Denied - Not a punishable offence',
    'Denied - Action not severe enough',
    'Denied - Outside of Discord server',
  ]

  return list.map((title, index) =>
    ResponseTemplate(index, list.length, title, () => {
      let template = ''

      switch (index) {
        case 0:
          template = toString([
            'After careful consideration, we have come to the decision to accept your report. Thank you for your efforts to keeping NetherGames a safe community!',
            '',
            "A warning will be applied to the player's Discord account shortly in accordance with our enforcement system - https://support.nethergames.org/discord-server-regulations",
          ])
          break
        case 1:
          template = toString([
            'After careful consideration, we have come to the decision to deny your report. Our reasoning for this is that the evidence does not appear to include a punishable offence - https://support.nethergames.org/discord-server-regulations',
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 2:
          template = toString([
            'After careful consideration, we have come to the decision to deny your report. Our reasoning for this is that the action committed in the evidence is not severe enough to warrant a punishment.',
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 3:
          template = toString([
            "After careful consideration, we have come to the decision to deny your report. Our reasoning for this is that the action committed in the evidence is not inside our Discord server and thus we are unable to verify the authenticity of the message. It is recommended you report this message to Discord's Trust & Safety Team.",
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
      }

      props.onClick(template)
    }),
  )
}

function DiscordAppealResponseTemplates(props: ResponseTemplatesProps): React.JSX.Element[] {
  const list = ['Denied', 'Approved - Second chance', 'Approved - Error in judgement']

  return list.map((title, index) =>
    ResponseTemplate(index, list.length, title, () => {
      let template = ''

      switch (index) {
        case 0:
          template = toString([
            'After careful consideration, we have come to the decision to deny your appeal. Our reasoning for this was that there appears to be no error in judgement on behalf of the staff team regarding your specific punishment.',
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 1:
          template = toString([
            'After careful consideration, we have come to the decision to accept your appeal. Your punishment should shortly be removed from our Discord server.',
            '',
            'Please note that this will be your final chance to prove you are willing to adhere to the Discord server rules.',
          ])
          break
        case 2:
          template = toString([
            'After careful consideration, we have come to the decision to accept your appeal. Your punishment should shortly be removed from our Discord server.',
            '',
            'We apologise for the error in judgement in issuing your punishment.',
          ])
          break
      }

      props.onClick(template)
    }),
  )
}

function YoutubeApplicationResponseTemplates(props: ResponseTemplatesProps): React.JSX.Element[] {
  const list = [
    'Denied - YouTube Shorts',
    'Denied - Missing NetherGames content',
    'Denied - Limited views',
    'Approved - YouTube rank',
  ]

  return list.map((title, index) =>
    ResponseTemplate(index, list.length, title, () => {
      let template = ''

      const currentDate = new Date()
      const oneMonthFromNow = new Date(currentDate.setMonth(currentDate.getMonth() + 1)).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })

      switch (index) {
        case 0:
          template = toString([
            "Your interest in applying for our program is appreciated, but unfortunately, your application has been denied because you post mainly YouTube shorts, which we don't consider as part of the application.",
            '',
            `You are welcome to apply again on ${oneMonthFromNow}. Please keep in mind that any applications submitted before this date will be automatically denied.`,
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 1:
          template = toString([
            'Your interest in applying for our program is appreciated, but unfortunately, your application has been denied due to a limited amount of recent content posted about the NetherGames Network.',
            '',
            `You are welcome to apply again on ${oneMonthFromNow}. Please keep in mind that any applications submitted before this date will be automatically denied.`,
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 2:
          template = toString([
            'Your interest in applying for our program is appreciated, but unfortunately, your application has been denied due to a limited amount of views on your recent videos.',
            '',
            `You are welcome to apply again on ${oneMonthFromNow}. Please keep in mind that any applications submitted before this date will be automatically denied.`,
            '',
            'We understand this may not be the outcome you were hoping for, but our decision is final.',
          ])
          break
        case 3:
          template = toString([
            'Thanks for applying for a YouTube rank on the NetherGames Network.',
            '',
            'After reviewing your application, we have issued you the YouTube rank in-game. You now have Legend rank permissions, including custom nickname permissions. The YouTube rank also grants the permission to post NetherGames-related videos in the #media channel on our Discord server.',
            '',
            'We look forward to seeing the content you publish!',
          ])
          break
      }

      props.onClick(template)
    }),
  )
}

function ResponseTemplate(index: number, size: number, title: string, onClick: () => void): React.JSX.Element {
  return (
    <Box
      display="flex"
      alignItems="center"
      mt={index === 0 ? '8px' : '0px'}
      borderWidth="1px"
      borderColor="inherit"
      borderTopRadius={index === 0 ? 'md' : 'none'}
      borderBottomRadius={index === size - 1 ? 'md' : 'none'}
      key={index}
      p="2"
    >
      <Box flex="1" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {title}
      </Box>

      <IconButton
        aria-label="Enter template"
        h="1.75rem"
        icon={<ClipboardDocumentIcon height={16} width={16} />}
        onClick={async () => onClick()}
        size="sm"
        ml="2"
      />
    </Box>
  )
}
