import type {APIApplication} from '@app/common/api'
import {AdminApplicationFlags, useApplication} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {AlertDialog} from '@app/components/Modal/AlertDialog'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import {ClipboardDocumentCheckIcon, ClipboardDocumentIcon, QuestionMarkCircleIcon} from '@heroicons/react/24/solid'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {FormProvider, useForm, useFormContext} from 'react-hook-form'
import {toast} from 'react-hot-toast'
import TextareaAutosize from 'react-textarea-autosize'
import {useCopyToClipboard} from 'usehooks-ts'
import {shallow} from 'zustand/shallow'

export default function ApplicationModal(): React.JSX.Element {
  const methods = useForm()
  const queryClient = useQueryClient()
  const [applicationId, setApplicationId] = useOverlayStore(
    state => [state.applicationId, state.setApplicationId],
    shallow,
  )

  async function onSubmit(data: any): Promise<void> {
    try {
      await api.patch(`/applications/${applicationId}`, data)
      await queryClient.invalidateQueries({queryKey: ['applications', applicationId]})
      toast.success('Application updated')
    } catch {
      toast.error('Failed to update application')
    }
  }

  return (
    <FormProvider {...methods}>
      <Modal
        footer={
          <ModalFooter>
            <Button isLoading={methods.formState.isSubmitting} onClick={methods.handleSubmit(onSubmit)}>
              Save
            </Button>
          </ModalFooter>
        }
        header="Edit Application"
        isOpen={Boolean(applicationId)}
        onClose={() => setApplicationId(null)}
      >
        <ApplicationIdContent applicationId={applicationId!} />
      </Modal>
    </FormProvider>
  )
}

function ApplicationIdContent({applicationId}: {applicationId: string}): React.JSX.Element {
  const [copiedValue, copy] = useCopyToClipboard()
  const [isResettingToken, setIsResettingToken] = React.useState(false)
  const [showToken, setShowToken] = React.useState(false)
  const {data} = useApplication(applicationId)
  const deleteModal = useDisclosure()
  const methods = useFormContext()
  const queryClient = useQueryClient()

  // biome-ignore lint/correctness/useExhaustiveDependencies: this is fine
  React.useEffect(() => {
    if (data) methods.reset(data)
  }, [data])

  if (!data)
    return (
      <ModalBody align="center" as={Stack} h="full" justify="center" my={16}>
        <Spinner />
      </ModalBody>
    )

  return (
    <ModalBody as={Stack} gap="8px">
      <FormControl as={Stack}>
        <SmallHeading>App ID</SmallHeading>
        <Input isReadOnly value={data.id} />
      </FormControl>

      <FormControl as={Stack}>
        <SmallHeading>Name</SmallHeading>
        <Input {...methods.register('name', {required: true})} maxLength={32} minLength={2} />
        <FormErrorMessage>{(methods.formState.errors as any).name?.message}</FormErrorMessage>
      </FormControl>

      <FormControl as={Stack}>
        <SmallHeading>Description</SmallHeading>
        <Textarea {...methods.register('description', {required: true})} as={TextareaAutosize} maxLength={255} />
        <FormErrorMessage>{(methods.formState.errors as any).description?.message}</FormErrorMessage>
      </FormControl>

      <FormControl as={Stack}>
        <SmallHeading>Token</SmallHeading>

        <InputGroup size="md">
          <Input isReadOnly name="token" pr="3rem" value={showToken ? data.token : '●'.repeat(data.token.length)} />
          <InputRightElement w="3rem">
            <IconButton
              aria-label="Copy token"
              h="1.75rem"
              icon={
                copiedValue ? (
                  <ClipboardDocumentCheckIcon height={16} width={16} />
                ) : (
                  <ClipboardDocumentIcon height={16} width={16} />
                )
              }
              onClick={async () => copy(data.token)}
              size="sm"
            />
          </InputRightElement>
        </InputGroup>

        <FormHelperText>
          <Link color="orange.300" onClick={() => setShowToken(!showToken)}>
            {showToken ? 'Hide' : 'Reveal'} this field
          </Link>
        </FormHelperText>
      </FormControl>

      <Stack>
        <Button
          colorScheme="orange"
          isLoading={isResettingToken}
          onClick={async () => {
            setIsResettingToken(true)
            try {
              await api.post(`/applications/${applicationId}/reset`)
              await queryClient.invalidateQueries({queryKey: ['applications', applicationId]})
              toast.success('Reset application token')
            } catch {
              toast.error('Failed to reset application token')
            }

            setIsResettingToken(false)
          }}
          variant="outline"
        >
          Reset Token
        </Button>
      </Stack>

      <FormControl as={Stack}>
        <Stack align="center" direction="row" spacing="1">
          <SmallHeading>Flags</SmallHeading>
          <Tooltip label="These flags can only be changed by NetherGames staff.">
            <QuestionMarkCircleIcon height={16} width={16} />
          </Tooltip>
        </Stack>

        <CheckboxGroup
          defaultValue={Object.keys(AdminApplicationFlags).filter(key => data.flags & AdminApplicationFlags[key]!)}
          isDisabled
        >
          <Stack>
            {Object.keys(AdminApplicationFlags).map(key => (
              <Checkbox key={key} name="flags" value={key}>
                {key}
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      </FormControl>

      <Stack>
        <SmallHeading>Danger Zone</SmallHeading>
        <Button colorScheme="red" onClick={() => deleteModal.onOpen()} variant="outline">
          Delete App
        </Button>
      </Stack>

      <ApplicationDeleteModal {...deleteModal} application={data} />
    </ModalBody>
  )
}

type ApplicationDeleteModalProps = {
  isOpen: boolean
  onClose(): void
  application: APIApplication
}

export function ApplicationDeleteModal(props: ApplicationDeleteModalProps): React.JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const queryClient = useQueryClient()
  const setApplicationId = useOverlayStore(state => state.setApplicationId)

  return (
    <AlertDialog
      content={
        <>
          Are you sure you want to permanently delete <strong>{props.application.name}</strong>? This action cannot be
          undone.
        </>
      }
      isLoading={isLoading}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onPrimary={async () => {
        setIsLoading(true)
        try {
          await api.delete(`/applications/${props.application.id}`)
          await queryClient.invalidateQueries({queryKey: ['applications']})
          toast.success('Application deleted')
          setApplicationId(null)
        } catch {
          toast.error('Something went wrong! Please try again later.')
        }

        setIsLoading(false)
        props.onClose()
      }}
      primary="Delete App"
      title="Danger, Will Robinson!"
    />
  )
}
