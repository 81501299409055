import type {APIStaffDirectoryEntry} from '@app/common/api'
import {useCurrentUser, useStaffAlumni, useStaffDirectory} from '@app/common/api'
import {dateFormatter} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {isAdmin} from '@app/common/utils'
import {Avatar} from '@app/components/Avatar/Avatar'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {useReplaceState} from '@app/hooks/useReplaceState'
import {EditIcon} from '@chakra-ui/icons'
import {Box, Button, Flex, Heading, IconButton, Link, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {ArrowTopRightOnSquareIcon, LockClosedIcon} from '@heroicons/react/24/solid'
import {formatDistance} from 'date-fns'
import type React from 'react'
import {FaDiscord} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import {shallow} from 'zustand/shallow'

export default function StaffDirectoryModal(): React.JSX.Element {
  const [staffDirectoryOpen, setStaffDirectoryOpen] = useOverlayStore(
    state => [state.staffDirectoryOpen, state.setStaffDirectoryOpen],
    shallow,
  )
  return (
    <Modal header="Staff Directory" isOpen={staffDirectoryOpen} onClose={() => setStaffDirectoryOpen(false)}>
      <StaffDirectoryModalContent />
    </Modal>
  )
}

function StaffDirectoryModalContent(): React.JSX.Element {
  useReplaceState('/staff-directory')
  const {data: alumni} = useStaffAlumni()
  const {data: directory} = useStaffDirectory()
  const staffOverview = useDisclosure()
  const seenXuids = new Set()
  const uniqueStaff = Object.values(directory?.staff ?? {}).reduce<APIStaffDirectoryEntry[]>((acc, staff) => {
    for (const entry of staff) {
      if (seenXuids.has(entry.xuid)) continue
      seenXuids.add(entry.xuid)
      acc.push(entry)
    }

    return acc
  }, [])
  const mfaEnabledCount = uniqueStaff.filter(entry => entry.mfaEnabled).length

  if (!directory)
    return (
      <ModalBody my={16}>
        <Spinner />
      </ModalBody>
    )

  return (
    <>
      <StaffOverviewModal uniqueStaff={uniqueStaff} {...staffOverview} />
      <ModalBody as={Stack} direction="column" gap="4px">
        <Stack align="center" direction="row" justify="center" spacing={1}>
          <LockClosedIcon fill="var(--chakra-colors-green-300)" height={16} width={16} />
          <Text color="gray.400" fontSize="sm">
            {mfaEnabledCount}/{uniqueStaff.length} ({Math.round((mfaEnabledCount / uniqueStaff.length) * 100)}%) of
            staff have MFA enabled
          </Text>
        </Stack>

        <Box>
          <Button
            colorScheme="orange"
            leftIcon={<ArrowTopRightOnSquareIcon height={16} width={16} />}
            onClick={() => staffOverview.onOpen()}
            size="sm"
            w="full"
          >
            Staff Overview (w/ Discord Tags)
          </Button>
        </Box>

        {Object.entries(directory.staff).map(([group, staff]) => {
          if (staff.length === 0) return null
          return (
            <Stack direction="column" fontSize="sm" gap="4px" key={group}>
              <Heading size="sm">
                {group} — {Object.values(staff).length}
              </Heading>
              <Stack direction="column" fontSize="sm" gap="4px">
                {staff.map(entry => (
                  <StaffDirectoryEntry entry={entry} key={entry.player} />
                ))}
              </Stack>
            </Stack>
          )
        })}

        {alumni && (
          <Stack direction="column" fontSize="sm" gap="4px">
            <Heading size="sm">Alumni — {alumni.length}</Heading>
            <Stack direction="column" fontSize="sm" gap="4px">
              {alumni.map(entry => (
                <StaffDirectoryEntry entry={entry} key={entry.player} />
              ))}
            </Stack>
          </Stack>
        )}
      </ModalBody>
    </>
  )
}

type StaffOverviewModalProps = {
  isOpen: boolean
  onClose(): void
  uniqueStaff: APIStaffDirectoryEntry[]
}

function StaffOverviewModal(props: StaffOverviewModalProps): React.JSX.Element {
  return (
    <Modal fullscreen header="Staff Overview" isOpen={props.isOpen} onClose={props.onClose}>
      <ModalBody as={Stack} direction="column" gap="4px" userSelect="text">
        {props.uniqueStaff.map(entry => (
          <Text fontSize="sm" key={entry.player}>
            <strong>{entry.player}</strong> ({entry.xuid}) - <strong>{entry.discordTag}</strong> ({entry.discordId})
          </Text>
        ))}
      </ModalBody>
    </Modal>
  )
}

function StaffDirectoryEntry({entry}: {entry: APIStaffDirectoryEntry}): React.JSX.Element {
  const {data: user} = useCurrentUser()
  const navigate = useNavigate()
  const setAdminPlayer = useOverlayStore(state => state.setAdminPlayer)
  const setStaffDirectoryOpen = useOverlayStore(state => state.setStaffDirectoryOpen)

  return (
    <Flex align="center">
      <Avatar name={entry.player} online={entry.online} size={32} skinHash={entry.skinHash} />
      <Box flex="1" ml={3}>
        <Stack align="center" direction="row">
          <Link
            onClick={event => {
              if (event.metaKey || event.ctrlKey) {
                window.open(`/player/${entry.player}`, '_blank')
              } else {
                navigate(`/player/${entry.player}`)
                setStaffDirectoryOpen(false)
              }
            }}
          >
            <Heading fontSize="md">{entry.player}</Heading>
          </Link>
          {entry.discordId && entry.discordTag && (
            <Tooltip label={`Open ${entry.discordTag} (desktop app only)`} padding={8}>
              <a href={`discord:/users/${entry.discordId}`}>
                <FaDiscord fill="#5865f2" size={16} />
              </a>
            </Tooltip>
          )}
          {entry.mfaEnabled && (
            <Tooltip label="MFA Enabled" padding={8}>
              <LockClosedIcon fill="var(--chakra-colors-green-300)" height={16} width={16} />
            </Tooltip>
          )}
        </Stack>
        {entry.online ? (
          <Text color="gray.400" fontSize="sm">
            Currently online at <strong>{entry.lastServer}</strong>
          </Text>
        ) : (
          <Text color="gray.400" fontSize="sm">
            Last seen at{' '}
            <Tooltip label={formatDistance(new Date(entry.lastSeen), Date.now(), {addSuffix: true})}>
              <strong>{dateFormatter.format(new Date(entry.lastSeen))}</strong>
            </Tooltip>
          </Text>
        )}
      </Box>
      {isAdmin(user) && (
        <Box>
          <Tooltip label="Edit Player (Admin)" padding={8}>
            <IconButton
              aria-label="Edit Player (Admin)"
              icon={<EditIcon />}
              onClick={() => setAdminPlayer(entry.player)}
              rounded="full"
              size="sm"
            />
          </Tooltip>
        </Box>
      )}
    </Flex>
  )
}
