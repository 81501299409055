import type {ServerUpdate} from '@app/common/api'
import {useServerUpdatesAdmin} from '@app/common/api'
import {dateFormatter} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import {PencilSquareIcon, PlusIcon, TrashIcon} from '@heroicons/react/24/solid'
import {ImageIcon} from '@radix-ui/react-icons'
import {useQueryClient} from '@tanstack/react-query'
import {formatDistance} from 'date-fns'
import {m as motion} from 'framer-motion'
import React from 'react'
import {toast} from 'react-hot-toast'
import TextareaAutosize from 'react-textarea-autosize'
import {shallow} from 'zustand/shallow'

export default function AdminServerUpdatesModal(): React.JSX.Element {
  const [adminServerUpdatesOpen, setAdminServerUpdatesOpen] = useOverlayStore(
    state => [state.adminServerUpdatesOpen, state.setAdminServerUpdatesOpen],
    shallow,
  )

  return (
    <Modal header="Server Changelog" isOpen={adminServerUpdatesOpen} onClose={() => setAdminServerUpdatesOpen(false)}>
      <AdminServerUpdatesModalContent />
    </Modal>
  )
}

function AdminServerUpdatesModalContent(): React.JSX.Element {
  const createModal = useDisclosure()
  const {data: updates} = useServerUpdatesAdmin()
  return (
    <ModalBody as={Stack} gap="8px">
      <ServerUpdateCreateModal {...createModal} />
      <Button
        colorScheme="orange"
        leftIcon={<PlusIcon height={16} width={16} />}
        onClick={() => createModal.onOpen()}
        size="sm"
      >
        Create Changelog Item
      </Button>
      {updates?.map(update => (
        <ServerUpdateRenderer key={update.id} update={update} />
      ))}
    </ModalBody>
  )
}

function ImageSelector({
  fileRef,
  updateThumbnail,
}: {
  fileRef: React.RefObject<HTMLInputElement>
  updateThumbnail: string
}) {
  const [isHovering, setIsHovering] = React.useState(false)
  return (
    <div
      onClick={() => fileRef.current?.click()}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          fileRef.current?.click()
        }
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      role="button"
      style={{
        alignItems: 'center',
        aspectRatio: '16/9',
        backgroundImage: updateThumbnail ? `url(${updateThumbnail})` : undefined,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        border: '1px solid #4A5568',
        borderRadius: '8px',
        cursor: 'pointer',
        display: 'flex',
        height: 'auto',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
      }}
      tabIndex={0}
    >
      {updateThumbnail && isHovering && (
        <motion.div
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          initial={{opacity: 0}}
          style={{
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            height: '100%',
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
          transition={{duration: 0.2}}
        >
          <ImageIcon height={32} width={32} />
          <Text>Press to change thumbnail</Text>
        </motion.div>
      )}

      {!updateThumbnail && (
        <Flex alignItems="center" direction="column" gap="4px" justifyContent="center">
          <ImageIcon height={32} width={32} />
          <Text>Press to select a thumbnail</Text>
        </Flex>
      )}
    </div>
  )
}

function ServerUpdateCreateModal({isOpen, onClose}: {isOpen: boolean; onClose(): void}): React.JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const [updateContent, setUpdateContent] = React.useState('')
  const [updateThumbnail, setUpdateThumbnail] = React.useState('')
  const [updateTitle, setUpdateTitle] = React.useState('')
  const fileRef = React.useRef<HTMLInputElement>(null)
  const queryClient = useQueryClient()

  function handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0]
    if (!file) return
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const result = reader.result
      if (typeof result === 'string') {
        setUpdateThumbnail(result)
      }
    })
    reader.readAsDataURL(file)
  }

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            colorScheme="orange"
            isDisabled={!updateContent || !updateTitle}
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true)
                await api.post('/admin/updates', {
                  thumbnail: updateThumbnail,
                  title: updateTitle,
                  content: updateContent,
                })
                await queryClient.invalidateQueries({queryKey: ['admin', 'updates']})
                onClose()
              } catch {
                toast.error('Something went wrong! Please try again later.')
              } finally {
                setIsLoading(false)
              }
            }}
          >
            Create
          </Button>
        </ModalFooter>
      }
      header="Create Changelog Item"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody as={Stack} gap="8px">
        <input accept="image/*" onChange={handleFileSelect} ref={fileRef} style={{display: 'none'}} type="file" />
        <ImageSelector fileRef={fileRef} updateThumbnail={updateThumbnail} />

        <FormControl>
          <FormLabel>Title</FormLabel>
          <Input
            onChange={event => setUpdateTitle(event.target.value)}
            placeholder="Some Cool Update"
            value={updateTitle}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Content</FormLabel>
          <Textarea
            as={TextareaAutosize}
            onChange={event => setUpdateContent(event.target.value)}
            value={updateContent}
          />
        </FormControl>
      </ModalBody>
    </Modal>
  )
}

function ServerUpdateRenderer({update}: {update: ServerUpdate}) {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const queryClient = useQueryClient()
  const updateModal = useDisclosure()

  return (
    <Flex flexDir="column">
      {update.thumbnailUrl && (
        <div
          style={{
            alignItems: 'center',
            aspectRatio: '16/9',
            backgroundImage: `url(${update.thumbnailUrl})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            border: '1px solid #4A5568',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            cursor: 'pointer',
            display: 'flex',
            height: 'auto',
            justifyContent: 'center',
            position: 'relative',
            width: '100%',
          }}
        />
      )}

      <Stack
        align="center"
        bgColor="gray.800"
        boxShadow="lg"
        direction="row"
        fontSize="sm"
        letterSpacing="tight"
        p={4}
        rounded="lg"
        roundedTop={update.thumbnailUrl ? 0 : 'lg'}
      >
        <Stack align="center" direction="row" flex="1" spacing="16px">
          <Stack spacing="1">
            <Text fontWeight="semibold" textTransform="uppercase">
              {update.title}
            </Text>

            <Tooltip label={formatDistance(new Date(update.timestamp), Date.now(), {addSuffix: true})}>
              <Text fontWeight="light">{dateFormatter.format(new Date(update.timestamp))}</Text>
            </Tooltip>

            <Text color="whiteAlpha.700" fontWeight="light" isTruncated maxW="320px">
              {update.content}
            </Text>
          </Stack>
        </Stack>

        <ServerUpdateUpdateModal update={update} {...updateModal} />
        <Stack direction="row" spacing="1.5">
          <Tooltip label="Delete Changelog Item">
            <IconButton
              aria-label="Delete Changelog Item"
              colorScheme="red"
              icon={<TrashIcon height={16} width={16} />}
              isLoading={isDeleting}
              onClick={async () => {
                try {
                  setIsDeleting(true)
                  await api.delete(`/admin/updates/${update.id}`)
                  await queryClient.invalidateQueries({queryKey: ['admin', 'updates']})
                } catch {
                  toast.error('Failed to delete changelog item')
                } finally {
                  setIsDeleting(false)
                }
              }}
              rounded="full"
              size="sm"
            />
          </Tooltip>

          <Tooltip label="Update Changelog Item">
            <IconButton
              aria-label="Update Changelog Item"
              colorScheme="orange"
              icon={<PencilSquareIcon height={16} width={16} />}
              onClick={() => updateModal.onOpen()}
              rounded="full"
              size="sm"
            />
          </Tooltip>
        </Stack>
      </Stack>
    </Flex>
  )
}

function ServerUpdateUpdateModal({
  update,
  isOpen,
  onClose,
}: {
  update: ServerUpdate
  isOpen: boolean
  onClose(): void
}): React.JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const [updateContent, setUpdateContent] = React.useState(update.content)
  const [updateThumbnail, setUpdateThumbnail] = React.useState('')
  const [updateTitle, setUpdateTitle] = React.useState(update.title)
  const fileRef = React.useRef<HTMLInputElement>(null)
  const queryClient = useQueryClient()

  function handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0]
    if (!file) return
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const result = reader.result
      if (typeof result === 'string') {
        setUpdateThumbnail(result)
      }
    })
    reader.readAsDataURL(file)
  }

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            colorScheme="orange"
            isDisabled={!updateContent || !updateTitle}
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true)
                await api.patch(`/admin/updates/${update.id}`, {
                  thumbnail: updateThumbnail,
                  title: updateTitle,
                  content: updateContent,
                })
                await queryClient.invalidateQueries({queryKey: ['admin', 'updates']})
                onClose()
              } catch {
                toast.error('Something went wrong! Please try again later.')
              } finally {
                setIsLoading(false)
              }
            }}
          >
            Update
          </Button>
        </ModalFooter>
      }
      header="Update Changelog Item"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody as={Stack} gap="8px">
        <input accept="image/*" onChange={handleFileSelect} ref={fileRef} style={{display: 'none'}} type="file" />
        <ImageSelector fileRef={fileRef} updateThumbnail={updateThumbnail || update.thumbnailUrl || ''} />

        <FormControl>
          <FormLabel>Title</FormLabel>
          <Input
            onChange={event => setUpdateTitle(event.target.value)}
            placeholder="Some Cool Update"
            value={updateTitle}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Content</FormLabel>
          <Textarea
            as={TextareaAutosize}
            onChange={event => setUpdateContent(event.target.value)}
            value={updateContent}
          />
        </FormControl>
      </ModalBody>
    </Modal>
  )
}
