import {formatBitfield} from '@app/common/utils'

export const DEVELOPER = import.meta.env.DEV
export const API_HOST = DEVELOPER ? 'http://localhost:5000' : 'https://api.ngmc.co'
export const CDN_HOST = 'https://cdn.nethergames.org'
export const XBOX_REDIRECT_URI = DEVELOPER
  ? 'http://localhost:5173/oauth/xbox/callback'
  : 'https://portal.nethergames.org/oauth/xbox/callback'

export const NOOP = (): void => {}

export const numberFormatter = new Intl.NumberFormat('en-US')
export const dateFormatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: 'short',
})

export enum ChatLogContexts {
  GLOBAL = 'global',
  PARTY = 'party',
  GUILD = 'guild',
  STAFF = 'staff',
  MODERATION = 'moderation',
  TRAINEE = 'trainee',
  ADMIN = 'admin',
  FACTION = 'faction',
  FACTION_ALLY = 'faction_ally',
  RANKED = 'ranked',
}

export enum GameMode {
  BW = 'bw',
  CQ = 'cq',
  DUELS = 'duels',
  MD = 'md',
  MM = 'mm',
  MS = 'ms',
  SC = 'sc',
  SG = 'sg',
  SW = 'sw',
  TB = 'tb',
  UHC = 'uhc',
}

export const GameModeToString: Record<GameMode, string> = {
  [GameMode.BW]: 'Bedwars',
  [GameMode.CQ]: 'Conquests',
  [GameMode.DUELS]: 'Duels',
  [GameMode.MD]: 'Meltdown',
  [GameMode.MM]: 'Murder Mystery',
  [GameMode.MS]: 'Momma Says',
  [GameMode.SC]: 'Soccer',
  [GameMode.SG]: 'Survival Games',
  [GameMode.SW]: 'Skywars',
  [GameMode.TB]: 'The Bridge',
  [GameMode.UHC]: 'UHC',
}

export enum LeaderboardColumn {
  BW_1V1_FINAL_KILLS = 'bw_1v1_final_kills',
  BW_1V1_KILLS = 'bw_1v1_kills',
  BW_1V1_WINS = 'bw_1v1_wins',
  BW_2V2_FINAL_KILLS = 'bw_2v2_final_kills',
  BW_2V2_KILLS = 'bw_2v2_kills',
  BW_2V2_WINS = 'bw_2v2_wins',
  BW_DOUBLES_FINAL_KILLS = 'bw_doubles_final_kills',
  BW_DOUBLES_KILLS = 'bw_doubles_kills',
  BW_DOUBLES_WINS = 'bw_doubles_wins',
  BW_FINAL_KILLS = 'bw_final_kills',
  BW_KILLS = 'bw_kills',
  BW_SOLO_FINAL_KILLS = 'bw_solo_final_kills',
  BW_SOLO_KILLS = 'bw_solo_kills',
  BW_SOLO_WINS = 'bw_solo_wins',
  BW_SQUADS_FINAL_KILLS = 'bw_squads_final_kills',
  BW_SQUADS_KILLS = 'bw_squads_kills',
  BW_SQUADS_WINS = 'bw_squads_wins',
  BW_WINS = 'bw_wins',
  CQ_FLAGS_CAPTURED = 'cq_flags_captured',
  CQ_KILLS = 'cq_kills',
  CQ_WINS = 'cq_wins',
  DUELS_KILLS = 'duels_kills',
  DUELS_WINS = 'duels_wins',
  KILLS = 'kills',
  MD_KILLS = 'md_kills',
  MD_WINS = 'md_wins',
  MM_BOW_KILLS = 'mm_bow_kills',
  MM_CLASSIC_KILLS = 'mm_classic_kills',
  MM_CLASSIC_WINS = 'mm_classic_wins',
  MM_INFECTION_KILLS = 'mm_infection_kills',
  MM_INFECTION_WINS = 'mm_infection_wins',
  MM_KILLS = 'mm_kills',
  MM_KNIFE_KILLS = 'mm_knife_kills',
  MM_THROW_KNIFE_KILLS = 'mm_throw_knife_kills',
  MM_WINS = 'mm_wins',
  MS_WINS = 'ms_wins',
  SC_WINS = 'sc_wins',
  SG_KILLS = 'sg_kills',
  SG_WINS = 'sg_wins',
  SW_DOUBLES_INSANE_KILLS = 'sw_doubles_insane_kills',
  SW_DOUBLES_KILLS = 'sw_doubles_kills',
  SW_DOUBLES_NORMAL_KILLS = 'sw_doubles_normal_kills',
  SW_DOUBLES_WINS = 'sw_doubles_wins',
  SW_KILLS = 'sw_kills',
  SW_SOLO_INSANE_KILLS = 'sw_solo_insane_kills',
  SW_SOLO_KILLS = 'sw_solo_kills',
  SW_SOLO_NORMAL_KILLS = 'sw_solo_normal_kills',
  SW_SOLO_WINS = 'sw_solo_wins',
  SW_WINS = 'sw_wins',
  TB_DOUBLES_GOALS = 'tb_doubles_goals',
  TB_DOUBLES_KILLS = 'tb_doubles_kills',
  TB_DOUBLES_WINS = 'tb_doubles_wins',
  TB_GOALS = 'tb_goals',
  TB_KILLS = 'tb_kills',
  TB_SOLO_GOALS = 'tb_solo_goals',
  TB_SOLO_KILLS = 'tb_solo_kills',
  TB_SOLO_WINS = 'tb_solo_wins',
  TB_WINS = 'tb_wins',
  UHC_KILLS = 'uhc_kills',
  UHC_WINS = 'uhc_wins',
  WINS = 'wins',
}

export const LeaderboardColumnToString: Record<LeaderboardColumn, string> = {
  [LeaderboardColumn.BW_1V1_FINAL_KILLS]: 'Bedwars 1v1 Final Kills',
  [LeaderboardColumn.BW_1V1_KILLS]: 'Bedwars 1v1 Kills',
  [LeaderboardColumn.BW_1V1_WINS]: 'Bedwars 1v1 Wins',
  [LeaderboardColumn.BW_2V2_FINAL_KILLS]: 'Bedwars 2v2 Final Kills',
  [LeaderboardColumn.BW_2V2_KILLS]: 'Bedwars 2v2 Kills',
  [LeaderboardColumn.BW_2V2_WINS]: 'Bedwars 2v2 Wins',
  [LeaderboardColumn.BW_DOUBLES_FINAL_KILLS]: 'Bedwars Doubles Final Kills',
  [LeaderboardColumn.BW_DOUBLES_KILLS]: 'Bedwars Doubles Kills',
  [LeaderboardColumn.BW_DOUBLES_WINS]: 'Bedwars Doubles Wins',
  [LeaderboardColumn.BW_FINAL_KILLS]: 'Bedwars Final Kills',
  [LeaderboardColumn.BW_KILLS]: 'Bedwars Kills',
  [LeaderboardColumn.BW_SOLO_FINAL_KILLS]: 'Bedwars Solo Final Kills',
  [LeaderboardColumn.BW_SOLO_KILLS]: 'Bedwars Solo Kills',
  [LeaderboardColumn.BW_SOLO_WINS]: 'Bedwars Solo Wins',
  [LeaderboardColumn.BW_SQUADS_FINAL_KILLS]: 'Bedwars Squads Final Kills',
  [LeaderboardColumn.BW_SQUADS_KILLS]: 'Bedwars Squads Kills',
  [LeaderboardColumn.BW_SQUADS_WINS]: 'Bedwars Squads Wins',
  [LeaderboardColumn.BW_WINS]: 'Bedwars Wins',
  [LeaderboardColumn.CQ_FLAGS_CAPTURED]: 'Conquests Flags Captured',
  [LeaderboardColumn.CQ_KILLS]: 'Conquests Kills',
  [LeaderboardColumn.CQ_WINS]: 'Conquests Wins',
  [LeaderboardColumn.DUELS_KILLS]: 'Duels Kills',
  [LeaderboardColumn.DUELS_WINS]: 'Duels Wins',
  [LeaderboardColumn.KILLS]: 'Global Kills',
  [LeaderboardColumn.MD_KILLS]: 'Meltdown Kills',
  [LeaderboardColumn.MD_WINS]: 'Meltdown Wins',
  [LeaderboardColumn.MM_BOW_KILLS]: 'Murder Mystery Bow Kills',
  [LeaderboardColumn.MM_CLASSIC_KILLS]: 'Murder Mystery Classic Kills',
  [LeaderboardColumn.MM_CLASSIC_WINS]: 'Murder Mystery Classic Wins',
  [LeaderboardColumn.MM_INFECTION_KILLS]: 'Murder Mystery Infection Kills',
  [LeaderboardColumn.MM_INFECTION_WINS]: 'Murder Mystery Infection Wins',
  [LeaderboardColumn.MM_KILLS]: 'Murder Mystery Kills',
  [LeaderboardColumn.MM_KNIFE_KILLS]: 'Murder Mystery Knife Kills',
  [LeaderboardColumn.MM_THROW_KNIFE_KILLS]: 'Murder Mystery Throw Knife Kills',
  [LeaderboardColumn.MM_WINS]: 'Murder Mystery Wins',
  [LeaderboardColumn.MS_WINS]: 'Momma Says Wins',
  [LeaderboardColumn.SC_WINS]: 'Soccer Wins',
  [LeaderboardColumn.SG_KILLS]: 'Survival Games Kills',
  [LeaderboardColumn.SG_WINS]: 'Survival Games Wins',
  [LeaderboardColumn.SW_DOUBLES_INSANE_KILLS]: 'Skywars Doubles Insane Kills',
  [LeaderboardColumn.SW_DOUBLES_KILLS]: 'Skywars Doubles Kills',
  [LeaderboardColumn.SW_DOUBLES_NORMAL_KILLS]: 'Skywars Doubles Normal Kills',
  [LeaderboardColumn.SW_DOUBLES_WINS]: 'Skywars Doubles Wins',
  [LeaderboardColumn.SW_KILLS]: 'Skywars Kills',
  [LeaderboardColumn.SW_SOLO_INSANE_KILLS]: 'Skywars Solo Insane Kills',
  [LeaderboardColumn.SW_SOLO_KILLS]: 'Skywars Solo Kills',
  [LeaderboardColumn.SW_SOLO_NORMAL_KILLS]: 'Skywars Solo Normal Kills',
  [LeaderboardColumn.SW_SOLO_WINS]: 'Skywars Solo Wins',
  [LeaderboardColumn.SW_WINS]: 'Skywars Wins',
  [LeaderboardColumn.TB_DOUBLES_GOALS]: 'The Bridge Doubles Goals',
  [LeaderboardColumn.TB_DOUBLES_KILLS]: 'The Bridge Doubles Kills',
  [LeaderboardColumn.TB_DOUBLES_WINS]: 'The Bridge Doubles Wins',
  [LeaderboardColumn.TB_GOALS]: 'The Bridge Goals',
  [LeaderboardColumn.TB_KILLS]: 'The Bridge Kills',
  [LeaderboardColumn.TB_SOLO_GOALS]: 'The Bridge Solo Goals',
  [LeaderboardColumn.TB_SOLO_KILLS]: 'The Bridge Solo Kills',
  [LeaderboardColumn.TB_SOLO_WINS]: 'The Bridge Solo Wins',
  [LeaderboardColumn.TB_WINS]: 'The Bridge Wins',
  [LeaderboardColumn.UHC_KILLS]: 'UHC Kills',
  [LeaderboardColumn.UHC_WINS]: 'UHC Wins',
  [LeaderboardColumn.WINS]: 'Global Wins',
}

export enum LeaderboardType {
  FACTIONS = 'factions',
  FACTIONS_PLAYER = 'factionsPlayer',
  GAME = 'game',
  GUILDS = 'guilds',
  MAP_VOTES = 'mapVotes',
  PLAYER_AGE = 'oldest',
  PLAYER_CRATE_KEYS = 'crateKeys',
  PLAYER_CREDITS = 'credits',
  PLAYER_DEATHS = 'deaths',
  PLAYER_KDR = 'kdr',
  PLAYER_KILLS = 'kills',
  PLAYER_PARKOUR = 'parkour',
  PLAYER_PLAYTIME = 'playtime',
  PLAYER_VOTES = 'voters',
  PLAYER_WINS = 'wins',
  PLAYER_WLR = 'wlr',
  PLAYER_XP = 'xp',
  SKYBLOCK = 'skyblock',
  TOURNAMENT = 'tournament',
  WIN_STREAKS = 'winStreaks',
}

export const LeaderboardTypeToString: Record<LeaderboardType, string> = {
  [LeaderboardType.FACTIONS_PLAYER]: 'Factions Players',
  [LeaderboardType.FACTIONS]: 'Factions',
  [LeaderboardType.GAME]: 'Game',
  [LeaderboardType.GUILDS]: 'Guilds',
  [LeaderboardType.MAP_VOTES]: 'Map Votes',
  [LeaderboardType.PLAYER_AGE]: 'Player Age',
  [LeaderboardType.PLAYER_CRATE_KEYS]: 'Crate Keys',
  [LeaderboardType.PLAYER_CREDITS]: 'Credits',
  [LeaderboardType.PLAYER_DEATHS]: 'Deaths',
  [LeaderboardType.PLAYER_KDR]: 'K/D',
  [LeaderboardType.PLAYER_KILLS]: 'Kills',
  [LeaderboardType.PLAYER_PARKOUR]: 'Parkour',
  [LeaderboardType.PLAYER_PLAYTIME]: 'Playtime',
  [LeaderboardType.PLAYER_VOTES]: 'Votes',
  [LeaderboardType.PLAYER_WINS]: 'Wins',
  [LeaderboardType.PLAYER_WLR]: 'W/L',
  [LeaderboardType.PLAYER_XP]: 'XP',
  [LeaderboardType.SKYBLOCK]: 'Skyblock',
  [LeaderboardType.TOURNAMENT]: 'Tournament',
  [LeaderboardType.WIN_STREAKS]: 'Win Streaks',
}

export enum LeaderboardSortBy {
  BALANCE = 'balance',
  BEST = 'best',
  CURRENT = 'current',
  PLAYER_BANK = 'bank',
  PLAYER_BEST_STREAK = 'bestStreak',
  PLAYER_BOUNTY = 'bounty',
  PLAYER_COINS = 'coins',
  PLAYER_KILLS = 'kills',
  PLAYER_KILL_STREAK = 'killStreak',
  PLAYER_MONEY = 'money',
  PLAYER_STREAK = 'streak',
  PLAYER_XP = 'xp',
  STRENGTH = 'strength',
}

export const LeaderboardSortByToString: Record<LeaderboardSortBy, string> = {
  [LeaderboardSortBy.BALANCE]: 'Balance',
  [LeaderboardSortBy.PLAYER_BANK]: 'Bank',
  [LeaderboardSortBy.PLAYER_BEST_STREAK]: 'Best Streak',
  [LeaderboardSortBy.PLAYER_BOUNTY]: 'Bounty',
  [LeaderboardSortBy.PLAYER_COINS]: 'Coins',
  [LeaderboardSortBy.PLAYER_KILLS]: 'Kills',
  [LeaderboardSortBy.PLAYER_KILL_STREAK]: 'Kill Streak',
  [LeaderboardSortBy.PLAYER_MONEY]: 'Money',
  [LeaderboardSortBy.PLAYER_STREAK]: 'Streak',
  [LeaderboardSortBy.PLAYER_XP]: 'XP',
  [LeaderboardSortBy.STRENGTH]: 'Strength',
  [LeaderboardSortBy.BEST]: 'Best',
  [LeaderboardSortBy.CURRENT]: 'Current',
}

export enum Rank {
  ADMIN = 'Admin',
  ADVISOR = 'Advisor',
  BUILDER = 'Builder',
  CREW = 'Crew',
  DESIGNER = 'Designer',
  DEVELOPER = 'Dev',
  DISCORD = 'Discord Mod',
  EMERALD = 'Emerald',
  GAME_DESIGNER = 'Game Designer',
  HONOURED = 'Honoured',
  LEGEND = 'Legend',
  MEDIA = 'Media',
  MOD = 'Mod',
  PARTNER = 'Partner',
  SUPERVISOR = 'Supervisor',
  SUPPORT = 'Support',
  SYSTEM = 'System',
  TESTER = 'Tester',
  TITAN = 'Titan',
  TRAINEE = 'Trainee',
  TRAINEE_BUILDER = 'Trainee Builder',
  ULTRA = 'Ultra',
  YOUTUBE = 'Youtube',
}

export const CREW_PLUS_RANKS = new Set([Rank.ADMIN, Rank.ADVISOR, Rank.SUPERVISOR, Rank.SUPPORT, Rank.MOD, Rank.CREW])
export const SUPPORT_PLUS_RANKS = new Set([Rank.ADMIN, Rank.ADVISOR, Rank.SUPERVISOR, Rank.SUPPORT])
export const MOD_PLUS_RANKS = new Set([Rank.ADMIN, Rank.ADVISOR, Rank.SUPERVISOR, Rank.SUPPORT, Rank.MOD])
export const STAFF_RANKS = new Set([
  Rank.ADMIN,
  Rank.SYSTEM,
  Rank.ADVISOR,
  Rank.SUPERVISOR,
  Rank.SUPPORT,
  Rank.MOD,
  Rank.CREW,
  Rank.TRAINEE,
  Rank.TRAINEE_BUILDER,
  Rank.BUILDER,
  Rank.DESIGNER,
  Rank.DEVELOPER,
  Rank.DISCORD,
  Rank.GAME_DESIGNER,
  Rank.MEDIA,
])

export const RankToColorString: Record<Rank, string> = {
  [Rank.ADMIN]: '#d7342a',
  [Rank.SYSTEM]: '#d7342a',
  [Rank.ADVISOR]: '#9d5bd3',
  [Rank.SUPERVISOR]: '#e257c0',
  [Rank.MOD]: '#3498db',
  [Rank.SUPPORT]: '#9b59b6',
  [Rank.DISCORD]: '#1abc9c',
  [Rank.MEDIA]: '#11806a',
  [Rank.CREW]: '#86f103',
  [Rank.DEVELOPER]: '#e91e63',
  [Rank.BUILDER]: '#065db6',
  [Rank.DESIGNER]: '#9b59b6',
  [Rank.GAME_DESIGNER]: '#c27c0e',
  [Rank.HONOURED]: '#ffa658',
  [Rank.TRAINEE]: '#f1c40f',
  [Rank.TRAINEE_BUILDER]: '#00aaaa',
  [Rank.PARTNER]: '#e06b00',
  [Rank.YOUTUBE]: '#e95959',
  [Rank.TESTER]: '#00aa00',
  [Rank.TITAN]: '#b91c1c',
  [Rank.LEGEND]: '#02d3f0',
  [Rank.EMERALD]: '#41bb4b',
  [Rank.ULTRA]: '#df9833',
}

export enum Tier {
  AMETHYST = 'Amethyst',
  BRONZE = 'Bronze',
  DIAMOND = 'Diamond',
  GOLD = 'Gold',
  OPAL = 'Opal',
  SAPPHIRE = 'Sapphire',
  SILVER = 'Silver',
  STEEL = 'Steel',
}

export const TierToCreditsThreshold: Record<Tier, number> = {
  [Tier.STEEL]: 2_500,
  [Tier.BRONZE]: 5_000,
  [Tier.SILVER]: 10_000,
  [Tier.GOLD]: 20_000,
  [Tier.OPAL]: 40_000,
  [Tier.AMETHYST]: 80_000,
  [Tier.SAPPHIRE]: 120_000,
  [Tier.DIAMOND]: 200_000,
}

export enum Period {
  BIWEEKLY = 'biweekly',
  DAILY = 'daily',
  GLOBAL = 'global',
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  YEARLY = 'yearly',
}

export const PeriodToString: Record<Period, string> = {
  [Period.GLOBAL]: 'All Time',
  [Period.DAILY]: 'Daily',
  [Period.WEEKLY]: 'Weekly',
  [Period.BIWEEKLY]: 'Biweekly',
  [Period.MONTHLY]: 'Monthly',
  [Period.YEARLY]: 'Yearly',
}

export enum MinecraftColor {
  AQUA = '#55ffff',
  BLACK = '#000000',
  BLUE = '#5555ff',
  DARK_AQUA = '#00aaaa',
  DARK_BLUE = '#0000aa',
  DARK_GRAY = '#555555',
  DARK_GREEN = '#00aa00',
  DARK_PURPLE = '#aa00aa',
  DARK_RED = '#aa0000',
  GOLD = '#ffaa00',
  GRAY = '#aaaaaa',
  GREEN = '#55ff55',
  LIGHT_PURPLE = '#ff55ff',
  MINECOIN_GOLD = '#ddd605',
  RED = '#ff5555',
  WHITE = '#ffffff',
  YELLOW = '#ffff55',
}

export const MinecraftColorToString: Record<MinecraftColor, string> = {
  [MinecraftColor.BLACK]: 'Black',
  [MinecraftColor.DARK_BLUE]: 'Dark Blue',
  [MinecraftColor.DARK_GREEN]: 'Dark Green',
  [MinecraftColor.DARK_AQUA]: 'Dark Aqua',
  [MinecraftColor.DARK_RED]: 'Dark Red',
  [MinecraftColor.DARK_PURPLE]: 'Dark Purple',
  [MinecraftColor.GOLD]: 'Gold',
  [MinecraftColor.GRAY]: 'Gray',
  [MinecraftColor.DARK_GRAY]: 'Dark Gray',
  [MinecraftColor.BLUE]: 'Blue',
  [MinecraftColor.GREEN]: 'Green',
  [MinecraftColor.AQUA]: 'Aqua',
  [MinecraftColor.RED]: 'Red',
  [MinecraftColor.LIGHT_PURPLE]: 'Light Purple',
  [MinecraftColor.YELLOW]: 'Yellow',
  [MinecraftColor.WHITE]: 'White',
  [MinecraftColor.MINECOIN_GOLD]: 'Mincoin Gold',
}

export enum GuildFlags {
  NONE = 0,
  AUTO_KICK_OFFICERS = 1 << 0,
  ACTIVE = 1 << 1,
  AUTO_KICK_BANNED = 1 << 2,
  AUTO_KICK_TEMP_BANNED = 1 << 3,
}

export enum GuildMemberFlags {
  NONE = 0,
  AUTO_KICK_EXEMPT = 1 << 0,
}

export enum GuildAuditLogActionType {
  AUTO_KICK = 1,
  INVITE_CREATE = 2,
  INVITE_DELETE = 3,
  AUTO_KICK_BANNED = 4,
  LEADERSHIP_TRANSFER = 5,
  MEMBER_ROLE_UPDATE = 6,
}

export const GUILD_INACTIVE_MESSAGE =
  'Active guilds have at least three active members who have played on the server for longer than 15 minutes each within the last three months.'

export enum FormType {
  SERVER_BAN_APPEAL = 1,
  LIVE_CHAT_BAN_APPEAL = 2,
  DISCORD_BAN_APPEAL = 3,
  MODMAIL_BAN_APPEAL = 4,
  SERVER_MUTE_APPEAL = 5,
  BUILDER_APPLICATION = 6,
  DESIGNER_APPLICATION = 7,
  DEVELOPER_APPLICATION = 8,
  DISCORD_MOD_APPLICATION = 9,
  SUPPORT_REP_APPLICATION = 10,
  TRAINEE_APPLICATION = 11,
  PARTNER_APPLICATION = 12,
  YOUTUBE_APPLICATION = 13,
  PLAYER_REPORT = 14,
  FACTIONS_ROLLBACK = 15,
  BILLING_ISSUE = 16,
  BUG_BOUNTY = 17,
  DISCORD_REPORT = 18,
  VPN_ALLOWLIST = 19,
  GUILD_ICON_APPROVAL = 20,
  ACCOUNT_DELETION = 21,
  COLLAGE_CLIPS = 22,
  GAME_DESIGNER_APPLICATION = 23,
  LINKED_ACCOUNTS_APPEAL = 24,
  SKYBLOCK_ROLLBACK = 25,
  BUILDING_COMPETITION = 26,
  INTEGRITY_REPORT = 27,
  FACTION_REPORT = 28,
  GUILD_REPORT = 29,

  // invalid types
  WELCOME_SCREEN = 101,
  APPEAL_SCREEN = 102,
  STAFF_APPLICATION_SCREEN = 103,
  BUG_REPORT_SCREEN = 106,
  FEEDBACK_SCREEN = 107,
  FINISH_SCREEN = 108,
  SUBMISSIONS_SCREEN = 109,
  PREVIEW_SCREEN = 110,
  OTHER_HELP_SCREEN = 111,
}

export const INVALID_FORM_TYPES = new Set([
  FormType.WELCOME_SCREEN,
  FormType.APPEAL_SCREEN,
  FormType.STAFF_APPLICATION_SCREEN,
  FormType.BUG_REPORT_SCREEN,
  FormType.OTHER_HELP_SCREEN,
  FormType.FEEDBACK_SCREEN,
  FormType.FINISH_SCREEN,
  FormType.SUBMISSIONS_SCREEN,
  FormType.PREVIEW_SCREEN,
])

export const APPLICATION_FORM_TYPES = new Set([
  FormType.BUILDER_APPLICATION,
  FormType.DESIGNER_APPLICATION,
  FormType.DEVELOPER_APPLICATION,
  FormType.DISCORD_MOD_APPLICATION,
  FormType.SUPPORT_REP_APPLICATION,
  FormType.TRAINEE_APPLICATION,
  FormType.PARTNER_APPLICATION,
  FormType.YOUTUBE_APPLICATION,
  FormType.GAME_DESIGNER_APPLICATION,
  FormType.STAFF_APPLICATION_SCREEN,
])

export const FormTypeToTitle: Record<FormType, string> = {
  [FormType.SERVER_BAN_APPEAL]: 'Server Ban',
  [FormType.LIVE_CHAT_BAN_APPEAL]: 'Live Chat Ban',
  [FormType.DISCORD_BAN_APPEAL]: 'Discord Ban',
  [FormType.MODMAIL_BAN_APPEAL]: 'Modmail Ban',
  [FormType.SERVER_MUTE_APPEAL]: 'Server Mute',
  [FormType.BUILDER_APPLICATION]: 'Builder',
  [FormType.DESIGNER_APPLICATION]: 'Designer',
  [FormType.DEVELOPER_APPLICATION]: 'Developer',
  [FormType.DISCORD_MOD_APPLICATION]: 'Discord Moderator',
  [FormType.SUPPORT_REP_APPLICATION]: 'Support Representative',
  [FormType.TRAINEE_APPLICATION]: 'Trainee',
  [FormType.PARTNER_APPLICATION]: 'Apply for Partner',
  [FormType.YOUTUBE_APPLICATION]: 'Apply for YouTube',
  [FormType.PLAYER_REPORT]: 'Player Report',
  [FormType.FACTIONS_ROLLBACK]: 'Factions Rollback',
  [FormType.BILLING_ISSUE]: 'Billing Issue',
  [FormType.BUG_BOUNTY]: 'Security Bug Bounty',
  [FormType.DISCORD_REPORT]: 'Discord Report',
  [FormType.VPN_ALLOWLIST]: 'VPN Allowlist',
  [FormType.GUILD_ICON_APPROVAL]: 'Guild Icon Approval',
  [FormType.ACCOUNT_DELETION]: 'Account Deletion',
  [FormType.COLLAGE_CLIPS]: 'Collage Clips',
  [FormType.GAME_DESIGNER_APPLICATION]: 'Game Designer',
  [FormType.LINKED_ACCOUNTS_APPEAL]: 'Linked Accounts',
  [FormType.SKYBLOCK_ROLLBACK]: 'Skyblock Rollback',
  [FormType.BUILDING_COMPETITION]: 'Building Competition',
  [FormType.INTEGRITY_REPORT]: 'Integrity Report',
  [FormType.FACTION_REPORT]: 'Faction Report',
  [FormType.GUILD_REPORT]: 'Guild Report',

  // invalid types
  [FormType.WELCOME_SCREEN]: 'Contact Us',
  [FormType.APPEAL_SCREEN]: 'Appeals',
  [FormType.STAFF_APPLICATION_SCREEN]: 'Apply for Staff',
  [FormType.BUG_REPORT_SCREEN]: 'Bug Report',
  [FormType.OTHER_HELP_SCREEN]: 'Other',
  [FormType.FEEDBACK_SCREEN]: 'Submit Feedback',
  [FormType.FINISH_SCREEN]: 'Request Submitted',
  [FormType.SUBMISSIONS_SCREEN]: 'Submissions',
  [FormType.PREVIEW_SCREEN]: 'Preview',
}

export const FormTypeToStringBase: Record<
  Exclude<
    FormType,
    | FormType.APPEAL_SCREEN
    | FormType.BUG_REPORT_SCREEN
    | FormType.OTHER_HELP_SCREEN
    | FormType.FEEDBACK_SCREEN
    | FormType.FINISH_SCREEN
    | FormType.PREVIEW_SCREEN
    | FormType.STAFF_APPLICATION_SCREEN
    | FormType.SUBMISSIONS_SCREEN
    | FormType.WELCOME_SCREEN
  >,
  string
> = {
  [FormType.SERVER_BAN_APPEAL]: 'Server Ban Appeal',
  [FormType.LIVE_CHAT_BAN_APPEAL]: 'Live Chat Ban Appeal',
  [FormType.DISCORD_BAN_APPEAL]: 'Discord Ban Appeal',
  [FormType.MODMAIL_BAN_APPEAL]: 'Modmail Ban Appeal',
  [FormType.SERVER_MUTE_APPEAL]: 'Server Mute Appeal',
  [FormType.BUILDER_APPLICATION]: 'Builder Application',
  [FormType.DESIGNER_APPLICATION]: 'Designer Application',
  [FormType.DEVELOPER_APPLICATION]: 'Developer Application',
  [FormType.DISCORD_MOD_APPLICATION]: 'Discord Moderator Application',
  [FormType.SUPPORT_REP_APPLICATION]: 'Support Representative Application',
  [FormType.TRAINEE_APPLICATION]: 'Trainee Application',
  [FormType.PARTNER_APPLICATION]: 'Partner Application',
  [FormType.YOUTUBE_APPLICATION]: 'YouTube Application',
  [FormType.GAME_DESIGNER_APPLICATION]: 'Game Designer Application',
  [FormType.LINKED_ACCOUNTS_APPEAL]: 'Linked Accounts Appeal',
  [FormType.PLAYER_REPORT]: FormTypeToTitle[FormType.PLAYER_REPORT],
  [FormType.FACTIONS_ROLLBACK]: FormTypeToTitle[FormType.FACTIONS_ROLLBACK],
  [FormType.BILLING_ISSUE]: FormTypeToTitle[FormType.BILLING_ISSUE],
  [FormType.BUG_BOUNTY]: FormTypeToTitle[FormType.BUG_BOUNTY],
  [FormType.DISCORD_REPORT]: FormTypeToTitle[FormType.DISCORD_REPORT],
  [FormType.VPN_ALLOWLIST]: FormTypeToTitle[FormType.VPN_ALLOWLIST],
  [FormType.GUILD_ICON_APPROVAL]: FormTypeToTitle[FormType.GUILD_ICON_APPROVAL],
  [FormType.ACCOUNT_DELETION]: FormTypeToTitle[FormType.ACCOUNT_DELETION],
  [FormType.COLLAGE_CLIPS]: FormTypeToTitle[FormType.COLLAGE_CLIPS],
  [FormType.SKYBLOCK_ROLLBACK]: FormTypeToTitle[FormType.SKYBLOCK_ROLLBACK],
  [FormType.BUILDING_COMPETITION]: FormTypeToTitle[FormType.BUILDING_COMPETITION],
  [FormType.INTEGRITY_REPORT]: FormTypeToTitle[FormType.INTEGRITY_REPORT],
  [FormType.FACTION_REPORT]: FormTypeToTitle[FormType.FACTION_REPORT],
  [FormType.GUILD_REPORT]: FormTypeToTitle[FormType.GUILD_REPORT],
}

export const FormTypeToString: Record<FormType, string> = {
  ...FormTypeToStringBase,

  // invalid types
  [FormType.WELCOME_SCREEN]: FormTypeToTitle[FormType.WELCOME_SCREEN],
  [FormType.APPEAL_SCREEN]: FormTypeToTitle[FormType.APPEAL_SCREEN],
  [FormType.STAFF_APPLICATION_SCREEN]: FormTypeToTitle[FormType.STAFF_APPLICATION_SCREEN],
  [FormType.BUG_REPORT_SCREEN]: FormTypeToTitle[FormType.BUG_REPORT_SCREEN],
  [FormType.OTHER_HELP_SCREEN]: FormTypeToTitle[FormType.OTHER_HELP_SCREEN],
  [FormType.FEEDBACK_SCREEN]: FormTypeToTitle[FormType.FEEDBACK_SCREEN],
  [FormType.FINISH_SCREEN]: FormTypeToTitle[FormType.FINISH_SCREEN],
  [FormType.SUBMISSIONS_SCREEN]: FormTypeToTitle[FormType.SUBMISSIONS_SCREEN],
  [FormType.PREVIEW_SCREEN]: FormTypeToTitle[FormType.PREVIEW_SCREEN],
}

export enum FormFieldType {
  TEXT_INPUT = 1,
  NUMBER_INPUT = 2,
  SELECT = 3,
  RADIO = 4,
}

export enum TextInputStyle {
  SHORT = 1,
  PARAGRAPH = 2,
  UPLOAD = 3,
}

export enum TextInputValidation {
  NONE = 0,
  EMAIL = 1,
  URL = 2,
  PLAYER = 3,
  LINKS = 4,
  GITHUB = 5,
  DISCORD_MESSAGE_URL = 6,
}

export const TextInputValidationToType: Record<TextInputValidation, string> = {
  [TextInputValidation.NONE]: 'text',
  [TextInputValidation.EMAIL]: 'email',
  [TextInputValidation.URL]: 'url',
  [TextInputValidation.PLAYER]: 'text',
  [TextInputValidation.LINKS]: 'text',
  [TextInputValidation.GITHUB]: 'text',
  [TextInputValidation.DISCORD_MESSAGE_URL]: 'url',
}

export enum FormRequirementType {
  ACTIVE_BAN = 1,
  ACTIVE_BAN_DISCORD = 2,
  ACTIVE_MUTE = 3,
  APPEALABLE = 4,
  RATE_LIMIT = 5,
  RATE_LIMIT_DENIED = 6,
  DISCORD_CONNECTION = 7,
  MIN_PLAYER_AGE = 8,
  NO_PENDING_SUBMISSIONS = 9,
  NO_PUNISHMENTS_ACTIVE = 12,
  NOT_RANK_ALREADY = 13,
  YOUTUBE_CONNECTION = 14,
  YOUTUBE_SUBSCRIBER_COUNT = 15,
  INVENTORY_BACKUP = 16,
  YOUTUBE_AVG_VIEW_COUNT = 17,
  YOUTUBE_VIDEO_COUNT = 18,
  CURRENT_OR_FORMER_STAFF = 20,
  NOT_FORM_BANNED = 21,
  RATE_LIMIT_ULTRA = 22,
  RATE_LIMIT_EMERALD = 23,
  RATE_LIMIT_LEGEND = 24,
  RATE_LIMIT_DENIED_CATEGORY = 25,
  IP_COUNTRY_RESTRICTED = 26,
  NOT_APPROVED_ALREADY = 27,
  GUILD_LEADER = 28,
  NOT_TITAN_SUBSCRIBER = 29,
  NOT_PROTECTED = 30,
  DISCORD_MEMBER_MIN_AGE = 31,
  MIN_PLAYER_LEVEL = 32,
  LINKED_ACCOUNTS = 33,
  HAS_YOUTUBE_RANK = 34,
}

export const FormRequirementTypeToString: Record<FormRequirementType, string> = {
  [FormRequirementType.ACTIVE_BAN]: 'Active ban on this account',
  [FormRequirementType.ACTIVE_BAN_DISCORD]: 'Active ban on your Discord account',
  [FormRequirementType.ACTIVE_MUTE]: 'Active mute on this account',
  [FormRequirementType.APPEALABLE]: 'Appealable punishment',
  [FormRequirementType.RATE_LIMIT]: 'Max 3 requests per hour',
  [FormRequirementType.RATE_LIMIT_DENIED]: '30 days passed since last denial',
  [FormRequirementType.DISCORD_CONNECTION]: 'Discord account connected',
  [FormRequirementType.MIN_PLAYER_AGE]: 'Joined the Minecraft server at least 30 days ago',
  [FormRequirementType.NO_PENDING_SUBMISSIONS]: 'No pending requests in this category',
  [FormRequirementType.NO_PUNISHMENTS_ACTIVE]: 'No active punishments on this account',
  [FormRequirementType.NOT_RANK_ALREADY]: 'Not already this rank',
  [FormRequirementType.YOUTUBE_CONNECTION]: 'YouTube account connected',
  [FormRequirementType.YOUTUBE_SUBSCRIBER_COUNT]: 'Minimum subscriber count (1000)',
  [FormRequirementType.INVENTORY_BACKUP]: 'At least one eligible inventory backup',
  [FormRequirementType.YOUTUBE_AVG_VIEW_COUNT]: 'Minimum average view count (750)',
  [FormRequirementType.YOUTUBE_VIDEO_COUNT]: 'Minimum video count (20)',
  [FormRequirementType.CURRENT_OR_FORMER_STAFF]: 'Current or former staff member',
  [FormRequirementType.NOT_FORM_BANNED]: 'Not banned from using this form',
  [FormRequirementType.RATE_LIMIT_ULTRA]: 'Max 5 requests per hour',
  [FormRequirementType.RATE_LIMIT_EMERALD]: 'Max 10 requests per hour',
  [FormRequirementType.RATE_LIMIT_LEGEND]: 'Max 15 requests per hour',
  [FormRequirementType.RATE_LIMIT_DENIED_CATEGORY]: '7 days passed since last denial in this category',
  [FormRequirementType.IP_COUNTRY_RESTRICTED]: 'Must be in a restricted country',
  [FormRequirementType.NOT_APPROVED_ALREADY]: 'Not already approved',
  [FormRequirementType.GUILD_LEADER]: 'Must be the leader of a guild',
  [FormRequirementType.NOT_TITAN_SUBSCRIBER]: 'Not a current Titan subscriber',
  [FormRequirementType.NOT_PROTECTED]: 'Not a protected account',
  [FormRequirementType.DISCORD_MEMBER_MIN_AGE]: 'You must have been active in our Discord server for at least 30 days',
  [FormRequirementType.MIN_PLAYER_LEVEL]: 'You must be at least level 100',
  [FormRequirementType.LINKED_ACCOUNTS]: 'At least one linked account',
  [FormRequirementType.HAS_YOUTUBE_RANK]: 'Must have YouTube rank',
}

export const YOUTUBE_REQUIREMENT_TYPES = new Set([
  FormRequirementType.YOUTUBE_SUBSCRIBER_COUNT,
  FormRequirementType.YOUTUBE_AVG_VIEW_COUNT,
  FormRequirementType.YOUTUBE_VIDEO_COUNT,
])

export enum FormSubmissionFlags {
  NONE = 0,
  SIMILARITY_SCORES_PENDING = 1 << 0,
  GPT_SCORE_PENDING = 1 << 1,
}

export enum FormSubmissionStatus {
  PENDING = 1,
  APPROVED = 2,
  DENIED = 3,
  ESCALATED = 4,
  RESUBMIT_REQUIRED = 5,
  SPAM = 6,
  RESOLVED = 7,
  MORE_INFORMATION_REQUIRED = 8,
}

export const FormSubmissionStatusToString: Record<FormSubmissionStatus, string> = {
  [FormSubmissionStatus.PENDING]: 'Pending',
  [FormSubmissionStatus.RESOLVED]: 'Resolved',
  [FormSubmissionStatus.APPROVED]: 'Approved',
  [FormSubmissionStatus.DENIED]: 'Denied',
  [FormSubmissionStatus.ESCALATED]: 'Escalated',
  [FormSubmissionStatus.RESUBMIT_REQUIRED]: 'Resubmit Required',
  [FormSubmissionStatus.MORE_INFORMATION_REQUIRED]: 'More Information Required',
  [FormSubmissionStatus.SPAM]: 'Spam',
}

export const FormSubmissionStatusToDescription: Record<FormSubmissionStatus, string> = {
  [FormSubmissionStatus.PENDING]: 'Your request is currently being reviewed by staff.',
  [FormSubmissionStatus.RESOLVED]: 'Your request has been resolved.',
  [FormSubmissionStatus.APPROVED]: 'Your request has been approved.',
  [FormSubmissionStatus.DENIED]: 'Your request has been denied.',
  [FormSubmissionStatus.ESCALATED]: 'Your request has been escalated to a higher staff member.',
  [FormSubmissionStatus.RESUBMIT_REQUIRED]:
    'Your request needs to be resubmitted. You need to create a new request with the updated information.',
  [FormSubmissionStatus.MORE_INFORMATION_REQUIRED]:
    'We need some more information from you in order to complete your request. You need to create a new request with the updated information.',
  [FormSubmissionStatus.SPAM]: 'You are flagged for spam and may not submit more requests.',
}

export const FormSubmissionStatusToBg: Record<FormSubmissionStatus, string> = {
  [FormSubmissionStatus.PENDING]: 'linear-gradient(107deg, rgb(255, 67, 5) 11.1%, rgb(245, 135, 0) 95.3%)',
  [FormSubmissionStatus.RESOLVED]: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
  [FormSubmissionStatus.APPROVED]: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
  [FormSubmissionStatus.DENIED]: 'linear-gradient(to right, #cb2d3e, #ef473a)',
  [FormSubmissionStatus.ESCALATED]: 'linear-gradient(107deg, rgb(255, 67, 5) 11.1%, rgb(245, 135, 0) 95.3%)',
  [FormSubmissionStatus.RESUBMIT_REQUIRED]: 'linear-gradient(107deg, rgb(255, 67, 5) 11.1%, rgb(245, 135, 0) 95.3%)',
  [FormSubmissionStatus.MORE_INFORMATION_REQUIRED]:
    'linear-gradient(107deg, rgb(255, 67, 5) 11.1%, rgb(245, 135, 0) 95.3%)',
  [FormSubmissionStatus.SPAM]: 'linear-gradient(to right, #cb2d3e, #ef473a)',
}

export const INVALID_FORM_BODY = 30_001
export const MICROSOFT_PARENTAL_CONSENT = 30_037
export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later.'

export enum PromotionType {
  OTHER = 0,
  SUMMER = 1,
  HALLOWEEN = 2,
  BLACK_FRIDAY = 3,
  HOLIDAY = 4,
  EASTER = 5,
}

export const PromotionTypeToBackground: Record<PromotionType, string | null> = {
  [PromotionType.OTHER]: null,
  [PromotionType.SUMMER]: null,
  [PromotionType.HALLOWEEN]: 'bbd5a88993058dcde2a8020de9355d99.webp',
  [PromotionType.BLACK_FRIDAY]: '7254bd2d0693164ea4dbf4764b918b98.webp',
  [PromotionType.HOLIDAY]: '2eaac1f06e84af55fbbc9b5436383e06.webp',
  [PromotionType.EASTER]: 'd862ba505e785273624fb3d38c32c678.webp',
}

export enum PromotionFlags {
  NONE = 0,
  TITAN_BOGO_PROMO = 1 << 0,
}

export const DEFAULT_BACKGROUND_DAY = '3554ca87ce3d636cd9374e76128464f7.webp'
export const DEFAULT_BACKGROUND_NIGHT = '2f0f5bb2031be2ba9c0a9afa6e12778c.webp'

export enum APIPermissions {
  NONE = 0,
  MANAGE_APPEALS = 1 << 0,
  MANAGE_PARTNER_APPLICATIONS = 1 << 1,
  MANAGE_YOUTUBE_APPLICATIONS = 1 << 2,
  MANAGE_STAFF_APPLICATIONS = 1 << 3,
  MANAGE_PLAYER_REPORTS = 1 << 4,
  MANAGE_ROLLBACKS = 1 << 5,
  MANAGE_BILLING_ISSUES = 1 << 6,
  MANAGE_BUG_BOUNTY = 1 << 7,
  MANAGE_DISCORD_APPEALS = 1 << 8,
  MANAGE_ENTITLEMENTS = 1 << 9,
  MANAGE_DEVELOPER_APPLICATIONS = 1 << 10,
  MANAGE_DISCORD_REPORTS = 1 << 11,
  MANAGE_BUILDER_APPLICATIONS = 1 << 12,
  MANAGE_COLLAGE_CLIPS = 1 << 13,
  MANAGE_GAME_DESIGNER_APPLICATIONS = 1 << 14,
  MANAGE_LINKED_ACCOUNTS_APPEALS = 1 << 15,
  ACCOUNT_DELETION_PROTECTED = 1 << 16,
}

export const AdminAPIPermissions = formatBitfield(APIPermissions)
