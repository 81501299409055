import type {AdminPlayerSession} from '@app/common/api'
import {useAdminPlayerSessions} from '@app/common/api'
import {dateFormatter} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {Flex, Heading, IconButton, Stack, Text} from '@chakra-ui/react'
import {
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  EyeIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/solid'
import {useQueryClient} from '@tanstack/react-query'
import type {TCountryCode} from 'countries-list'
import {getEmojiFlag} from 'countries-list'
import {formatDistance} from 'date-fns'
import type React from 'react'
import {shallow} from 'zustand/shallow'

export default function AdminPlayerWebIPsModal(): React.JSX.Element {
  const [adminPlayer, setAdminPlayer] = useOverlayStore(
    state => [state.adminPlayerWebIp, state.setAdminPlayerWebIp],
    shallow,
  )

  return (
    <Modal header={`Web IPs for '${adminPlayer}'`} isOpen={Boolean(adminPlayer)} onClose={() => setAdminPlayer(null)}>
      <AdminPlayerWebIPsContent player={adminPlayer!} />
    </Modal>
  )
}

function AdminPlayerWebIPsContent({player}: {player: string}): React.JSX.Element {
  const {data: sessions} = useAdminPlayerSessions(player)
  if (!sessions)
    return (
      <ModalBody align="center" as={Stack} h="full" justify="center" my={16}>
        <Spinner />
      </ModalBody>
    )

  return (
    <ModalBody as={Stack} gap="8px">
      {sessions.length > 0 ? (
        <Stack>
          {sessions.map(session => (
            <PlayerSession key={session.id} player={player} session={session} />
          ))}
        </Stack>
      ) : (
        <Stack align="center" h="full" justify="center" py={16} textAlign="center">
          <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
          <Heading fontSize="2xl" fontWeight="bold">
            *cricket noises*
          </Heading>
          <Text color="whiteAlpha.800" fontSize="md">
            No web sessions found.
          </Text>
        </Stack>
      )}
    </ModalBody>
  )
}

const REGION_NAMES = new Intl.DisplayNames(['en'], {type: 'region'})
const MOBILE_OS = new Set(['Android', 'iOS'])

function PlayerSession({player, session}: {player: string; session: AdminPlayerSession}): React.JSX.Element {
  const queryClient = useQueryClient()
  return (
    <Stack
      align="center"
      bgColor="gray.800"
      boxShadow="lg"
      direction="row"
      fontSize="sm"
      letterSpacing="tight"
      p={4}
      rounded="lg"
      userSelect="text"
    >
      <Stack align="center" direction="row" flex="1" spacing="14px">
        <Flex align="center" bgColor="gray.300" boxSize="46px" color="gray.900" justify="center" p={2} rounded="full">
          {MOBILE_OS.has(session.clientOs) ? (
            <DevicePhoneMobileIcon height={24} width={24} />
          ) : (
            <ComputerDesktopIcon height={24} width={24} />
          )}
        </Flex>

        <Stack spacing="1">
          <Text fontWeight="semibold" textTransform="uppercase">
            {session.clientOs} &bull; {session.clientBrowser}
          </Text>

          <Text fontWeight="light">
            {session.clientIp} &bull; <span>{getEmojiFlag(session.clientCountry as TCountryCode)}</span>{' '}
            {REGION_NAMES.of(session.clientCountry)}
          </Text>

          <Tooltip label={formatDistance(new Date(session.approxLastUsedTime), Date.now(), {addSuffix: true})}>
            <Text fontWeight="light">{dateFormatter.format(new Date(session.approxLastUsedTime))}</Text>
          </Tooltip>
        </Stack>
      </Stack>

      <Stack align="center" direction="row" spacing="8px">
        <Tooltip label="View as Session">
          <IconButton
            aria-label="View as Session"
            colorScheme="orange"
            icon={<EyeIcon height={16} width={16} />}
            onClick={async () => {
              window.localStorage.token = session.id
              window.location.reload()
            }}
            rounded="full"
            size="sm"
          />
        </Tooltip>

        <Tooltip label="Revoke Session">
          <IconButton
            aria-label="Revoke Session"
            colorScheme="red"
            icon={<TrashIcon height={16} width={16} />}
            onClick={async () => {
              await api.delete(`/admin/players/${player}/sessions/${session.id}`)
              await queryClient.invalidateQueries({queryKey: ['admin', 'players', player]})
            }}
            rounded="full"
            size="sm"
          />
        </Tooltip>
      </Stack>
    </Stack>
  )
}
