import type {APIPromotion} from '@app/common/api'
import {usePromotionsAdmin} from '@app/common/api'
import {PromotionType} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {PencilSquareIcon, PlusIcon, TrashIcon} from '@heroicons/react/24/solid'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {toast} from 'react-hot-toast'
import {shallow} from 'zustand/shallow'

export default function AdminPromosModal(): React.JSX.Element {
  const [adminPromosOpen, setAdminPromosOpen] = useOverlayStore(
    state => [state.adminPromosOpen, state.setAdminPromosOpen],
    shallow,
  )

  return (
    <Modal header="Promotions" isOpen={adminPromosOpen} onClose={() => setAdminPromosOpen(false)}>
      <AdminPromosModalContent />
    </Modal>
  )
}

function AdminPromosModalContent(): React.JSX.Element {
  const createModal = useDisclosure()
  const {data: promotions} = usePromotionsAdmin()
  return (
    <ModalBody as={Stack} gap="8px">
      <PromoCreateModal {...createModal} />
      <Button
        colorScheme="orange"
        leftIcon={<PlusIcon height={16} width={16} />}
        onClick={() => createModal.onOpen()}
        size="sm"
      >
        Create Promotion
      </Button>
      {promotions?.map(promotion => (
        <PromoRenderer key={promotion.id} promotion={promotion} />
      ))}
    </ModalBody>
  )
}

const PromotionTypes = {
  [PromotionType.OTHER]: 'Other',
  [PromotionType.SUMMER]: 'Summer',
  [PromotionType.HALLOWEEN]: 'Halloween',
  [PromotionType.BLACK_FRIDAY]: 'Black Friday',
  [PromotionType.HOLIDAY]: 'Holiday',
  [PromotionType.EASTER]: 'Easter',
}

function PromoCreateModal({isOpen, onClose}: {isOpen: boolean; onClose(): void}): React.JSX.Element {
  const queryClient = useQueryClient()
  const exampleDate = React.useMemo(() => new Date().toISOString(), [])
  const [type, setType] = React.useState<PromotionType>(PromotionType.OTHER)
  const [discount, setDiscount] = React.useState(0)
  const [flags, setFlags] = React.useState(0)
  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            colorScheme="orange"
            isDisabled={!discount || !title || !description || !startDate || !endDate}
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true)
                await api.post('/admin/billing/promotions', {
                  type,
                  discount,
                  flags,
                  title,
                  description,
                  startDate,
                  endDate,
                })
                await queryClient.invalidateQueries({queryKey: ['admin', 'billing', 'promotions']})
                onClose()
              } catch {
                toast.error('Something went wrong! Please try again later.')
              } finally {
                setIsLoading(false)
              }
            }}
          >
            Create
          </Button>
        </ModalFooter>
      }
      header="Create Promotion"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody as={Stack} gap="8px">
        <FormControl>
          <FormLabel>Type</FormLabel>
          <RadioGroup onChange={value => setType(Number(value) as PromotionType)} value={type.toString()}>
            <Stack>
              {Object.entries(PromotionTypes).map(([key, label]) => (
                <Radio key={key} value={key}>
                  {label}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Discount (0-100%)</FormLabel>
          <Input
            onChange={event => setDiscount(Number.parseInt(event.target.value, 10))}
            type="number"
            value={discount}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Titan Buy-One-Get-One?</FormLabel>
          <Checkbox
            isChecked={Boolean(flags & 1)}
            onChange={event => setFlags(flags => (event.target.checked ? flags | 1 : flags & ~1))}
          >
            Yes
          </Checkbox>
        </FormControl>

        <FormControl>
          <FormLabel>Title</FormLabel>
          <Input onChange={event => setTitle(event.target.value)} placeholder="Halloween Sale" value={title} />
        </FormControl>

        <FormControl>
          <FormLabel>Description</FormLabel>
          <Input
            onChange={event => setDescription(event.target.value)}
            placeholder="Lorem ipsum dolor sit amet, consectur adipiscing elit."
            value={description}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Start Date</FormLabel>
          <Input onChange={event => setStartDate(event.target.value)} placeholder={exampleDate} value={startDate} />
        </FormControl>

        <FormControl>
          <FormLabel>End Date</FormLabel>
          <Input onChange={event => setEndDate(event.target.value)} placeholder={exampleDate} value={endDate} />
        </FormControl>
      </ModalBody>
    </Modal>
  )
}

function PromoRenderer({promotion}: {promotion: APIPromotion}): React.JSX.Element {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const queryClient = useQueryClient()
  const updateModal = useDisclosure()

  return (
    <Stack
      align="center"
      bgColor="gray.800"
      boxShadow="lg"
      direction="row"
      fontSize="sm"
      letterSpacing="tight"
      p={4}
      rounded="lg"
    >
      <Stack align="center" direction="row" flex="1" spacing="16px">
        <Stack spacing="1">
          <Text fontWeight="semibold" textTransform="uppercase">
            {promotion.title}
          </Text>

          <Text fontWeight="light">{promotion.description}</Text>
        </Stack>
      </Stack>

      <PromoUpdateModal promotion={promotion} {...updateModal} />
      <Stack direction="row" spacing="1.5">
        <Tooltip label="Delete Promotion">
          <IconButton
            aria-label="Delete Promotion"
            colorScheme="red"
            icon={<TrashIcon height={16} width={16} />}
            isLoading={isDeleting}
            onClick={async () => {
              try {
                setIsDeleting(true)
                await api.delete(`/admin/billing/promotions/${promotion.id}`)
                await queryClient.invalidateQueries({queryKey: ['admin', 'billing', 'promotions']})
              } catch {
                toast.error('Failed to delete promotion')
              } finally {
                setIsDeleting(false)
              }
            }}
            rounded="full"
            size="sm"
          />
        </Tooltip>

        <Tooltip label="Update Promotion">
          <IconButton
            aria-label="Update Promotion"
            colorScheme="orange"
            icon={<PencilSquareIcon height={16} width={16} />}
            onClick={() => updateModal.onOpen()}
            rounded="full"
            size="sm"
          />
        </Tooltip>
      </Stack>
    </Stack>
  )
}

function PromoUpdateModal({
  promotion,
  isOpen,
  onClose,
}: {
  promotion: APIPromotion
  isOpen: boolean
  onClose(): void
}): React.JSX.Element {
  const exampleDate = React.useMemo(() => new Date().toISOString(), [])
  const [type, setType] = React.useState<PromotionType>(PromotionType.OTHER)
  const [discount, setDiscount] = React.useState(promotion.discount)
  const [flags, setFlags] = React.useState(promotion.flags)
  const [title, setTitle] = React.useState(promotion.title)
  const [description, setDescription] = React.useState(promotion.description)
  const [startDate, setStartDate] = React.useState(promotion.startDate)
  const [endDate, setEndDate] = React.useState(promotion.endDate)
  const [isLoading, setIsLoading] = React.useState(false)
  const queryClient = useQueryClient()

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            colorScheme="orange"
            isDisabled={!discount || !title || !description || !startDate || !endDate}
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true)
                await api.patch(`/admin/billing/promotions/${promotion.id}`, {
                  type,
                  discount,
                  flags,
                  title,
                  description,
                  startDate,
                  endDate,
                })
                await queryClient.invalidateQueries({queryKey: ['admin', 'billing', 'promotions']})
                onClose()
              } catch {
                toast.error('Something went wrong! Please try again later.')
              } finally {
                setIsLoading(false)
              }
            }}
          >
            Update
          </Button>
        </ModalFooter>
      }
      header="Update Promotion"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody as={Stack} gap="8px">
        <FormControl>
          <FormLabel>Type</FormLabel>
          <RadioGroup onChange={value => setType(Number(value) as PromotionType)} value={type.toString()}>
            <Stack>
              {Object.entries(PromotionTypes).map(([key, label]) => (
                <Radio key={key} value={key}>
                  {label}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Discount (0-100%)</FormLabel>
          <Input
            onChange={event => setDiscount(Number.parseInt(event.target.value, 10))}
            type="number"
            value={discount}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Titan Buy-One-Get-One?</FormLabel>
          <Checkbox
            isChecked={Boolean(flags & 1)}
            onChange={event => setFlags(flags => (event.target.checked ? flags | 1 : flags & ~1))}
          >
            Yes
          </Checkbox>
        </FormControl>

        <FormControl>
          <FormLabel>Title</FormLabel>
          <Input onChange={event => setTitle(event.target.value)} placeholder="Halloween Sale" value={title} />
        </FormControl>

        <FormControl>
          <FormLabel>Description</FormLabel>
          <Input
            onChange={event => setDescription(event.target.value)}
            placeholder="Lorem ipsum dolor sit amet, consectur adipiscing elit."
            value={description}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Start Date</FormLabel>
          <Input onChange={event => setStartDate(event.target.value)} placeholder={exampleDate} value={startDate} />
        </FormControl>

        <FormControl>
          <FormLabel>End Date</FormLabel>
          <Input onChange={event => setEndDate(event.target.value)} placeholder={exampleDate} value={endDate} />
        </FormControl>
      </ModalBody>
    </Modal>
  )
}
