import {SOMETHING_WENT_WRONG} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button, Textarea as ChakraTextarea, FormControl, FormLabel, Input, Stack} from '@chakra-ui/react'
import axios from 'axios'
import type React from 'react'
import {FormProvider, useForm, useFormContext} from 'react-hook-form'
import {toast} from 'react-hot-toast'
import TextareaAutosize from 'react-textarea-autosize'
import {shallow} from 'zustand/shallow'

export default function AdminSystemMessageModal(): React.JSX.Element {
  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {subject: '', message: ''},
  })
  const [player, setPlayer] = useOverlayStore(
    state => [state.adminSystemMessagePlayer, state.setAdminSystemMessagePlayer],
    shallow,
  )

  async function onSubmit(data: any): Promise<void> {
    try {
      await api.post(`admin/players/${player}/system-message`, {
        subject: data.subject?.trim() || '',
        message: data.message?.trim() || '',
      })
      toast.success('Message sent!')
      setPlayer(null)
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message ?? SOMETHING_WENT_WRONG)
      else toast.error(SOMETHING_WENT_WRONG)
    }
  }

  return (
    <FormProvider {...methods}>
      <Modal
        footer={
          <ModalFooter>
            <Button colorScheme="gray" mr={6} onClick={() => setPlayer(null)} variant="link">
              Cancel
            </Button>
            <Button isLoading={methods.formState.isSubmitting} onClick={methods.handleSubmit(onSubmit)}>
              Send Message
            </Button>
          </ModalFooter>
        }
        header="Send System Message"
        isOpen={Boolean(player)}
        onClose={() => setPlayer(null)}
      >
        <AdminSystemMessageModalContent />
      </Modal>
    </FormProvider>
  )
}

function AdminSystemMessageModalContent(): React.JSX.Element {
  const methods = useFormContext()
  return (
    <ModalBody as={Stack} fontSize="md" gap="8px">
      <FormControl>
        <FormLabel fontSize="sm" letterSpacing="tight">
          Subject
        </FormLabel>
        <Input {...methods.register('subject', {maxLength: 100})} name="subject" rounded="md" size="sm" />
      </FormControl>

      <FormControl>
        <FormLabel fontSize="sm" letterSpacing="tight">
          Message
        </FormLabel>
        <ChakraTextarea
          as={TextareaAutosize}
          {...methods.register('message', {maxLength: 4000})}
          name="message"
          rounded="md"
          size="sm"
        />
      </FormControl>
    </ModalBody>
  )
}
