import type {APIApplication} from '@app/common/api'
import {AdminPlayerFlags, useAdminPlayer, useAdminPlayerApplications} from '@app/common/api'
import {AdminAPIPermissions, Rank, RankToColorString, dateFormatter} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {getSkinUrl, isAdmin, isStaff} from '@app/common/utils'
import {Avatar} from '@app/components/Avatar/Avatar'
import {AlertDialog} from '@app/components/Modal/AlertDialog'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Link,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {PaperAirplaneIcon, PencilSquareIcon, QuestionMarkCircleIcon} from '@heroicons/react/24/solid'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {FormProvider, useForm, useFormContext} from 'react-hook-form'
import {toast} from 'react-hot-toast'
import {shallow} from 'zustand/shallow'

export default function AdminPlayerModal(): React.JSX.Element {
  const methods = useForm()
  const queryClient = useQueryClient()
  const [adminPlayer, setAdminPlayer] = useOverlayStore(state => [state.adminPlayer, state.setAdminPlayer], shallow)

  async function onSubmit(data: any): Promise<void> {
    try {
      await api.patch(`/admin/players/${adminPlayer}`, data)
      await queryClient.invalidateQueries({queryKey: ['admin', 'players', adminPlayer]})
      await queryClient.invalidateQueries({queryKey: ['players', adminPlayer]})
      toast.success('Player updated')
    } catch {
      toast.error('Failed to update player')
    }
  }

  return (
    <FormProvider {...methods}>
      <Modal
        footer={
          <ModalFooter>
            <Button isLoading={methods.formState.isSubmitting} onClick={methods.handleSubmit(onSubmit)}>
              Save
            </Button>
          </ModalFooter>
        }
        header={`Edit '${adminPlayer}'`}
        isOpen={Boolean(adminPlayer)}
        onClose={() => setAdminPlayer(null)}
      >
        <AdminPlayerContent player={adminPlayer!} />
      </Modal>
    </FormProvider>
  )
}

function AdminPlayerContent({player}: {player: string}): React.JSX.Element {
  const [incrementLevelsAmount, setIncrementLevelsAmount] = React.useState(10)
  const [isClearingBio, setIsClearingBio] = React.useState(false)
  const [isClearingSkin, setIsClearingSkin] = React.useState(false)
  const [isDisablingMfa, setIsDisablingMfa] = React.useState(false)
  const [isIncrementingLevels, setIsIncrementingLevels] = React.useState(false)
  const {data: applications} = useAdminPlayerApplications(player)
  const {data: playerData} = useAdminPlayer(player)
  const deleteModal = useDisclosure()
  const methods = useFormContext()
  const queryClient = useQueryClient()
  const setAdminSystemMessagePlayer = useOverlayStore(state => state.setAdminSystemMessagePlayer)
  const statsDeleteModal = useDisclosure()

  if (!playerData)
    return (
      <ModalBody align="center" as={Stack} h="full" justify="center" my={16}>
        <Spinner />
      </ModalBody>
    )

  return (
    <ModalBody as={Stack} gap="8px">
      <Avatar name={playerData.player} shouldShowOnline={false} skinHash={playerData.skinHash} />

      <Stack>
        <SmallHeading>Moderation Actions</SmallHeading>
        <Button
          colorScheme="orange"
          onClick={() => window.open(getSkinUrl(playerData.skinHash), '_blank')}
          size="sm"
          variant="outline"
        >
          View Player Skin
        </Button>

        <Button
          colorScheme="orange"
          isLoading={isClearingSkin}
          onClick={async () => {
            setIsClearingSkin(true)
            try {
              await api.delete(`/admin/players/${player}/skin`)
              await queryClient.invalidateQueries({queryKey: ['admin', 'players', player]})
              await queryClient.invalidateQueries({queryKey: ['players', player]})
              toast.success('Cleared player skin')
            } catch {
              toast.error('Failed to clear player skin')
            }

            setIsClearingSkin(false)
          }}
          size="sm"
          variant="outline"
        >
          Clear Player Skin
        </Button>

        <Button
          colorScheme="orange"
          isLoading={isClearingBio}
          onClick={async () => {
            setIsClearingBio(true)
            try {
              await api.delete(`/admin/players/${player}/bio`)
              await queryClient.invalidateQueries({queryKey: ['admin', 'players', player]})
              await queryClient.invalidateQueries({queryKey: ['players', player]})
              toast.success('Cleared player bio')
            } catch {
              toast.error('Failed to clear player bio')
            }

            setIsClearingBio(false)
          }}
          size="sm"
          variant="outline"
        >
          Clear Player Bio
        </Button>

        {playerData.mfaEnabled && (
          <Button
            colorScheme="orange"
            isLoading={isDisablingMfa}
            onClick={async () => {
              setIsDisablingMfa(true)
              try {
                await api.delete(`/admin/players/${player}/mfa`)
                await queryClient.invalidateQueries({queryKey: ['admin', 'players', player]})
                await queryClient.invalidateQueries({queryKey: ['players', player]})
                toast.success('Disabled player MFA')
              } catch {
                toast.error('Failed to disable player MFA')
              }

              setIsDisablingMfa(false)
            }}
            size="sm"
            variant="outline"
          >
            Disable Player MFA
          </Button>
        )}
      </Stack>

      <Stack>
        <SmallHeading>Increment Levels By</SmallHeading>
        <Stack align="center" direction="row" justify="space-between" spacing="2">
          <Input
            defaultValue={incrementLevelsAmount}
            name="incrementLevels"
            onChange={event => setIncrementLevelsAmount(Number(event.target.value))}
            type="number"
          />
          <Button
            colorScheme="orange"
            isLoading={isIncrementingLevels}
            onClick={async () => {
              setIsIncrementingLevels(true)
              try {
                await api.put(`/admin/players/${player}/increment-levels/${incrementLevelsAmount}`)
                await queryClient.invalidateQueries({queryKey: ['admin', 'players', player]})
                await queryClient.invalidateQueries({queryKey: ['players', player]})
                toast.success(`Incremented ${incrementLevelsAmount} levels`)
              } catch {
                toast.error('Failed to increment levels')
              }

              setIsIncrementingLevels(false)
            }}
            size="sm"
            variant="outline"
          >
            Let's Go!
          </Button>
        </Stack>
      </Stack>

      <Stack>
        <SmallHeading>Danger Zone</SmallHeading>
        <Button colorScheme="red" onClick={() => deleteModal.onOpen()} size="sm" variant="outline">
          Delete Player
        </Button>
        <Button colorScheme="red" onClick={() => statsDeleteModal.onOpen()} size="sm" variant="outline">
          Delete Positive Stats
        </Button>
      </Stack>

      <PlayerDeleteModal {...deleteModal} player={player} />
      <PlayerStatsDeleteModal {...statsDeleteModal} player={player} />

      <FormControl as={Stack}>
        <SmallHeading>Email</SmallHeading>
        <Input isReadOnly name="email" value={playerData.email} />
      </FormControl>

      <Button
        colorScheme="orange"
        leftIcon={<PaperAirplaneIcon height={16} width={16} />}
        onClick={() => setAdminSystemMessagePlayer(player)}
        size="sm"
        variant="outline"
      >
        Send System Message
      </Button>

      <FormControl as={Stack}>
        <SmallHeading>Ranks</SmallHeading>
        <CheckboxGroup defaultValue={playerData.ranks} onChange={ranks => methods.setValue('ranks', ranks)}>
          <Stack>
            {Object.keys(RankToColorString)
              .filter(rank => rank !== Rank.TITAN)
              .map(rank => (
                <Checkbox key={rank} name="ranks" value={rank}>
                  {rank}
                </Checkbox>
              ))}
          </Stack>
        </CheckboxGroup>
      </FormControl>

      <FormControl as={Stack}>
        <SmallHeading>Extra Friend Slots</SmallHeading>
        <Input
          defaultValue={playerData.extraFriends}
          name="extraFriends"
          onChange={event => methods.setValue('extraFriends', Number(event.target.value))}
          type="number"
        />
      </FormControl>

      <FormControl as={Stack}>
        <SmallHeading>XP</SmallHeading>
        <Input
          defaultValue={playerData.xp}
          name="xp"
          onChange={event => methods.setValue('xp', Number(event.target.value))}
          type="number"
        />
        <FormHelperText>
          You can convert between levels and XP using the{' '}
          <Link color="orange.300" href="https://ngmc.co/xp" isExternal>
            XP Calculator
          </Link>{' '}
          tool.
        </FormHelperText>
      </FormControl>

      <FormControl as={Stack}>
        <SmallHeading>Discord ID</SmallHeading>
        <Input
          defaultValue={playerData.discordId ?? ''}
          name="discordId"
          onChange={event => methods.setValue('discordId', event.target.value)}
          type="text"
        />
      </FormControl>

      <FormControl as={Stack}>
        <SmallHeading>Discord Tag</SmallHeading>
        <Input
          defaultValue={playerData.discordTag ?? ''}
          name="discordTag"
          onChange={event => methods.setValue('discordTag', event.target.value)}
          type="text"
        />
      </FormControl>

      <FormControl as={Stack}>
        <SmallHeading>Flags</SmallHeading>
        <CheckboxGroup
          defaultValue={Object.keys(AdminPlayerFlags).filter(key => playerData.flags & AdminPlayerFlags[key]!)}
          onChange={keys => {
            const flags = keys.map(key => AdminPlayerFlags[key]) as number[]
            methods.setValue(
              'flags',
              flags.reduce((a, b) => a | b, 0),
            )
          }}
        >
          <Stack>
            {Object.keys(AdminPlayerFlags).map(key => (
              <Checkbox key={key} name="flags" value={key}>
                {key}
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      </FormControl>

      <FormControl as={Stack}>
        <Stack align="center" direction="row" spacing="1">
          <SmallHeading>API Permissions</SmallHeading>
          {isAdmin(playerData) && (
            <Tooltip label="Admins have all permissions.">
              <QuestionMarkCircleIcon height={16} width={16} />
            </Tooltip>
          )}
          {!isStaff(playerData) && (
            <Tooltip label="This player is not a staff member.">
              <QuestionMarkCircleIcon height={16} width={16} />
            </Tooltip>
          )}
        </Stack>

        <CheckboxGroup
          defaultValue={Object.keys(AdminAPIPermissions).filter(
            key => playerData.apiPermissions & AdminAPIPermissions[key]!,
          )}
          isDisabled={isAdmin(playerData) || !isStaff(playerData)}
          onChange={keys => {
            const permissions = keys.map(key => AdminAPIPermissions[key]) as number[]
            methods.setValue(
              'apiPermissions',
              permissions.reduce((a, b) => a | b, 0),
            )
          }}
        >
          <Stack>
            {Object.keys(AdminAPIPermissions).map(key => (
              <Checkbox key={key} name="apiPermissions" value={key}>
                {key}
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      </FormControl>

      {applications && applications.length > 0 && (
        <Stack>
          <SmallHeading>API Applications</SmallHeading>
          <Stack>
            {applications.map(application => (
              <PlayerApplication application={application} key={application.id} />
            ))}
          </Stack>
        </Stack>
      )}
    </ModalBody>
  )
}

function PlayerApplication({application}: {application: APIApplication}): React.JSX.Element {
  const setAdminApplicationId = useOverlayStore(state => state.setAdminApplicationId)
  return (
    <Stack
      align="center"
      bgColor="gray.800"
      boxShadow="lg"
      direction="row"
      fontSize="sm"
      letterSpacing="tight"
      p={4}
      rounded="lg"
    >
      <Stack align="center" direction="row" flex="1" spacing="16px">
        <Stack spacing="1">
          <Text fontWeight="semibold" textTransform="uppercase">
            {application.name}
          </Text>

          <Text fontWeight="light">
            {dateFormatter.format(new Date(application.lastActiveAt ?? application.createdAt))}
          </Text>
        </Stack>
      </Stack>

      <Stack spacing="1">
        <Tooltip label="Edit Application">
          <IconButton
            aria-label="Edit Application"
            colorScheme="orange"
            icon={<PencilSquareIcon height={16} width={16} />}
            onClick={() => setAdminApplicationId(application.id)}
            rounded="full"
            size="sm"
          />
        </Tooltip>
      </Stack>
    </Stack>
  )
}

type PlayerDeleteModalProps = {
  isOpen: boolean
  onClose(): void
  player: string
}

export function PlayerDeleteModal(props: PlayerDeleteModalProps): React.JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const queryClient = useQueryClient()
  const setAdminPlayer = useOverlayStore(state => state.setAdminPlayer)

  return (
    <AlertDialog
      content={
        <>
          Are you sure you want to permanently delete <strong>{props.player}</strong>? This action cannot be undone.
        </>
      }
      isLoading={isLoading}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onPrimary={async () => {
        setIsLoading(true)
        try {
          await api.delete(`admin/players/${props.player}`)
          await queryClient.invalidateQueries({queryKey: ['admin', 'players', props.player]})
          await queryClient.invalidateQueries({queryKey: ['players', props.player]})
          setAdminPlayer(null)
        } catch {
          toast.error('Something went wrong! Please try again later.')
        }

        setIsLoading(false)
        props.onClose()
      }}
      primary="Delete Player"
      title="Danger, Will Robinson!"
    />
  )
}

type PlayerStatsDeleteModalProps = {
  isOpen: boolean
  onClose(): void
  player: string
}

export function PlayerStatsDeleteModal(props: PlayerStatsDeleteModalProps): React.JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false)
  const queryClient = useQueryClient()
  const setAdminPlayer = useOverlayStore(state => state.setAdminPlayer)

  return (
    <AlertDialog
      content={
        <>
          Are you sure you want to permanently delete all positive stats for <strong>{props.player}</strong>? This
          action cannot be undone.
        </>
      }
      isLoading={isLoading}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onPrimary={async () => {
        setIsLoading(true)
        try {
          await api.delete(`admin/players/${props.player}/stats`)
          await queryClient.invalidateQueries({queryKey: ['admin', 'players', props.player]})
          await queryClient.invalidateQueries({queryKey: ['players', props.player]})
          setAdminPlayer(null)
        } catch {
          toast.error('Something went wrong! Please try again later.')
        }

        setIsLoading(false)
        props.onClose()
      }}
      primary="Delete Player Stats"
      title="Danger, Will Robinson!"
    />
  )
}
