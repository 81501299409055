import type {
  APIFormFieldResponse,
  APINumberInputResponse,
  APIRadioResponse,
  APISelectOptionResponse,
  APISelectResponse,
  APITextInputResponse,
} from '@app/common/api'
import {usePlayer} from '@app/common/api'
import {FormFieldType, TextInputStyle, TextInputValidation, TextInputValidationToType} from '@app/common/constants'
import {useEvidenceV2} from '@app/common/experiments'
import {useOverlayStore} from '@app/common/stores'
import {FormHelperTextMarkdown} from '@app/components/Modal/FormHelperTextMarkdown'
import {PlayerInfo} from '@app/components/Modal/PlayerInfo'
import {
  Avatar,
  Button,
  Textarea as ChakraTextarea,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import {ArrowTopRightOnSquareIcon} from '@heroicons/react/24/solid'
import type {GroupBase, OptionProps} from 'chakra-react-select'
import {Select, chakraComponents} from 'chakra-react-select'
import {formatDistanceToNow} from 'date-fns'
import type {APIMessage, APIUser} from 'discord-api-types/v10'
import prettyBytes from 'pretty-bytes'
import React from 'react'
import {useDropzone} from 'react-dropzone'
import type {Control} from 'react-hook-form'
import {useController} from 'react-hook-form'
import {FaGavel, FaHistory} from 'react-icons/fa'
import {Link as RouterLink} from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize'
import invariant from 'tiny-invariant'

export function FormFieldRequiredIndicator(): React.JSX.Element {
  return (
    <Text as="span" color="red.300" fontSize="md" fontWeight="semibold" ml={1}>
      *
    </Text>
  )
}

export function SelectOption(props: OptionProps<APISelectOptionResponse>): React.JSX.Element {
  return (
    <chakraComponents.Option {...props}>
      <Stack direction="column" fontSize="sm" letterSpacing="tight" spacing="0.5">
        <Text fontWeight="semibold">{props.data.label}</Text>
        <Text color="whiteAlpha.800" fontSize="xs">
          {props.data.description}
        </Text>
      </Stack>
    </chakraComponents.Option>
  )
}

export function ControlledSelect({
  control,
  field,
}: {control: Control<any>; field: APISelectResponse}): React.JSX.Element {
  const isMulti = field.maxValues > 1
  const message = `You must select between ${field.minValues} and ${field.maxValues} options`
  const {field: inputField, fieldState} = useController({
    control,
    name: field.name,
    defaultValue: isMulti
      ? field.options.filter(option => option.default).map(option => option.value)
      : field.options.find(option => option.default)?.value,
    rules: {
      required: {value: field.required, message: 'This field is required'},
      ...(isMulti ? {minLength: {value: field.minValues, message}} : {}),
      ...(isMulti ? {maxLength: {value: field.maxValues, message}} : {}),
    },
  })

  const selectedOptions = inputField.value
    ? field.options.filter(option => inputField.value.includes(option.value))
    : []
  return (
    <FormControl id={field.name} isInvalid={Boolean(fieldState.error)} isRequired={field.required}>
      <FormLabel fontSize="sm" letterSpacing="tight" requiredIndicator={<FormFieldRequiredIndicator />}>
        {field.label}
      </FormLabel>
      <Select<APISelectOptionResponse, boolean, GroupBase<APISelectOptionResponse>>
        className="react-select"
        classNamePrefix="react-select"
        closeMenuOnSelect={field.name === 'rollback_inventory_id'}
        components={{Option: SelectOption}}
        defaultValue={(isMulti ? selectedOptions : selectedOptions[0]) ?? null}
        isMulti={isMulti}
        menuPortalTarget={document.body}
        name={inputField.name}
        onBlur={() => inputField.onBlur()}
        onChange={option => {
          if (!option) inputField.onChange(null)
          else if (isMulti) {
            invariant(Array.isArray(option), 'Expected option to be an array')
            inputField.onChange(option.map(option => option.value))
          } else {
            invariant('value' in option, 'Expected option to have a value')
            inputField.onChange(option.value)
          }
        }}
        options={field.options}
        placeholder={field.placeholder}
        selectedOptionStyle="check"
        size="sm"
        useBasicStyles
      />
      {field.description && <FormHelperTextMarkdown>{field.description}</FormHelperTextMarkdown>}
      {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export function SelectPreview({field, admin = false}: {field: APISelectResponse; admin?: boolean}): React.JSX.Element {
  const selectedOptions = field.options.filter(option => field.value?.includes(option.value))
  return (
    <FormControl id={field.name} isRequired={field.required}>
      <FormLabel fontSize="sm" letterSpacing="tight" requiredIndicator={<FormFieldRequiredIndicator />}>
        {field.label}
      </FormLabel>
      <Select<APISelectOptionResponse, boolean, GroupBase<APISelectOptionResponse>>
        className="react-select"
        classNamePrefix="react-select"
        closeMenuOnSelect={false}
        defaultValue={(field.maxValues > 1 ? selectedOptions : selectedOptions[0]) ?? null}
        isDisabled
        isMulti={field.maxValues > 1}
        menuPortalTarget={document.body}
        name={field.name}
        options={field.options}
        placeholder={field.placeholder}
        selectedOptionStyle="check"
        size="sm"
        useBasicStyles
      />
      {field.description && <FormHelperTextMarkdown>{field.description}</FormHelperTextMarkdown>}
      {admin && (
        <Text color="whiteAlpha.800" fontSize="xs" mt={1} userSelect="text">
          Selected value: {JSON.stringify(field.value)}
        </Text>
      )}
      {admin && field.metadata != null && (
        <Text color="whiteAlpha.800" fontSize="xs" mt={1} userSelect="text">
          Metadata: {JSON.stringify(field.metadata, null, 2)}
        </Text>
      )}
    </FormControl>
  )
}

const SENSITIVE_DEFAULT_FIELDS = new Set(['appeal_ip', 'appeal_location'])

export function ControlledTextInput({
  control,
  field,
}: {
  control: Control<any>
  field: APITextInputResponse
}): React.JSX.Element {
  const {field: inputField, fieldState} = useController({
    control,
    name: field.name,
    defaultValue:
      field.name === 'application_timezone' ? Intl.DateTimeFormat().resolvedOptions().timeZone : field.value,
    rules: {
      required: {value: field.required, message: 'This field is required'},
      maxLength: {
        value: field.maxLength,
        message: `Must be between ${field.minLength} and ${field.maxLength} in length`,
      },
      minLength: {
        value: field.minLength,
        message: `Must be between ${field.minLength} and ${field.maxLength} in length`,
      },
    },
  })

  return (
    <FormControl id={field.name} isInvalid={Boolean(fieldState.error)} isRequired={field.required}>
      <FormLabel fontSize="sm" letterSpacing="tight" requiredIndicator={<FormFieldRequiredIndicator />}>
        {field.label}
      </FormLabel>
      <Input
        {...inputField}
        className={SENSITIVE_DEFAULT_FIELDS.has(field.name) ? 'blurOnLoseFocus' : undefined}
        isReadOnly={field.disabled}
        maxLength={field.maxLength}
        minLength={field.minLength}
        placeholder={field.placeholder ?? undefined}
        rounded="md"
        size="sm"
        type={TextInputValidationToType[field.validate]}
      />
      {field.description && <FormHelperTextMarkdown>{field.description}</FormHelperTextMarkdown>}
      {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
    </FormControl>
  )
}

const Dropzone = styled.div<{isDragActive: boolean}>`
  align-items: center;
  border-color: var(--chakra-colors-gray-600);
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  flex: 1;
  outline: none;
  padding: 20px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`

enum UploadState {
  Idle = 0,
  DropzoneActive = 1,
  DisabledMaxFiles = 2,
  DisabledMaxRequestSize = 3,
  Uploading = 4,
  UploadingError = 5,
}

const MAX_FILES = 10
const MAX_REQUEST_SIZE = 8 * 1024 * 1024

export function ControlledEvidenceUpload({
  control,
  field,
}: {
  control: Control<any>
  field: APITextInputResponse
}): React.JSX.Element {
  const {fieldState} = useController({
    control,
    name: field.name,
    defaultValue: field.value,
  })

  const [files, setFiles] = React.useState<File[]>([])
  const [uploadState, setUploadState] = React.useState<UploadState>(UploadState.Idle)
  const requestSize = React.useMemo(() => files.reduce((acc, file) => acc + file.size, 0), [files])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: files => {
      if (uploadState === UploadState.Uploading) return
      setFiles(prev => [...prev, ...files])
    },
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/gif': ['.gif'],
      'image/webp': ['.webp'],
      'video/mp4': ['.mp4'],
      'video/webm': ['.webm'],
      'video/quicktime': ['.mov'],
    },
    maxSize: 8 * 1024 * 1024,
    disabled: uploadState === UploadState.DisabledMaxFiles || uploadState === UploadState.DisabledMaxRequestSize,
  })

  React.useEffect(() => {
    if (requestSize > MAX_REQUEST_SIZE) setUploadState(UploadState.DisabledMaxRequestSize)
    else if (files.length > MAX_FILES) setUploadState(UploadState.DisabledMaxFiles)
    else if (isDragActive) setUploadState(UploadState.DropzoneActive)
    else setUploadState(UploadState.Idle)
  }, [requestSize, files.length, isDragActive])

  React.useEffect(() => {
    console.debug(`Upload state: ${UploadState[uploadState]}`)
  }, [uploadState])

  return (
    <FormControl id={field.name} isInvalid={Boolean(fieldState.error)} isRequired={field.required}>
      <FormLabel fontSize="sm" letterSpacing="tight" requiredIndicator={<FormFieldRequiredIndicator />}>
        {field.label}
      </FormLabel>

      <Stack color="whiteAlpha.800" fontSize="sm" letterSpacing="tight">
        <Dropzone {...getRootProps()} isDragActive={isDragActive}>
          <input {...getInputProps()} />
          <Stack spacing="4px">
            <Text>Drag & drop (or click to select) your media (8 MB).</Text>
            <Stack spacing="2px">
              {files.map((file, index) => (
                <Text
                  color="whiteAlpha.600"
                  key={`file-${
                    // biome-ignore lint/suspicious/noArrayIndexKey: this is fine
                    index
                  }`}
                >
                  {file.name} ({prettyBytes(file.size)})
                </Text>
              ))}
            </Stack>
          </Stack>
        </Dropzone>
        <Text color="whiteAlpha.600">Supported file extensions: .png, .jpg, .gif, .webp, .mp4, .webm, .mov</Text>
      </Stack>

      {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export function TextInputPreviewPlayer({
  field,
  admin = false,
}: {
  field: APITextInputResponse
  admin?: boolean
}): React.JSX.Element {
  const {data: player} = usePlayer(field.value!)
  const setAdminRequestsOffenderXuid = useOverlayStore(state => state.setAdminRequestsOffenderXuid)
  const setAdminRequestsOpen = useOverlayStore(state => state.setAdminRequestsOpen)
  const setAdminRequestsStatus = useOverlayStore(state => state.setAdminRequestsStatus)
  const setPunishmentPlayer = useOverlayStore(state => state.setPunishmentPlayer)
  const setRequestId = useOverlayStore(state => state.setRequestId)

  return (
    <FormControl id={field.name} isRequired={field.required}>
      <FormLabel fontSize="sm" letterSpacing="tight" requiredIndicator={<FormFieldRequiredIndicator />}>
        {field.label}
      </FormLabel>
      <PlayerInfo isDark player={player?.name ?? field.value!} />
      {field.description && <FormHelperTextMarkdown>{field.description}</FormHelperTextMarkdown>}

      {player && (
        <Button
          colorScheme="orange"
          leftIcon={<FaGavel />}
          mt={4}
          onClick={() => setPunishmentPlayer(player.name)}
          size="sm"
          w="full"
        >
          View Punishments
        </Button>
      )}
      {player && admin && (
        <Button
          colorScheme="orange"
          leftIcon={<FaHistory />}
          mt={4}
          onClick={() => {
            setRequestId(null)
            setAdminRequestsOpen(true)
            setAdminRequestsStatus([])
            setAdminRequestsOffenderXuid([player.xuid])
          }}
          size="sm"
          w="full"
        >
          View Previous Reports
        </Button>
      )}
    </FormControl>
  )
}

export function getDiscordAvatarUrl(user: APIUser): string {
  return user.avatar ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.webp?size=128` : ''
}

export function DiscordMessage({message, messageLink}: {message: APIMessage; messageLink: string}): React.JSX.Element {
  return (
    <Stack
      as={RouterLink}
      bg="gray.800"
      borderRadius="md"
      cursor="pointer"
      direction="row"
      justify="space-between"
      p={4}
      spacing={2}
      target="_blank"
      to={messageLink}
    >
      <Stack direction="column">
        <Stack align="center" direction="row" spacing={2}>
          <Avatar name={message.author.username} size="sm" src={getDiscordAvatarUrl(message.author)} />
          <Stack spacing={0}>
            <Text color="whiteAlpha.800" fontSize="sm">
              {message.author.username}#{message.author.discriminator}
            </Text>
            <Text color="whiteAlpha.600" fontSize="xs">
              {formatDistanceToNow(new Date(message.timestamp))} ago
            </Text>
          </Stack>
        </Stack>
        <Text color="whiteAlpha.800" fontSize="sm">
          {message.content}
        </Text>
      </Stack>
      <ArrowTopRightOnSquareIcon height={24} width={24} />
    </Stack>
  )
}

export function TextInputPreviewDiscordMessage({field}: {field: APITextInputResponse}): React.JSX.Element {
  return (
    <FormControl id={field.name} isRequired={field.required}>
      <FormLabel fontSize="sm" letterSpacing="tight" requiredIndicator={<FormFieldRequiredIndicator />}>
        {field.label}
      </FormLabel>
      <DiscordMessage message={field.metadata as APIMessage} messageLink={field.value!} />
      {field.description && <FormHelperTextMarkdown>{field.description}</FormHelperTextMarkdown>}
    </FormControl>
  )
}

export function TextInputPreview({
  field,
  admin = false,
}: {field: APITextInputResponse; admin?: boolean}): React.JSX.Element {
  if (field.validate === TextInputValidation.PLAYER) {
    return <TextInputPreviewPlayer admin={admin} field={field} />
  }

  if (field.validate === TextInputValidation.DISCORD_MESSAGE_URL) {
    return <TextInputPreviewDiscordMessage field={field} />
  }

  return (
    <FormControl id={field.name} isRequired={field.required}>
      <FormLabel fontSize="sm" letterSpacing="tight" requiredIndicator={<FormFieldRequiredIndicator />}>
        {field.label}
      </FormLabel>
      <Input
        isReadOnly
        maxLength={field.maxLength}
        minLength={field.minLength}
        placeholder={field.placeholder ?? undefined}
        rounded="md"
        size="sm"
        type="text"
        value={field.value}
      />
      {field.description && <FormHelperTextMarkdown>{field.description}</FormHelperTextMarkdown>}
    </FormControl>
  )
}

export function ControlledTextArea({
  control,
  field,
}: {
  control: Control<any>
  field: APITextInputResponse
}): React.JSX.Element {
  const {field: inputField, fieldState} = useController({
    control,
    name: field.name,
    defaultValue: field.value,
    rules: {
      required: {value: field.required, message: 'This field is required'},
      maxLength: {
        value: field.maxLength,
        message: `Must be between ${field.minLength} and ${field.maxLength} in length`,
      },
      minLength: {
        value: field.minLength,
        message: `Must be between ${field.minLength} and ${field.maxLength} in length`,
      },
    },
  })

  return (
    <FormControl id={field.name} isInvalid={Boolean(fieldState.error)} isRequired={field.required}>
      <FormLabel fontSize="sm" letterSpacing="tight" requiredIndicator={<FormFieldRequiredIndicator />}>
        {field.label}
      </FormLabel>
      <ChakraTextarea
        as={TextareaAutosize}
        {...inputField}
        isReadOnly={field.disabled}
        maxLength={field.maxLength}
        minLength={field.minLength}
        placeholder={field.placeholder ?? undefined}
        rounded="md"
        size="sm"
      />
      {field.description && <FormHelperTextMarkdown>{field.description}</FormHelperTextMarkdown>}
      {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export function TextAreaPreview({field}: {field: APITextInputResponse}): React.JSX.Element {
  return (
    <FormControl id={field.name} isRequired={field.required}>
      <FormLabel fontSize="sm" letterSpacing="tight" requiredIndicator={<FormFieldRequiredIndicator />}>
        {field.label}
      </FormLabel>
      <ChakraTextarea
        as={TextareaAutosize}
        isReadOnly
        maxLength={field.maxLength}
        minLength={field.minLength}
        placeholder={field.placeholder ?? undefined}
        rounded="md"
        size="sm"
        value={field.value}
      />
      {field.description && <FormHelperTextMarkdown>{field.description}</FormHelperTextMarkdown>}
    </FormControl>
  )
}

export function ControlledNumberInput({
  control,
  field,
}: {
  control: Control<any>
  field: APINumberInputResponse
}): React.JSX.Element {
  const {field: inputField, fieldState} = useController({
    control,
    name: field.name,
    defaultValue: field.min,
    rules: {
      required: {value: field.required, message: 'This field is required'},
      max: {value: field.max, message: `Must be between ${field.min} and ${field.max}`},
      min: {value: field.min, message: `Must be between ${field.min} and ${field.max}`},
    },
  })

  return (
    <FormControl id={field.name} isInvalid={Boolean(fieldState.error)} isRequired={field.required}>
      <FormLabel fontSize="sm" letterSpacing="tight" requiredIndicator={<FormFieldRequiredIndicator />}>
        {field.label}
      </FormLabel>
      <NumberInput
        {...inputField}
        defaultValue={field.min}
        max={field.max}
        min={field.min}
        onChange={value => !Number.isNaN(Number(value)) && inputField.onChange(value)}
        size="sm"
      >
        <NumberInputField rounded="md" />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      {field.description && <FormHelperTextMarkdown>{field.description}</FormHelperTextMarkdown>}
      {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export function NumberInputPreview({field}: {field: APINumberInputResponse}): React.JSX.Element {
  return (
    <FormControl id={field.name} isRequired={field.required}>
      <FormLabel fontSize="sm" letterSpacing="tight" requiredIndicator={<FormFieldRequiredIndicator />}>
        {field.label}
      </FormLabel>
      <NumberInput isReadOnly max={field.max} min={field.min} size="sm" value={Number(field.value!)}>
        <NumberInputField rounded="md" />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      {field.description && <FormHelperTextMarkdown>{field.description}</FormHelperTextMarkdown>}
    </FormControl>
  )
}

export function ControlledRadioGroup({
  control,
  field,
}: {control: Control<any>; field: APIRadioResponse}): React.JSX.Element {
  const {field: inputField, fieldState} = useController({
    control,
    name: field.name,
    defaultValue: field.defaultOption,
    rules: {
      required: {value: field.required, message: 'This field is required'},
    },
  })

  return (
    <FormControl id={field.name} isInvalid={Boolean(fieldState.error)} isRequired={field.required}>
      <FormLabel fontSize="sm" letterSpacing="tight" requiredIndicator={<FormFieldRequiredIndicator />}>
        {field.label}
      </FormLabel>
      <RadioGroup {...inputField} size="sm">
        <Stack spacing="0.5">
          {field.options.map(option => (
            <Radio key={option} value={option}>
              {option}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      {field.description && <FormHelperTextMarkdown>{field.description}</FormHelperTextMarkdown>}
      {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export function RadioGroupPreview({field}: {field: APIRadioResponse}): React.JSX.Element {
  return (
    <FormControl id={field.name} isRequired={field.required}>
      <FormLabel fontSize="sm" letterSpacing="tight" requiredIndicator={<FormFieldRequiredIndicator />}>
        {field.label}
      </FormLabel>
      <RadioGroup size="sm" value={field.value!}>
        <Stack spacing="0.5">
          {field.options.map(option => (
            <Radio isReadOnly key={option} value={option}>
              {option}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      {field.description && <FormHelperTextMarkdown>{field.description}</FormHelperTextMarkdown>}
    </FormControl>
  )
}

export function FormField({control, field}: {control: Control; field: APIFormFieldResponse}): React.JSX.Element {
  const isEvidenceV2 = useEvidenceV2()

  switch (field.type) {
    case FormFieldType.TEXT_INPUT: {
      return field.style === TextInputStyle.UPLOAD && isEvidenceV2 ? (
        <ControlledEvidenceUpload control={control} field={field} />
      ) : field.style === TextInputStyle.PARAGRAPH ? (
        <ControlledTextArea control={control} field={field} />
      ) : (
        <ControlledTextInput control={control} field={field} />
      )
    }

    case FormFieldType.NUMBER_INPUT: {
      return <ControlledNumberInput control={control} field={field} />
    }

    case FormFieldType.SELECT: {
      return <ControlledSelect control={control} field={field} />
    }

    case FormFieldType.RADIO: {
      return <ControlledRadioGroup control={control} field={field} />
    }
  }
}

export function FormFieldPreview({
  field,
  admin = false,
}: {field: APIFormFieldResponse; admin?: boolean}): React.JSX.Element {
  const isEvidenceV2 = useEvidenceV2()

  switch (field.type) {
    case FormFieldType.TEXT_INPUT: {
      return field.style === TextInputStyle.UPLOAD && isEvidenceV2 ? (
        <>TBD</>
      ) : field.style === TextInputStyle.PARAGRAPH ? (
        <TextAreaPreview field={field} />
      ) : (
        <TextInputPreview admin={admin} field={field} />
      )
    }

    case FormFieldType.NUMBER_INPUT: {
      return <NumberInputPreview field={field} />
    }

    case FormFieldType.SELECT: {
      return <SelectPreview admin={admin} field={field} />
    }

    case FormFieldType.RADIO: {
      return <RadioGroupPreview field={field} />
    }
  }
}
