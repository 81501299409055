import type {PlayerIPResult} from '@app/common/api'
import {usePlayerIPs} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Heading, Stack, Text} from '@chakra-ui/react'
import {QuestionMarkCircleIcon} from '@heroicons/react/24/solid'
import type {TCountryCode} from 'countries-list'
import {getEmojiFlag} from 'countries-list'
import type React from 'react'
import {shallow} from 'zustand/shallow'

export default function AdminPlayerGameIPsModal(): React.JSX.Element {
  const [adminPlayer, setAdminPlayer] = useOverlayStore(
    state => [state.adminPlayerGameIp, state.setAdminPlayerGameIp],
    shallow,
  )

  return (
    <Modal header={`Game IPs for '${adminPlayer}'`} isOpen={Boolean(adminPlayer)} onClose={() => setAdminPlayer(null)}>
      <AdminPlayerGameIPsContent player={adminPlayer!} />
    </Modal>
  )
}

function AdminPlayerGameIPsContent({player}: {player: string}): React.JSX.Element {
  const {data: ips} = usePlayerIPs(player)
  if (!ips)
    return (
      <ModalBody align="center" as={Stack} h="full" justify="center" my={16}>
        <Spinner />
      </ModalBody>
    )

  return (
    <ModalBody as={Stack} gap="8px">
      {ips.length > 0 ? (
        <Stack>
          {ips.map(ipResult => (
            <PlayerIP ipResult={ipResult} key={ipResult.ip} />
          ))}
        </Stack>
      ) : (
        <Stack align="center" h="full" justify="center" py={16} textAlign="center">
          <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
          <Heading fontSize="2xl" fontWeight="bold">
            *cricket noises*
          </Heading>
          <Text color="whiteAlpha.800" fontSize="md">
            No game IPs found.
          </Text>
        </Stack>
      )}
    </ModalBody>
  )
}

function PlayerIP({ipResult}: {ipResult: PlayerIPResult}): React.JSX.Element {
  return (
    <Stack
      align="center"
      bgColor="gray.800"
      boxShadow="lg"
      direction="row"
      fontSize="sm"
      letterSpacing="tight"
      p={4}
      rounded="lg"
      userSelect="text"
    >
      <Stack align="center" direction="row" flex="1" spacing="14px">
        <Stack spacing="1">
          <Text fontWeight="semibold">
            <span>{getEmojiFlag(ipResult.countryCode as TCountryCode)}</span> {ipResult.city}, {ipResult.country},{' '}
            {ipResult.postalCode}
          </Text>

          <Text fontWeight="light">
            {ipResult.location.latitude}, {ipResult.location.longitude} &bull; {ipResult.location.accuracyRadius} km
            accuracy radius
          </Text>

          <Text fontWeight="light">
            <span>{getEmojiFlag(ipResult.countryCode as TCountryCode)}</span> {ipResult.ip} &bull;{' '}
            {ipResult.location.timeZone}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  )
}
