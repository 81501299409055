import type {Bucket, Experiment} from '@app/common/experiments'
import {useExperimentStore} from '@app/common/experiments'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import {PencilSquareIcon, PlusIcon, TrashIcon} from '@heroicons/react/24/solid'
import React from 'react'
import {toast} from 'react-hot-toast'
import TextareaAutosize from 'react-textarea-autosize'
import {shallow} from 'zustand/shallow'

export default function AdminExperimentsModal(): React.JSX.Element {
  const [adminExperimentsOpen, setAdminExperimentsOpen] = useOverlayStore(
    state => [state.adminExperimentsOpen, state.setAdminExperimentsOpen],
    shallow,
  )

  return (
    <Modal header="Experiments" isOpen={adminExperimentsOpen} onClose={() => setAdminExperimentsOpen(false)}>
      <AdminExperimentsModalContent />
    </Modal>
  )
}

function AdminExperimentsModalContent(): React.JSX.Element {
  const createModal = useDisclosure()
  const experiments = useExperimentStore(state => state.experiments)
  return (
    <ModalBody as={Stack} gap="8px">
      <ExperimentCreateModal {...createModal} />
      {experiments.map(experiment => (
        <ExperimentRenderer experiment={experiment} key={experiment.id} />
      ))}
      <Button
        colorScheme="orange"
        leftIcon={<PlusIcon height={16} width={16} />}
        onClick={() => createModal.onOpen()}
        size="sm"
      >
        Create Experiment
      </Button>
    </ModalBody>
  )
}

function ExperimentCreateModal({isOpen, onClose}: {isOpen: boolean; onClose(): void}): React.JSX.Element {
  const loadExperiments = useExperimentStore(state => state.loadExperiments)
  const [experimentId, setExperimentId] = React.useState('')
  const [experimentTitle, setExperimentTitle] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            colorScheme="orange"
            isDisabled={!experimentId || !experimentTitle}
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true)
                await api.put('/admin/experiments', {id: experimentId, title: experimentTitle})
                await loadExperiments()
                onClose()
              } catch {
                toast.error('Something went wrong! Please try again later.')
              } finally {
                setIsLoading(false)
              }
            }}
          >
            Create
          </Button>
        </ModalFooter>
      }
      header="Create Experiment"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody as={Stack} gap="8px">
        <FormControl>
          <FormLabel>Experiment ID</FormLabel>
          <Input
            onChange={event => setExperimentId(event.target.value)}
            placeholder="2023-01_experiment_id"
            value={experimentId}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Experiment Name</FormLabel>
          <Input
            onChange={event => setExperimentTitle(event.target.value)}
            placeholder="Take Over the Tri-State Area"
            value={experimentTitle}
          />
        </FormControl>
      </ModalBody>
    </Modal>
  )
}

function ExperimentRenderer({experiment}: {experiment: Experiment}) {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const loadExperiments = useExperimentStore(state => state.loadExperiments)
  const updateModal = useDisclosure()

  return (
    <Stack
      align="center"
      bgColor="gray.800"
      boxShadow="lg"
      direction="row"
      fontSize="sm"
      letterSpacing="tight"
      p={4}
      rounded="lg"
    >
      <Stack align="center" direction="row" flex="1" spacing="16px">
        <Stack spacing="1">
          <Text fontWeight="semibold" textTransform="uppercase">
            {experiment.id.split('_')[0]} - {experiment.title}
          </Text>

          <Text fontWeight="light">
            Default Bucket: <strong>{experiment.buckets.find(bucket => bucket.default)?.title ?? 'None'}</strong>
          </Text>
        </Stack>
      </Stack>

      <ExperimentUpdateModal experiment={experiment} {...updateModal} />
      <Stack direction="row" spacing="1.5">
        <Tooltip label="Delete Experiment">
          <IconButton
            aria-label="Delete Experiment"
            colorScheme="red"
            icon={<TrashIcon height={16} width={16} />}
            isLoading={isDeleting}
            onClick={async () => {
              try {
                setIsDeleting(true)
                await api.delete(`/admin/experiments/${experiment.id}`)
                await loadExperiments()
              } catch {
                toast.error('Failed to delete experiment')
              } finally {
                setIsDeleting(false)
              }
            }}
            rounded="full"
            size="sm"
          />
        </Tooltip>

        <Tooltip label="Update Experiment">
          <IconButton
            aria-label="Update Experiment"
            colorScheme="orange"
            icon={<PencilSquareIcon height={16} width={16} />}
            onClick={() => updateModal.onOpen()}
            rounded="full"
            size="sm"
          />
        </Tooltip>
      </Stack>
    </Stack>
  )
}

function ExperimentUpdateModal({
  experiment,
  isOpen,
  onClose,
}: {
  experiment: Experiment
  isOpen: boolean
  onClose(): void
}): React.JSX.Element {
  const [experimentTitle, setExperimentTitle] = React.useState(experiment.title)
  const [isLoading, setIsLoading] = React.useState(false)
  const createModal = useDisclosure()
  const loadExperiments = useExperimentStore(state => state.loadExperiments)

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            colorScheme="orange"
            isDisabled={!experimentTitle}
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true)
                await api.put('/admin/experiments', {id: experiment.id, title: experimentTitle})
                await loadExperiments()
                onClose()
              } catch {
                toast.error('Something went wrong! Please try again later.')
              } finally {
                setIsLoading(false)
              }
            }}
          >
            Update
          </Button>
        </ModalFooter>
      }
      header="Update Experiment"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody as={Stack} gap="8px">
        <FormControl>
          <FormLabel>Experiment ID</FormLabel>
          <Input isReadOnly value={experiment.id} />
        </FormControl>

        <FormControl>
          <FormLabel>Experiment Name</FormLabel>
          <Input
            onChange={event => setExperimentTitle(event.target.value)}
            placeholder="Take Over the Tri-State Area"
            value={experimentTitle}
          />
        </FormControl>

        <ExperimentBucketCreateModal experiment={experiment} {...createModal} />
        <FormControl>
          <FormLabel>Buckets</FormLabel>
          <Stack spacing="8px">
            <Button
              colorScheme="orange"
              leftIcon={<PlusIcon height={16} width={16} />}
              onClick={() => createModal.onOpen()}
              size="sm"
            >
              Create Experiment Bucket
            </Button>

            {experiment.buckets.map((bucket, index) => (
              <ExperimentBucketRenderer bucket={bucket} experiment={experiment} index={index} key={bucket.id} />
            ))}
          </Stack>
        </FormControl>
      </ModalBody>
    </Modal>
  )
}

function ExperimentBucketRenderer({
  experiment,
  bucket,
  index,
}: {
  experiment: Experiment
  bucket: Bucket
  index: number
}): React.JSX.Element {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const loadExperiments = useExperimentStore(state => state.loadExperiments)
  const updateModal = useDisclosure()

  return (
    <Stack
      align="center"
      bgColor="gray.800"
      boxShadow="lg"
      direction="row"
      fontSize="sm"
      letterSpacing="tight"
      p={4}
      rounded="lg"
    >
      <Stack align="center" direction="row" flex="1" spacing="16px">
        <Stack spacing="1">
          <Text fontWeight="semibold" textTransform="uppercase">
            {bucket.id === 'control' ? bucket.title : `Treatment ${index}: ${bucket.title}`}
          </Text>

          <Checkbox
            isChecked={bucket.default}
            isDisabled={isLoading}
            onChange={async event => {
              try {
                setIsLoading(true)
                await api.put(`/admin/experiments/${experiment.id}/buckets`, {
                  id: bucket.id,
                  default: event.target.checked,
                  title: bucket.title,
                  config: bucket.config,
                })
                await loadExperiments()
              } catch {
                toast.error('Something went wrong! Please try again later.')
              } finally {
                setIsLoading(false)
              }
            }}
          >
            Default Bucket
          </Checkbox>
        </Stack>
      </Stack>

      <ExperimentBucketUpdateModal bucket={bucket} experiment={experiment} {...updateModal} />
      <Stack direction="row" spacing="1.5">
        <Tooltip label="Delete Bucket">
          <IconButton
            aria-label="Delete Bucket"
            colorScheme="red"
            icon={<TrashIcon height={16} width={16} />}
            isLoading={isDeleting}
            onClick={async () => {
              try {
                setIsDeleting(true)
                await api.delete(`/admin/experiments/${experiment.id}/buckets/${bucket.id}`)
                await loadExperiments()
              } catch {
                toast.error('Failed to delete bucket')
              } finally {
                setIsDeleting(false)
              }
            }}
            rounded="full"
            size="sm"
          />
        </Tooltip>

        <Tooltip label="Update Bucket">
          <IconButton
            aria-label="Update Bucket"
            colorScheme="orange"
            icon={<PencilSquareIcon height={16} width={16} />}
            onClick={() => updateModal.onOpen()}
            rounded="full"
            size="sm"
          />
        </Tooltip>
      </Stack>
    </Stack>
  )
}

function ExperimentBucketCreateModal({
  experiment,
  isOpen,
  onClose,
}: {
  experiment: Experiment
  isOpen: boolean
  onClose(): void
}): React.JSX.Element {
  const [bucketConfig, setBucketConfig] = React.useState(JSON.stringify({enabled: true}, null, 2))
  const [bucketId, setBucketId] = React.useState(`treatment_${experiment.buckets.length}`)
  const [bucketName, setBucketName] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const loadExperiments = useExperimentStore(state => state.loadExperiments)

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            colorScheme="orange"
            isDisabled={!bucketId || !bucketName || !bucketConfig}
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true)
                await api.put(`/admin/experiments/${experiment.id}/buckets`, {
                  id: bucketId,
                  default: false,
                  title: bucketName,
                  config: JSON.parse(bucketConfig),
                })
                await loadExperiments()
                onClose()
              } catch {
                toast.error('Something went wrong! Please try again later.')
              } finally {
                setIsLoading(false)
              }
            }}
          >
            Create
          </Button>
        </ModalFooter>
      }
      header="Create Experiment"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody as={Stack} gap="8px">
        <FormControl>
          <FormLabel>Bucket ID</FormLabel>
          <Input onChange={event => setBucketId(event.target.value)} placeholder="treatment_1" value={bucketId} />
        </FormControl>

        <FormControl>
          <FormLabel>Bucket Name</FormLabel>
          <Input onChange={event => setBucketName(event.target.value)} placeholder="Enabled" value={bucketName} />
        </FormControl>

        <FormControl>
          <FormLabel>Bucket Config</FormLabel>
          <Textarea
            as={TextareaAutosize}
            onChange={event => setBucketConfig(event.target.value)}
            value={bucketConfig}
          />
        </FormControl>
      </ModalBody>
    </Modal>
  )
}

function ExperimentBucketUpdateModal({
  experiment,
  bucket,
  isOpen,
  onClose,
}: {
  experiment: Experiment
  bucket: Bucket
  isOpen: boolean
  onClose(): void
}): React.JSX.Element {
  const [bucketConfig, setBucketConfig] = React.useState(JSON.stringify(bucket.config, null, 2))
  const [bucketName, setBucketName] = React.useState(bucket.title)
  const [isLoading, setIsLoading] = React.useState(false)
  const loadExperiments = useExperimentStore(state => state.loadExperiments)

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            colorScheme="orange"
            isDisabled={!bucketName || !bucketConfig}
            isLoading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true)
                await api.put(`/admin/experiments/${experiment.id}/buckets`, {
                  id: bucket.id,
                  default: bucket.default,
                  title: bucketName,
                  config: JSON.parse(bucketConfig),
                })
                await loadExperiments()
                onClose()
              } catch {
                toast.error('Something went wrong! Please try again later.')
              } finally {
                setIsLoading(false)
              }
            }}
          >
            Update
          </Button>
        </ModalFooter>
      }
      header="Update Experiment Bucket"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody as={Stack} gap="8px">
        <FormControl>
          <FormLabel>Bucket ID</FormLabel>
          <Input isReadOnly value={bucket.id} />
        </FormControl>

        <FormControl>
          <FormLabel>Bucket Name</FormLabel>
          <Input onChange={event => setBucketName(event.target.value)} placeholder="Enabled" value={bucketName} />
        </FormControl>

        <FormControl>
          <FormLabel>Bucket Config</FormLabel>
          <Textarea
            as={TextareaAutosize}
            onChange={event => setBucketConfig(event.target.value)}
            value={bucketConfig}
          />
        </FormControl>
      </ModalBody>
    </Modal>
  )
}
