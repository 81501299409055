import type {ProxyServer} from '@app/common/api'
import {queryClient, useProxyList} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {ProxyListEntry} from '@app/components/Developer/ProxyListEntry'
import {AlertDialog} from '@app/components/Modal/AlertDialog'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {SequenceAnimation} from '@app/components/Sequencer/SequenceAnimation'
import {Spinner} from '@app/components/Spinner/Spinner'
import {api} from '@app/hooks/useApi'
import {Button, FormControl, FormLabel, Heading, Input, Stack, Text} from '@chakra-ui/react'
import {ArrowPathIcon} from '@heroicons/react/20/solid'
import {QuestionMarkCircleIcon} from '@heroicons/react/24/solid'
import React from 'react'
import {toast} from 'react-hot-toast'
import {shallow} from 'zustand/shallow'

export default function ProxyListModal(): React.JSX.Element {
  const [proxyListOpen, setProxyListOpen] = useOverlayStore(
    state => [state.developerProxyListOpen, state.setDeveloperProxyListOpen],
    shallow,
  )

  return (
    <Modal header={'Proxy Servers'} isOpen={proxyListOpen} onClose={() => setProxyListOpen(false)}>
      <ProxyListModalContent />
    </Modal>
  )
}

function ProxyListModalContent(): React.JSX.Element {
  const {data: proxies} = useProxyList()
  const [loading, setLoading] = React.useState(false)
  const [globalConfirmationOpen, setGlobalConfirmationOpen] = React.useState(false)
  const [globalShutdownBarrier, setGlobalShutdownBarrier] = React.useState(50)
  let globalRolloutInProgress = true

  if (!proxies) {
    return (
      <SequenceAnimation key={'proxies'}>
        <ModalBody align="center" as={Stack} h="full" justify="center" my={16}>
          <Spinner />
        </ModalBody>
      </SequenceAnimation>
    )
  }
  for (const proxy of proxies) {
    if (!proxy.state.gracefulShutdownInitiated) {
      globalRolloutInProgress = false
      break
    }
  }

  const regions: Record<string, ProxyServer[]> = {}
  let hasProductionServer = false

  if (proxies)
    for (const proxy of proxies) {
      if (proxy.state.production) hasProductionServer = true
      const region = proxy.id.split('-')[0]!.toUpperCase()
      if (regions && regions[region] !== undefined) {
        regions[region]!.push(proxy)
      } else {
        regions[region] = [proxy]
      }
    }

  return (
    <ModalBody as={Stack} gap="8px" h="full" overflowY="auto">
      <AlertDialog
        content={
          <>
            {loading ? (
              <Text>Pushing update to proxies...</Text>
            ) : (
              <Text>
                Are you sure that you want to {globalRolloutInProgress ? 'cancel the' : 'start a'} global rollout? This
                will {globalRolloutInProgress ? 'deactivate' : 'activate'} graceful restart for <strong>all</strong>{' '}
                proxies!
              </Text>
            )}

            <FormControl>
              <FormLabel>Graceful Shutdown Barrier</FormLabel>
              <Input
                onChange={event => setGlobalShutdownBarrier(Number.parseInt(event.target.value))}
                type="number"
                value={globalShutdownBarrier}
              />
            </FormControl>
          </>
        }
        isLoading={loading}
        isOpen={globalConfirmationOpen}
        onClose={() => setGlobalConfirmationOpen(false)}
        onPrimary={async () => {
          const newProxies: ProxyServer[] = JSON.parse(JSON.stringify(proxies))
          for (const proxy of newProxies) {
            proxy.state.gracefulShutdownInitiated = !globalRolloutInProgress
            proxy.state.gracefulShutdownBarrier = globalShutdownBarrier
          }

          setLoading(true)
          try {
            await api.patch(
              `/developer/proxy?intent=${globalRolloutInProgress ? 'cancel-rollout' : 'rollout'}`,
              newProxies,
            )

            await queryClient.invalidateQueries({queryKey: ['developer', 'proxy', 'list']})
            setGlobalConfirmationOpen(false)
          } catch {
            toast.error('Failed to restart proxy')
          } finally {
            setLoading(false)
          }
        }}
        primary={`${globalRolloutInProgress ? 'Cancel' : 'Begin'} global rollout`}
        title="Danger, Will Robinson!"
      />

      {proxies ? (
        proxies.length === 0 && (
          <Stack align="center" h="full" justify="center" py={16} textAlign="center">
            <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
            <Heading fontSize="2xl" fontWeight="bold">
              *cricket noises*
            </Heading>
            <Text color="whiteAlpha.800" fontSize="md">
              No proxies found. That's weird.
            </Text>
          </Stack>
        )
      ) : (
        <Spinner />
      )}

      {hasProductionServer ? (
        <Stack
          bg={'yellow.400'}
          color={'black'}
          direction="column"
          fontSize="sm"
          letterSpacing="tight"
          p={4}
          rounded="md"
        >
          <Text>
            <strong>Warning: </strong>Production servers!
          </Text>
          <Text>
            Some of the proxies below are production servers. Any action taken will directly affect the production
            network.
          </Text>
        </Stack>
      ) : (
        ''
      )}

      {globalRolloutInProgress ? (
        <Stack bg={'red.400'} color={'white'} direction="column" fontSize="sm" letterSpacing="tight" p={4} rounded="md">
          <Text>
            <strong>Warning: </strong>Global rollout in progress
          </Text>
          <Text>Global rollout is in progress. You can cancel the current rollout by clicking the button below.</Text>
        </Stack>
      ) : (
        ''
      )}

      <Button
        colorScheme="red"
        isLoading={false}
        leftIcon={<ArrowPathIcon height={16} width={16} />}
        onClick={async () => {
          setGlobalConfirmationOpen(true)
        }}
        size="sm"
        w="full"
      >
        {globalRolloutInProgress ? 'Cancel' : 'Start'} global rollout
      </Button>

      {Object.keys(regions)
        .sort()
        .map(region => (
          <RegionRenderer
            key={`region-${region}`}
            proxies={(regions[region] as ProxyServer[]).sort((p1, p2) => p1.id.localeCompare(p2.id)) as ProxyServer[]}
            region={region}
          />
        ))}
    </ModalBody>
  )
}

function RegionRenderer({region, proxies}: {region: string; proxies: ProxyServer[]}) {
  return (
    <Stack spacing="8px">
      <SmallHeading>
        {region} — {proxies?.length ?? 0}
      </SmallHeading>
      {proxies.map(proxy => (
        <ProxyListEntry key={`proxy-server-${proxy.podId}`} proxy={proxy} />
      ))}
    </Stack>
  )
}
