import {useOverlayStore} from '@app/common/stores'
import {SearchBar} from '@app/components/Search/SearchBar'
import {Box, Modal, ModalContent, ModalOverlay, Stack, Text} from '@chakra-ui/react'
import type React from 'react'
import {shallow} from 'zustand/shallow'

export function Code({children}: {children: React.ReactNode}): React.JSX.Element {
  return (
    <Box
      as="code"
      bg="gray.900"
      borderRadius="md"
      color="gray.200"
      display="inline-block"
      fontSize="11px"
      p="0.25em 0.5em"
    >
      {children}
    </Box>
  )
}

export default function SearchOverlay(): React.JSX.Element {
  const [quickSearchOpen, setQuickSearchOpen] = useOverlayStore(
    state => [state.quickSearchOpen, state.setQuickSearchOpen],
    shallow,
  )
  return (
    <Modal isCentered isOpen={quickSearchOpen} onClose={() => setQuickSearchOpen(false)}>
      <ModalOverlay />
      <ModalContent borderRadius="2xl" boxShadow="none" maxW="unset" overflow="auto" w="570px">
        <Stack p="16px" w="full">
          <SearchBar isOverlay onSuccessfulSearch={() => setQuickSearchOpen(false)} variant="main" />
          <Text fontSize="sm">
            <Box as="strong" color="green.400">
              PROTIP:
            </Box>{' '}
            Open the Quick Search by pressing <Code>Ctrl</Code>/<Code>⌘</Code> + <Code>K</Code>.
          </Text>
        </Stack>
      </ModalContent>
    </Modal>
  )
}
