import type {APIFormSubmissionResponse, APISelectOptionResponse} from '@app/common/api'
import type {FormSubmissionStatus} from '@app/common/constants'
import {
  FormSubmissionStatusToDescription,
  FormSubmissionStatusToString,
  SOMETHING_WENT_WRONG,
} from '@app/common/constants'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button, Textarea as ChakraTextarea, FormControl, FormLabel, Stack, Text} from '@chakra-ui/react'
import {useQueryClient} from '@tanstack/react-query'
import axios from 'axios'
import type {GroupBase, OptionProps} from 'chakra-react-select'
import {Select, chakraComponents} from 'chakra-react-select'
import type React from 'react'
import {useForm} from 'react-hook-form'
import {toast} from 'react-hot-toast'
import TextareaAutosize from 'react-textarea-autosize'
import ResponseTemplates from '@app/components/Modal/ResponseTemplates'

type FormStatusOption = {
  label: string
  value: FormSubmissionStatus
}

function SelectOption(props: OptionProps<APISelectOptionResponse>): React.JSX.Element {
  return (
    <chakraComponents.Option {...props}>
      <Stack direction="column" fontSize="sm" letterSpacing="tight" spacing="0.5">
        <Text fontWeight="semibold">{props.data.label}</Text>
        <Text color="whiteAlpha.800" fontSize="xs">
          {props.data.description}
        </Text>
      </Stack>
    </chakraComponents.Option>
  )
}

export default function RequestUpdateModal(props: {
  buttonRef: any
  isOpen: boolean
  onClose(): void
  submission: APIFormSubmissionResponse
}): React.JSX.Element {
  const queryClient = useQueryClient()
  const {formState, handleSubmit, register, setValue} = useForm({
    mode: 'onSubmit',
    defaultValues: {status: props.submission.status, reason: props.submission.reason},
  })

  async function onSubmit(data: any): Promise<void> {
    try {
      await api.patch(`admin/form-submissions/${props.submission.id}`, {
        status: data.status,
        reason: data.reason?.trim() || '',
      })
      await queryClient.invalidateQueries({queryKey: ['admin', 'form-submissions']})
      props.onClose()
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message ?? SOMETHING_WENT_WRONG)
      else toast.error(SOMETHING_WENT_WRONG)
    }
  }

  const options: FormStatusOption[] = Object.entries(FormSubmissionStatusToString).map(([key, value]) => ({
    label: value,
    value: Number(key) as FormSubmissionStatus,
    description: FormSubmissionStatusToDescription[Number(key) as FormSubmissionStatus],
  }))
  return (
    <Modal
      finalFocusRef={props.buttonRef}
      footer={
        <ModalFooter>
          <Button colorScheme="gray" mr={6} onClick={props.onClose} variant="link">
            Cancel
          </Button>
          <Button isLoading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </ModalFooter>
      }
      header="Update Request"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody as={Stack} fontSize="md" gap="8px">
          <FormControl>
            <FormLabel fontSize="sm" letterSpacing="tight">
              Status
            </FormLabel>
            <Select<FormStatusOption, false, GroupBase<FormStatusOption>>
              className="react-select"
              classNamePrefix="react-select"
              // @ts-expect-error ts(2322) this is fine
              components={{Option: SelectOption}}
              defaultValue={options.find(option => option.value === props.submission.status) ?? options[0]!}
              menuPortalTarget={document.body}
              name="status"
              onChange={value => setValue('status', value!.value)}
              options={options}
              selectedOptionStyle="check"
              size="sm"
              styles={{menuPortal: provided => ({...provided, zIndex: 9999})}}
              useBasicStyles
            />
          </FormControl>

          <FormControl>
            <FormLabel fontSize="sm" letterSpacing="tight">
              Review Message
            </FormLabel>
            <ChakraTextarea
              as={TextareaAutosize}
              {...register('reason', {maxLength: 4000})}
              defaultValue={props.submission.reason}
              name="reason"
              placeholder="Optional"
              rounded="md"
              size="sm"
            />
          </FormControl>

          <ResponseTemplates onClick={value => setValue('reason', value)} formType={props.submission.formType} />
        </ModalBody>
      </form>
    </Modal>
  )
}
