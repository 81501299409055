import {useCurrentUser} from '@app/common/api'
import {
  APIPermissions,
  FormType,
  GameModeToString,
  LeaderboardColumn,
  LeaderboardColumnToString,
} from '@app/common/constants'
import {useRequestsStore} from '@app/common/requests'
import {useOverlayStore} from '@app/common/stores'
import {canViewPunishmentStaffDetails, isAdmin, isDev, isStaff} from '@app/common/utils'
import {AccountGuildInvitesModal} from '@app/components/Account/AccountGuildInvitesModal'
import {AccountOfflineMessagesModal} from '@app/components/Account/AccountOfflineMessagesModal'
import {AccountUpdateModal} from '@app/components/Account/AccountUpdateModal'
import {AdminGiftCreateModal} from '@app/components/Admin/AdminGiftCreateModal'
import {AdminSubscriptionTransferModal} from '@app/components/Admin/AdminSubscriptionTransferModal'
import appStyles from '@app/components/App.module.css'
import {Avatar} from '@app/components/Avatar/Avatar'
import type {IMenuItem, TMenuItem} from '@app/components/Dropdown/Dropdown'
import {Dropdown} from '@app/components/Dropdown/Dropdown'
import {FriendModal} from '@app/components/Friend/FriendModal'
import {LogoIcon} from '@app/components/Logo/LogoIcon'
import {LogoOneLine} from '@app/components/Logo/LogoOneLine'
import {LogoutModal} from '@app/components/Modal/LogoutModal'
import {SearchBar} from '@app/components/Search/SearchBar'
import {CloseIcon, SearchIcon} from '@chakra-ui/icons'
import {Box, IconButton as ChakraIconButton, Flex, Text, useDisclosure} from '@chakra-ui/react'
import {
  AppBar,
  Button,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core'
import {ExpandLess, ExpandMore, Menu, MoreVert, Search} from '@material-ui/icons'
import React from 'react'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import {useMap} from 'usehooks-ts'

const useStyles = makeStyles(theme => ({
  list: {width: 250},
  navBar: {display: 'flex', gap: 8, justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0},
  nested: {paddingLeft: theme.spacing(4)},
  nestedSecond: {paddingLeft: theme.spacing(4)},
  nestedThird: {paddingLeft: theme.spacing(4)},
}))

const menuItemsFactions: TMenuItem[] = [
  {label: 'Strength', url: '/leaderboard/factions'},
  {label: 'Balance', url: '/leaderboard/factions?sortBy=balance'},
  {label: 'Best Streak', url: '/leaderboard/factionsPlayer?sortBy=bestStreak'},
  {label: 'Bounty', url: '/leaderboard/factionsPlayer?sortBy=bounty'},
  {label: 'Coins', url: '/leaderboard/factionsPlayer?sortBy=coins'},
  {label: 'Kills', url: '/leaderboard/factionsPlayer?sortBy=kills'},
  {label: 'Streak', url: '/leaderboard/factionsPlayer?sortBy=streak'},
  {label: 'XP', url: '/leaderboard/factionsPlayer?sortBy=xp'},
]

const menuItemsKills: TMenuItem[] = [
  {label: 'Global', url: '/leaderboard/kills'},
  ...Object.entries(GameModeToString)
    .map(([key, value]) => ({
      label: value,
      children: Object.values(LeaderboardColumn)
        .filter(column => column.startsWith(key) && column.endsWith('_kills'))
        .map(column => ({
          label: LeaderboardColumnToString[column]
            .replace(`${value} `, '')
            .replace(' Kills', '')
            .replace('Kills', 'Total'),
          url: `/leaderboard/kills/${column.replace('_kills', '')}`,
        }))
        .sort((a, b) => (a.label === 'Total' ? -1 : a.label.localeCompare(b.label))),
    }))
    .filter(({children}) => children.length > 0)
    .sort((a, b) => a.label.localeCompare(b.label)),
]

const menuItemsWins: TMenuItem[] = [
  {label: 'Global', url: '/leaderboard/wins'},
  ...Object.entries(GameModeToString)
    .map(([key, value]) => ({
      label: value,
      children: Object.values(LeaderboardColumn)
        .filter(column => column.startsWith(key) && column.endsWith('_wins'))
        .map(column => ({
          label: LeaderboardColumnToString[column]
            .replace(`${value} `, '')
            .replace(' Wins', '')
            .replace('Wins', 'Total'),
          url: `/leaderboard/wins/${column.replace('_wins', '')}`,
        }))
        .sort((a, b) => (a.label === 'Total' ? -1 : a.label.localeCompare(b.label))),
    }))
    .filter(({children}) => children.length > 0)
    .sort((a, b) => a.label.localeCompare(b.label)),
]

const menuItemsSkyblock: TMenuItem[] = [
  {label: 'Bank', url: '/leaderboard/skyblock?sortBy=bank'},
  {label: 'Bounty', url: '/leaderboard/skyblock?sortBy=bounty'},
  {label: 'Money', url: '/leaderboard/skyblock?sortBy=money'},
  {label: 'XP', url: '/leaderboard/skyblock?sortBy=xp'},
]

const menuItems: TMenuItem[] = [
  {label: 'Credits', url: '/leaderboard/credits'},
  {label: 'Guilds', url: '/leaderboard/guilds'},
  {label: 'Map Votes', url: '/leaderboard/mapVotes'},
  {label: 'Player Age', url: '/leaderboard/oldest'},
  {label: 'Parkour', url: '/leaderboard/parkour'},
  {label: 'Playtime', url: '/leaderboard/playtime'},
  {label: 'Tournament', url: '/leaderboard/tournament'},
  {label: 'Win Streaks', url: '/leaderboard/winStreaks?gameKey=bw_doubles&sortBy=best'},
  {label: 'Votes', url: '/leaderboard/voters'},
  {label: 'Factions', children: menuItemsFactions},
  {label: 'Kills', children: menuItemsKills},
  {label: 'Skyblock', children: menuItemsSkyblock},
  {label: 'Wins', children: menuItemsWins},
  {label: 'K/D', url: '/leaderboard/kdr'},
  {label: 'W/L', url: '/leaderboard/wlr'},
]

export function Header(): React.JSX.Element {
  const {data: user} = useCurrentUser()
  const location = useLocation()
  const styles = useStyles()

  const [expandMap, expandMapActions] = useMap<string, boolean>()
  const [searchMode, setSearchMode] = React.useState(false)
  const [state, setState] = React.useState({left: false})

  const overlay = useOverlayStore()
  const navigate = useRequestsStore(state => state.navigate)

  const accountUpdateModal = useDisclosure()
  const friendModal = useDisclosure()
  const giftCreateModal = useDisclosure()
  const subscriptionTransferModal = useDisclosure()
  const guildInvitesModal = useDisclosure()
  const logoutModal = useDisclosure()
  const offlineMessagesModal = useDisclosure()

  const collapseLogo = useMediaQuery('(max-width: 1000px)')
  const renderMobileAccessories = useMediaQuery('(max-width: 900px)')

  function toggleDrawer(open: boolean) {
    return (event: any) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return
      setState({left: open})
    }
  }

  function renderMenuItem(item: IMenuItem): React.JSX.Element {
    return (
      <ListItem
        button
        className={styles.nested}
        component={RouterLink}
        disableRipple
        key={item.label}
        onClick={toggleDrawer(false)}
        to={item.url}
      >
        <ListItemText primary={item.label} />
      </ListItem>
    )
  }

  function LeaderboardMenuItem(item: TMenuItem): React.JSX.Element {
    if ('children' in item)
      return (
        <>
          <ListItem
            button
            className={styles.nestedSecond}
            disableRipple
            onClick={() => expandMapActions.set(item.label, !expandMap.get(item.label))}
          >
            <ListItemText primary={item.label} />
            {expandMap.get(item.label) ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={Boolean(expandMap.get(item.label))} timeout="auto" unmountOnExit>
            <List className={styles.nestedThird} component="div" disablePadding>
              {item.children.map(data => LeaderboardMenuItem(data))}
            </List>
          </Collapse>
        </>
      )

    return renderMenuItem(item as any)
  }

  function SideList(): React.JSX.Element {
    return (
      <div className={styles.list} onKeyDown={toggleDrawer(false)} role="presentation">
        <List component="nav">
          <ListItem button disableRipple onClick={() => overlay.setRequestsOpen(true)}>
            <ListItemText primary="Contact Us" />
          </ListItem>

          <ListItem
            button
            component={RouterLink}
            disableRipple
            onClick={toggleDrawer(false)}
            to={user ? `/vote/${user.name}` : '/vote'}
          >
            <ListItemText primary="Vote Status" />
          </ListItem>

          <ListItem button component={RouterLink} disableRipple onClick={toggleDrawer(false)} to="/tools/xp-calculator">
            <ListItemText primary="XP Calculator" />
          </ListItem>

          <ListItem
            button
            disableRipple
            onClick={() => expandMapActions.set('Leaderboards', !expandMap.get('Leaderboards'))}
          >
            <ListItemText primary="Leaderboards" />
            {expandMap.get('Leaderboards') ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={Boolean(expandMap.get('Leaderboards'))} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {menuItems.map(item => LeaderboardMenuItem(item))}
            </List>
          </Collapse>

          <ListItem
            button
            component="a"
            disableRipple
            onClick={() => {
              setState({left: false})
              overlay.setSettingsOpen(true)
            }}
          >
            <ListItemText primary="Settings" />
          </ListItem>

          <ListItem button component={RouterLink} disableRipple onClick={toggleDrawer(false)} to="/auth/account">
            <ListItemText primary="Account" />
          </ListItem>
        </List>
      </div>
    )
  }

  if (renderMobileAccessories && searchMode)
    return (
      <AppBar className={appStyles.backdrop!} position="relative" style={{boxShadow: 'none'}}>
        <Toolbar className={styles.navBar} style={{margin: '0 8px'}}>
          <SearchBar onSuccessfulSearch={() => setSearchMode(false)} variant="headerMobile" />
          <ChakraIconButton
            aria-label="Exit Search Mode"
            boxSize="36px"
            colorScheme="gray"
            onClick={() => setSearchMode(false)}
            rounded="full"
            size="sm"
          >
            <CloseIcon />
          </ChakraIconButton>
        </Toolbar>
      </AppBar>
    )

  function MobileLeaderboardSelector({label, menuItems}: {label: string; menuItems: TMenuItem[]}): React.JSX.Element {
    return (
      <Dropdown checkedItem={location.pathname} isRadioList menuItems={menuItems}>
        <IconButton aria-label={label} disableRipple>
          <MoreVert />
        </IconButton>
      </Dropdown>
    )
  }

  const canManageEntitlements = user && (user.apiPermissions & APIPermissions.MANAGE_ENTITLEMENTS) !== 0
  const canManageRequests = user && (user.apiPermissions & ~APIPermissions.MANAGE_ENTITLEMENTS) !== 0

  const ADMIN_TOOLS = [
    ...(isAdmin(user)
      ? [
          {label: 'Punishment Reasons', onSelect: () => overlay.setAdminPunishmentReasonsOpen(true)},
          {label: 'Audit Log', onSelect: () => overlay.setAdminAuditLogOpen(true)},
          {label: 'Announcements', onSelect: () => overlay.setAdminServerAnnouncementsOpen(true)},
          {label: 'Changelog', onSelect: () => overlay.setAdminServerUpdatesOpen(true)},
          {label: 'Tournament', onSelect: () => overlay.setAdminTournamentOpen(true)},
          {label: 'Promotions', onSelect: () => overlay.setAdminPromosOpen(true)},
          {label: 'MOTD', onSelect: () => overlay.setAdminServerSettingsOpen(true)},
        ]
      : []),
  ]
  const STAFF_TOOLS = [
    ...(isStaff(user)
      ? [
          {label: 'Staff Directory', onSelect: () => overlay.setStaffDirectoryOpen(true)},
          {label: 'YouTube Directory', onSelect: () => overlay.setYoutubeDirectoryOpen(true)},
        ]
      : []),
    ...(canViewPunishmentStaffDetails(user)
      ? [{label: 'Punishments', onSelect: () => overlay.setAdminPunishmentsOpen(true)}]
      : []),
    ...(canManageRequests ? [{label: 'Requests', onSelect: () => overlay.setAdminRequestsOpen(true)}] : []),
    ...(canManageEntitlements ? [{label: 'Giveaway', onSelect: giftCreateModal.onOpen}] : []),
    ...(isAdmin(user) ? [{label: 'Subscription Transfer', onSelect: subscriptionTransferModal.onOpen}] : []),
  ]

  const DEV_TOOLS = [
    ...(isDev(user)
      ? [
          {label: 'Proxy Admin', onSelect: () => overlay.setDeveloperProxyListOpen(true)},
          {label: 'Experiments', onSelect: () => overlay.setAdminExperimentsOpen(true)},
        ]
      : []),
  ]

  const ACCOUNT_MENU_ITEMS = [
    ...(!renderMobileAccessories && isStaff(user) ? [{label: 'Staff Tools', children: STAFF_TOOLS}] : []),
    ...(renderMobileAccessories && isStaff(user) ? STAFF_TOOLS : []),
    ...(isAdmin(user) ? [{label: 'Administrative', children: ADMIN_TOOLS}] : []),
    ...(isDev(user) ? [{label: 'Developer', children: DEV_TOOLS}] : []),
    {label: 'Account Center', url: '/auth/account'},
    {label: 'Edit Profile', onSelect: accountUpdateModal.onOpen},
    {label: 'My Profile', url: user ? `/player/${user.name}` : '/auth/account'},
    {label: 'My Friends', onSelect: friendModal.onOpen},
    {
      label: 'My Requests',
      onSelect: () => {
        navigate(FormType.SUBMISSIONS_SCREEN)
        overlay.setRequestsOpen(true)
      },
    },
    ...(user?.guild ? [{label: 'My Guild', url: `/guild/${user.guild}`}] : []),
    {label: 'API Integrations', url: '/auth/applications'},
    {label: 'Guild Invites', onSelect: guildInvitesModal.onOpen},
    {label: 'Offline Messages', onSelect: offlineMessagesModal.onOpen},
    {label: 'Portal Settings', onSelect: () => overlay.setSettingsOpen(true)},
    {label: 'Tiers Summary', onSelect: () => overlay.setRankTierSummaryOpen(true)},
    {label: 'Log Out', onSelect: logoutModal.onOpen, danger: true},
  ]

  return (
    <AppBar className={appStyles.backdrop!} position="relative" style={{boxShadow: 'none'}}>
      <Toolbar className={styles.navBar} style={{margin: `0 ${renderMobileAccessories ? 8 : 16}px`}}>
        {renderMobileAccessories && (
          <Box flexBasis={1} flexGrow={1} flexShrink={0}>
            <IconButton aria-label="Open Drawer" disableRipple onClick={toggleDrawer(true)}>
              <Menu />
            </IconButton>
            <Drawer anchor="left" onClose={toggleDrawer(false)} open={state.left}>
              <SideList />
            </Drawer>
          </Box>
        )}

        {user && <AccountUpdateModal {...accountUpdateModal} user={user} />}
        <AccountGuildInvitesModal {...guildInvitesModal} />
        <AccountOfflineMessagesModal {...offlineMessagesModal} />
        <AdminGiftCreateModal {...giftCreateModal} />
        <AdminSubscriptionTransferModal {...subscriptionTransferModal} />
        <FriendModal {...friendModal} />
        <LogoutModal {...logoutModal} />

        <RouterLink aria-label="Home" to="/">
          {collapseLogo || renderMobileAccessories ? (
            <LogoIcon height="2.5rem" style={{cursor: 'pointer'}} />
          ) : (
            <LogoOneLine height="2.5rem" style={{cursor: 'pointer'}} />
          )}
        </RouterLink>

        {renderMobileAccessories ? (
          <Flex flexBasis={1} flexGrow={1} justifyContent="flex-end" ml="auto">
            {location.pathname !== '/' && (
              <IconButton
                aria-label="Search"
                disableRipple
                onClick={() => setSearchMode(true)}
                style={{width: 56, height: 56}}
              >
                <Search />
              </IconButton>
            )}
            {location.pathname.startsWith('/leaderboard/wins') && (
              <MobileLeaderboardSelector label="Choose Game" menuItems={menuItemsWins} />
            )}
            {location.pathname.startsWith('/leaderboard/kills') && (
              <MobileLeaderboardSelector label="Choose Game" menuItems={menuItemsKills} />
            )}
            {location.pathname.startsWith('/leaderboard/factions') && (
              <MobileLeaderboardSelector label="Choose Type" menuItems={menuItemsFactions} />
            )}

            {user ? (
              <Dropdown menuItems={ACCOUNT_MENU_ITEMS as any}>
                <IconButton aria-label="Toggle Account Menu" disableRipple>
                  <Avatar
                    name={user.name}
                    online={user.online}
                    shouldShowOnlineTooltip={false}
                    size={32}
                    skinHash={user.skinHash}
                  />
                </IconButton>
              </Dropdown>
            ) : (
              <Button component={RouterLink} disableRipple to="/auth/account">
                Login
              </Button>
            )}
          </Flex>
        ) : (
          <Flex gap="8px" ml="auto">
            <Button
              disableRipple
              onClick={() => overlay.setQuickSearchOpen(true)}
              startIcon={<SearchIcon boxSize="12px" />}
            >
              Search
            </Button>

            <Button disableRipple onClick={() => overlay.setRequestsOpen(true)}>
              Requests
            </Button>

            <Button component={RouterLink} disableRipple to={user ? `/vote/${user.name}` : '/vote'}>
              Vote Status
            </Button>
            <Button component={RouterLink} disableRipple to="/tools/xp-calculator">
              XP Calculator
            </Button>

            <Dropdown menuItems={menuItems}>
              <Button aria-label="Toggle Leaderboards Menu" disableRipple>
                Leaderboards
                <ExpandMore style={{opacity: 0.42, marginRight: -6}} />
              </Button>
            </Dropdown>

            {user ? (
              <Dropdown menuItems={ACCOUNT_MENU_ITEMS as any}>
                <Button aria-label="Toggle Account Menu" disableRipple>
                  <Flex align="center" justify="center">
                    <Avatar
                      name={user.name}
                      online={user.online}
                      shouldShowOnlineTooltip={false}
                      size={32}
                      skinHash={user.skinHash}
                    />
                    <Text fontWeight="bold" ml={2} textTransform="none">
                      {user.name}
                    </Text>
                  </Flex>
                  <ExpandMore style={{opacity: 0.42, marginRight: -6}} />
                </Button>
              </Dropdown>
            ) : (
              <>
                <Button component="a" disableRipple onClick={() => overlay.setSettingsOpen(true)}>
                  Settings
                </Button>
                <Button component={RouterLink} disableRipple to="/auth/account">
                  Login
                </Button>
              </>
            )}
          </Flex>
        )}
      </Toolbar>
    </AppBar>
  )
}
