import type {BackupCode} from '@app/common/api'
import type {ChatLogContexts, FormSubmissionStatus, FormType} from '@app/common/constants'
import {DEVELOPER} from '@app/common/constants'
import {CountryCode} from '@app/common/countries'
import {DEFAULT_STATUS_VALUE} from '@app/components/Modal/AdminRequestsModal'
import {api} from '@app/hooks/useApi'
import type {DefaultValues} from 'react-hook-form'
import {create} from 'zustand'
import {devtools, persist} from 'zustand/middleware'

type OverlayStore = {
  adminAltsPlayer: string | null
  adminApplicationId: string | null
  adminAuditLogOpen: boolean
  adminExperimentsOpen: boolean
  adminPlayer: string | null
  adminPlayerGameIp: string | null
  adminPlayerWebIp: string | null
  adminPromosOpen: boolean
  adminPunishmentsOpen: boolean
  adminRequestsOffenderXuid: string[]
  adminRequestsOpen: boolean
  adminRequestsPlayerXuid: string[]
  adminRequestsReviewedBy: string[]
  adminRequestsStatus: FormSubmissionStatus[]
  adminRequestsType: FormType[]
  adminServerAnnouncementsOpen: boolean
  adminPunishmentReasonsOpen: boolean
  adminServerSettingsOpen: boolean
  adminServerUpdatesOpen: boolean
  adminSystemMessagePlayer: string | null
  developerProxyListOpen: boolean
  chatLogsOpen: string | null
  chatLogsContexts: ChatLogContexts[]
  adminTournamentOpen: boolean
  applicationId: string | null
  externalLinkUrl: string | null
  mfaBackupCodes: BackupCode[]
  mfaEnableForceOpen: boolean
  mfaEnableOpen: boolean
  mfaVerifyOpen: boolean
  punishmentIds: string[]
  punishmentPlayer: string | null
  quickSearchOpen: boolean
  rankTierSummaryOpen: boolean
  requestId: string | null
  requestsOpen: boolean
  settingsOpen: boolean
  splashScreenOpen: boolean
  staffDirectoryOpen: boolean
  tournamentDetails: boolean
  youtubeDirectoryOpen: boolean
  setAdminAltsPlayer(value: string | null): void
  setAdminApplicationId(value: string | null): void
  setAdminAuditLogOpen(value: boolean): void
  setAdminExperimentsOpen(value: boolean): void
  setAdminPlayer(value: string | null): void
  setAdminPlayerGameIp(value: string | null): void
  setAdminPlayerWebIp(value: string | null): void
  setAdminPromosOpen(value: boolean): void
  setAdminPunishmentReasonsOpen(value: boolean): void
  setAdminPunishmentsOpen(value: boolean): void
  setAdminRequestsOffenderXuid(value: string[]): void
  setAdminRequestsOpen(value: boolean): void
  setAdminRequestsPlayerXuid(value: string[]): void
  setAdminRequestsReviewedBy(value: string[]): void
  setAdminRequestsStatus(value: FormSubmissionStatus[]): void
  setAdminRequestsType(value: FormType[]): void
  setAdminServerAnnouncementsOpen(value: boolean): void
  setAdminServerSettingsOpen(value: boolean): void
  setAdminServerUpdatesOpen(value: boolean): void
  setAdminSystemMessagePlayer(value: string | null): void
  setAdminTournamentOpen(value: boolean): void
  setDeveloperProxyListOpen(value: boolean): void
  setApplicationId(value: string | null): void
  setChatLogsOpen(player: string | null): void
  setChatLogContexts(value: ChatLogContexts[]): void
  setExternalLinkUrl(value: string | null): void
  setMfaBackupCodes(value: BackupCode[]): void
  setMfaEnableOpen(value: boolean, force?: boolean): void
  setMfaVerifyOpen(value: boolean): void
  setPunishmentPlayer(value: string | null, punishmentIds?: string[]): void
  setQuickSearchOpen(value: boolean): void
  setRankTierSummaryOpen(value: boolean): void
  setRequestId(value: string | null): void
  setRequestsOpen(value: boolean): void
  setSettingsOpen(value: boolean): void
  setSplashScreenOpen(value: boolean): void
  setStaffDirectoryOpen(value: boolean): void
  setTournamentDetails(value: boolean): void
  setYoutubeDirectoryOpen(value: boolean): void
}

export const useOverlayStore = create<OverlayStore>()(
  devtools(
    set => ({
      adminAltsPlayer: null,
      adminApplicationId: null,
      adminAuditLogOpen: false,
      adminExperimentsOpen: false,
      adminPlayer: null,
      adminPlayerGameIp: null,
      adminPlayerWebIp: null,
      adminPromosOpen: false,
      adminPunishmentsOpen: false,
      adminRequestsOffenderXuid: [],
      adminRequestsOpen: false,
      adminRequestsPlayerXuid: [],
      adminRequestsReviewedBy: [],
      adminRequestsStatus: DEFAULT_STATUS_VALUE,
      adminRequestsType: [],
      adminServerAnnouncementsOpen: false,
      adminServerSettingsOpen: false,
      adminServerUpdatesOpen: false,
      adminPunishmentReasonsOpen: false,
      adminSystemMessagePlayer: null,
      adminTournamentOpen: false,
      developerProxyListOpen: false,
      applicationId: null,
      externalLinkUrl: null,
      chatLogsOpen: null,
      mfaBackupCodes: [],
      mfaEnableForceOpen: false,
      mfaEnableOpen: false,
      mfaVerifyOpen: false,
      punishmentIds: [],
      chatLogsContexts: [],
      punishmentPlayer: null,
      quickSearchOpen: false,
      rankTierSummaryOpen: false,
      requestId: null,
      requestsOpen: false,
      settingsOpen: false,
      splashScreenOpen: false,
      staffDirectoryOpen: false,
      tournamentDetails: false,
      youtubeDirectoryOpen: false,
      setChatLogsOpen: value => set({chatLogsOpen: value}),
      setAdminAltsPlayer: value => set({adminAltsPlayer: value}),
      setAdminApplicationId: value => set({adminApplicationId: value}),
      setAdminAuditLogOpen: value => set({adminAuditLogOpen: value}),
      setAdminExperimentsOpen: value => set({adminExperimentsOpen: value}),
      setAdminPlayer: value => set({adminPlayer: value}),
      setAdminPlayerGameIp: value => set({adminPlayerGameIp: value}),
      setAdminPlayerWebIp: value => set({adminPlayerWebIp: value}),
      setAdminPromosOpen: value => set({adminPromosOpen: value}),
      setAdminPunishmentsOpen: value => set({adminPunishmentsOpen: value}),
      setAdminRequestsOffenderXuid: value => set({adminRequestsOffenderXuid: value}),
      setAdminPunishmentReasonsOpen: value => set({adminPunishmentReasonsOpen: value}),
      setChatLogContexts: value => set({chatLogsContexts: value}),
      setAdminRequestsOpen: value => set({adminRequestsOpen: value}),
      setAdminRequestsPlayerXuid: value => set({adminRequestsPlayerXuid: value}),
      setAdminRequestsReviewedBy: value => set({adminRequestsReviewedBy: value}),
      setAdminRequestsStatus: value => set({adminRequestsStatus: value}),
      setAdminRequestsType: value => set({adminRequestsType: value}),
      setAdminServerAnnouncementsOpen: value => set({adminServerAnnouncementsOpen: value}),
      setAdminServerSettingsOpen: value => set({adminServerSettingsOpen: value}),
      setAdminServerUpdatesOpen: value => set({adminServerUpdatesOpen: value}),
      setAdminSystemMessagePlayer: value => set({adminSystemMessagePlayer: value}),
      setAdminTournamentOpen: value => set({adminTournamentOpen: value}),
      setDeveloperProxyListOpen: value => set({developerProxyListOpen: value}),
      setApplicationId: value => set({applicationId: value}),
      setExternalLinkUrl: value => set({externalLinkUrl: value}),
      setMfaBackupCodes: backupCodes => set({mfaBackupCodes: backupCodes}),
      setMfaEnableOpen: (value, force = false) => set({mfaEnableOpen: value, mfaEnableForceOpen: force}),
      setMfaVerifyOpen: value => set({mfaVerifyOpen: value}),
      setPunishmentPlayer: (value, punishmentIds = []) => set({punishmentPlayer: value, punishmentIds}),
      setQuickSearchOpen: value => set({quickSearchOpen: value}),
      setRankTierSummaryOpen: value => set({rankTierSummaryOpen: value}),
      setRequestId: value => set({requestId: value}),
      setRequestsOpen: value => set({requestsOpen: value}),
      setSettingsOpen: value => set({settingsOpen: value}),
      setSplashScreenOpen: value => set({splashScreenOpen: value}),
      setStaffDirectoryOpen: value => set({staffDirectoryOpen: value}),
      setTournamentDetails: value => set({tournamentDetails: value}),
      setYoutubeDirectoryOpen: value => set({youtubeDirectoryOpen: value}),
    }),
    {name: 'OverlayStore'},
  ),
)

type SettingsStore = {
  bypassAnonymous: boolean
  bypassFormRequirements: boolean
  developerMode: boolean
  reducedMotion: boolean
  renderSkins: boolean
  skinProxyUrl: string | null
  syncReducedMotion: boolean
  setBypassAnonymous(value: boolean): void
  setBypassFormRequirements(value: boolean): void
  setDeveloperMode(value: boolean): void
  setReducedMotion(value: boolean): void
  setRenderSkins(value: boolean): void
  setSkinProxyUrl(value: string): void
  setSyncReducedMotion(value: boolean): void
}

export const useSettingsStore = create<SettingsStore>()(
  devtools(
    persist(
      set => ({
        bypassAnonymous: false,
        bypassFormRequirements: false,
        developerMode: DEVELOPER,
        reducedMotion: window.matchMedia('(prefers-reduced-motion: reduce)').matches,
        renderSkins: true,
        skinProxyUrl: null,
        syncReducedMotion: true,
        setBypassAnonymous: value => set({bypassAnonymous: value}),
        setBypassFormRequirements: value => set({bypassFormRequirements: value}),
        setDeveloperMode: value => set({developerMode: value}),
        setReducedMotion: value => set({reducedMotion: value}),
        setRenderSkins: value => set({renderSkins: value}),
        setSkinProxyUrl: value => set({skinProxyUrl: value}),
        setSyncReducedMotion: value => set({syncReducedMotion: value}),
      }),
      {name: 'SettingsStore', version: 1},
    ),
  ),
)

type DraftStore = {
  drafts: Partial<Record<FormType, DefaultValues<any>>>
  setDraft(form: FormType, values: DefaultValues<any>): void
  reset(): void
}

export const useDraftStore = create<DraftStore>()(
  devtools(
    persist(
      set => ({
        drafts: {},
        setDraft: (form, values) => set(state => ({drafts: {...state.drafts, [form]: values}})),
        reset: () => set({drafts: {}}),
      }),
      {name: 'DraftStore', version: 1},
    ),
  ),
)

type BanStore = {
  players: Record<string, number> // xuid -> timestamp
  addPlayer(xuid: string): void
  isPlayerBanned(xuid: string): boolean
}

export const useBanStore = create<BanStore>()(
  devtools(
    persist(
      (set, get) => ({
        players: {},
        addPlayer: xuid => set(state => ({players: {...state.players, [xuid]: Date.now()}})),
        isPlayerBanned: xuid => {
          const players = get().players
          if (xuid in players) {
            if (Date.now() - players[xuid]! < 1000 * 60 * 60 * 24) {
              return true
            }

            set(state => {
              const players = {...state.players}

              delete players[xuid]
              return {players}
            })

            return false
          }

          return false
        },
      }),
      {name: 'BanStore', version: 1},
    ),
  ),
)

type CountryCodeStore = {
  countryCode: CountryCode
  setCountryCode(countryCode: CountryCode): void
  loadCountryCode(): Promise<void>
}

export const useCountryCodeStore = create<CountryCodeStore>()(
  devtools(
    persist(
      set => ({
        countryCode: CountryCode.US,
        setCountryCode: (countryCode: CountryCode) => set({countryCode}),
        loadCountryCode: async () => {
          const {data} = await api.get<{countryCode: CountryCode}>('users/@me/billing/country-code')
          set({countryCode: data.countryCode})
        },
      }),
      {name: 'CountryCodeStore', version: 1},
    ),
  ),
)

type DelayedFormsStore = {
  delayedForms: Partial<Record<FormType, boolean>>
  isFormDelayed(form: FormType): boolean
  loadDelayedForms(): Promise<void>
}

export const useDelayedFormsStore = create<DelayedFormsStore>()(
  devtools(
    persist(
      (set, get) => ({
        delayedForms: {},
        isFormDelayed: (form: FormType) => form in get().delayedForms && get().delayedForms[form] === true,
        loadDelayedForms: async () => {
          const {data} = await api.get<Record<FormType, boolean>>('delayed-forms')
          set({delayedForms: data})
        },
      }),
      {name: 'DelayedFormsStore', version: 1},
    ),
  ),
)
