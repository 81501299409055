export enum CountryCode {
  AC = 'AC',
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DG = 'DG',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MI = 'MI',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TP = 'TP',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

export const COUNTRIES = [
  {label: 'Afghanistan', value: CountryCode.AF},
  {label: 'Åland Islands', value: CountryCode.AX},
  {label: 'Albania', value: CountryCode.AL},
  {label: 'Algeria', value: CountryCode.DZ},
  {label: 'American Samoa', value: CountryCode.AS},
  {label: 'Andorra', value: CountryCode.AD},
  {label: 'Angola', value: CountryCode.AO},
  {label: 'Anguilla', value: CountryCode.AI},
  {label: 'Antarctica', value: CountryCode.AQ},
  {label: 'Antigua and Barbuda', value: CountryCode.AG},
  {label: 'Argentina', value: CountryCode.AR},
  {label: 'Armenia', value: CountryCode.AM},
  {label: 'Aruba', value: CountryCode.AW},
  {label: 'Ascension', value: CountryCode.AC},
  {label: 'Australia', value: CountryCode.AU},
  {label: 'Austria', value: CountryCode.AT},
  {label: 'Azerbaijan', value: CountryCode.AZ},
  {label: 'Bahamas', value: CountryCode.BS},
  {label: 'Bahrain', value: CountryCode.BH},
  {label: 'Bangladesh', value: CountryCode.BD},
  {label: 'Barbados', value: CountryCode.BB},
  {label: 'Belarus', value: CountryCode.BY},
  {label: 'Belgium', value: CountryCode.BE},
  {label: 'Belize', value: CountryCode.BZ},
  {label: 'Benin', value: CountryCode.BJ},
  {label: 'Bermuda', value: CountryCode.BM},
  {label: 'Bhutan', value: CountryCode.BT},
  {label: 'Bolivia, Plurinational State of', value: CountryCode.BO},
  {label: 'Bonaire, Sint Eustatius and Saba', value: CountryCode.BQ},
  {label: 'Bosnia and Herzegovina', value: CountryCode.BA},
  {label: 'Botswana', value: CountryCode.BW},
  {label: 'Bouvet Island', value: CountryCode.BV},
  {label: 'Brazil', value: CountryCode.BR},
  {label: 'British Indian Ocean Territory', value: CountryCode.IO},
  {label: 'Brunei Darussalam', value: CountryCode.BN},
  {label: 'Bulgaria', value: CountryCode.BG},
  {label: 'Burkina Faso', value: CountryCode.BF},
  {label: 'Burundi', value: CountryCode.BI},
  {label: 'Cambodia', value: CountryCode.KH},
  {label: 'Cameroon', value: CountryCode.CM},
  {label: 'Canada', value: CountryCode.CA},
  {label: 'Cape Verde', value: CountryCode.CV},
  {label: 'Cayman Islands', value: CountryCode.KY},
  {label: 'Central African Republic', value: CountryCode.CF},
  {label: 'Chad', value: CountryCode.TD},
  {label: 'Chile', value: CountryCode.CL},
  {label: 'China', value: CountryCode.CN},
  {label: 'Christmas Island', value: CountryCode.CX},
  {label: 'Cocos (Keeling) Islands', value: CountryCode.CC},
  {label: 'Colombia', value: CountryCode.CO},
  {label: 'Comoros', value: CountryCode.KM},
  {label: 'Congo, The Democratic Republic of the', value: CountryCode.CD},
  {label: 'Congo', value: CountryCode.CG},
  {label: 'Cook Islands', value: CountryCode.CK},
  {label: 'Costa Rica', value: CountryCode.CR},
  {label: "Cote d'Ivoire", value: CountryCode.CI},
  {label: 'Croatia', value: CountryCode.HR},
  {label: 'Cuba', value: CountryCode.CU},
  {label: 'Curacao', value: CountryCode.CW},
  {label: 'Cyprus', value: CountryCode.CY},
  {label: 'Czech Republic', value: CountryCode.CZ},
  {label: 'Denmark', value: CountryCode.DK},
  {label: 'Diego Garcia', value: CountryCode.DG},
  {label: 'Djibouti', value: CountryCode.DJ},
  {label: 'Dominica', value: CountryCode.DM},
  {label: 'Dominican Republic', value: CountryCode.DO},
  {label: 'East Timor', value: CountryCode.TP},
  {label: 'Ecuador', value: CountryCode.EC},
  {label: 'Egypt', value: CountryCode.EG},
  {label: 'El Salvador', value: CountryCode.SV},
  {label: 'Equatorial Guinea', value: CountryCode.GQ},
  {label: 'Eritrea', value: CountryCode.ER},
  {label: 'Estonia', value: CountryCode.EE},
  {label: 'Ethiopia', value: CountryCode.ET},
  {label: 'Falkland Islands (Malvinas)', value: CountryCode.FK},
  {label: 'Faroe Islands', value: CountryCode.FO},
  {label: 'Fiji', value: CountryCode.FJ},
  {label: 'Finland', value: CountryCode.FI},
  {label: 'France', value: CountryCode.FR},
  {label: 'French Guiana', value: CountryCode.GF},
  {label: 'French Polynesia', value: CountryCode.PF},
  {label: 'French Southern Territories', value: CountryCode.TF},
  {label: 'Gabon', value: CountryCode.GA},
  {label: 'Gambia', value: CountryCode.GM},
  {label: 'Georgia', value: CountryCode.GE},
  {label: 'Germany', value: CountryCode.DE},
  {label: 'Ghana', value: CountryCode.GH},
  {label: 'Gibraltar', value: CountryCode.GI},
  {label: 'Greece', value: CountryCode.GR},
  {label: 'Greenland', value: CountryCode.GL},
  {label: 'Grenada', value: CountryCode.GD},
  {label: 'Guadeloupe', value: CountryCode.GP},
  {label: 'Guam', value: CountryCode.GU},
  {label: 'Guatemala', value: CountryCode.GT},
  {label: 'Guernsey', value: CountryCode.GG},
  {label: 'Guinea-Bissau', value: CountryCode.GW},
  {label: 'Guinea', value: CountryCode.GN},
  {label: 'Guyana', value: CountryCode.GY},
  {label: 'Haiti', value: CountryCode.HT},
  {label: 'Heard Island and McDonald Islands', value: CountryCode.HM},
  {label: 'Holy See (Vatican City State)', value: CountryCode.VA},
  {label: 'Honduras', value: CountryCode.HN},
  {label: 'Hong Kong', value: CountryCode.HK},
  {label: 'Hungary', value: CountryCode.HU},
  {label: 'Iceland', value: CountryCode.IS},
  {label: 'India', value: CountryCode.IN},
  {label: 'Indonesia', value: CountryCode.ID},
  {label: 'Iran, Islamic Republic of', value: CountryCode.IR},
  {label: 'Iraq', value: CountryCode.IQ},
  {label: 'Ireland', value: CountryCode.IE},
  {label: 'Isle of Man', value: CountryCode.IM},
  {label: 'Israel', value: CountryCode.IL},
  {label: 'Italy', value: CountryCode.IT},
  {label: 'Jamaica', value: CountryCode.JM},
  {label: 'Japan', value: CountryCode.JP},
  {label: 'Jersey', value: CountryCode.JE},
  {label: 'Jordan', value: CountryCode.JO},
  {label: 'Kazakhstan', value: CountryCode.KZ},
  {label: 'Kenya', value: CountryCode.KE},
  {label: 'Kiribati', value: CountryCode.KI},
  {label: 'Korea, Republic of', value: CountryCode.KR},
  {label: 'Kosovo', value: CountryCode.XK},
  {label: 'Kuwait', value: CountryCode.KW},
  {label: 'Kyrgyzstan', value: CountryCode.KG},
  {label: "Lao People's Democratic Republic", value: CountryCode.LA},
  {label: 'Latvia', value: CountryCode.LV},
  {label: 'Lebanon', value: CountryCode.LB},
  {label: 'Lesotho', value: CountryCode.LS},
  {label: 'Liberia', value: CountryCode.LR},
  {label: 'Libya', value: CountryCode.LY},
  {label: 'Liechtenstein', value: CountryCode.LI},
  {label: 'Lithuania', value: CountryCode.LT},
  {label: 'Luxembourg', value: CountryCode.LU},
  {label: 'Macao', value: CountryCode.MO},
  {label: 'Macedonia, Republic of', value: CountryCode.MK},
  {label: 'Madagascar', value: CountryCode.MG},
  {label: 'Malawi', value: CountryCode.MW},
  {label: 'Malaysia', value: CountryCode.MY},
  {label: 'Maldives', value: CountryCode.MV},
  {label: 'Mali', value: CountryCode.ML},
  {label: 'Malta', value: CountryCode.MT},
  {label: 'Marshall Islands', value: CountryCode.MH},
  {label: 'Martinique', value: CountryCode.MQ},
  {label: 'Mauritania', value: CountryCode.MR},
  {label: 'Mauritius', value: CountryCode.MU},
  {label: 'Mayotte', value: CountryCode.YT},
  {label: 'Mexico', value: CountryCode.MX},
  {label: 'Micronesia, Federated States of', value: CountryCode.FM},
  {label: 'Midway Island', value: CountryCode.MI},
  {label: 'Moldova, Republic of', value: CountryCode.MD},
  {label: 'Monaco', value: CountryCode.MC},
  {label: 'Mongolia', value: CountryCode.MN},
  {label: 'Montenegro', value: CountryCode.ME},
  {label: 'Montserrat', value: CountryCode.MS},
  {label: 'Morocco', value: CountryCode.MA},
  {label: 'Mozambique', value: CountryCode.MZ},
  {label: 'Myanmar', value: CountryCode.MM},
  {label: 'Namibia', value: CountryCode.NA},
  {label: 'Nauru', value: CountryCode.NR},
  {label: 'Nepal', value: CountryCode.NP},
  {label: 'Netherlands Antilles', value: CountryCode.AN},
  {label: 'Netherlands', value: CountryCode.NL},
  {label: 'New Caledonia', value: CountryCode.NC},
  {label: 'New Zealand', value: CountryCode.NZ},
  {label: 'Nicaragua', value: CountryCode.NI},
  {label: 'Niger', value: CountryCode.NE},
  {label: 'Nigeria', value: CountryCode.NG},
  {label: 'Niue', value: CountryCode.NU},
  {label: 'Norfolk Island', value: CountryCode.NF},
  {label: 'Northern Mariana Islands', value: CountryCode.MP},
  {label: 'Norway', value: CountryCode.NO},
  {label: 'Oman', value: CountryCode.OM},
  {label: 'Pakistan', value: CountryCode.PK},
  {label: 'Palau', value: CountryCode.PW},
  {label: 'Palestine, State of', value: CountryCode.PS},
  {label: 'Panama', value: CountryCode.PA},
  {label: 'Papua New Guinea', value: CountryCode.PG},
  {label: 'Paraguay', value: CountryCode.PY},
  {label: 'Peru', value: CountryCode.PE},
  {label: 'Philippines', value: CountryCode.PH},
  {label: 'Pitcairn', value: CountryCode.PN},
  {label: 'Poland', value: CountryCode.PL},
  {label: 'Portugal', value: CountryCode.PT},
  {label: 'Puerto Rico', value: CountryCode.PR},
  {label: 'Qatar', value: CountryCode.QA},
  {label: 'Reunion', value: CountryCode.RE},
  {label: 'Romania', value: CountryCode.RO},
  {label: 'Russian Federation', value: CountryCode.RU},
  {label: 'Rwanda', value: CountryCode.RW},
  {label: 'Saint Barthélemy', value: CountryCode.BL},
  {label: 'Saint Helena, Ascension and Tristan da Cunha', value: CountryCode.SH},
  {label: 'Saint Kitts and Nevis', value: CountryCode.KN},
  {label: 'Saint Lucia', value: CountryCode.LC},
  {label: 'Saint Martin (French part)', value: CountryCode.MF},
  {label: 'Saint Pierre and Miquelon', value: CountryCode.PM},
  {label: 'Saint Vincent and the Grenadines', value: CountryCode.VC},
  {label: 'Samoa', value: CountryCode.WS},
  {label: 'San Marino', value: CountryCode.SM},
  {label: 'Sao Tome and Principe', value: CountryCode.ST},
  {label: 'Saudi Arabia', value: CountryCode.SA},
  {label: 'Senegal', value: CountryCode.SN},
  {label: 'Serbia', value: CountryCode.RS},
  {label: 'Seychelles', value: CountryCode.SC},
  {label: 'Sierra Leone', value: CountryCode.SL},
  {label: 'Singapore', value: CountryCode.SG},
  {label: 'Sint Maarten (Dutch part)', value: CountryCode.SX},
  {label: 'Slovakia', value: CountryCode.SK},
  {label: 'Slovenia', value: CountryCode.SI},
  {label: 'Solomon Islands', value: CountryCode.SB},
  {label: 'Somalia', value: CountryCode.SO},
  {label: 'South Africa', value: CountryCode.ZA},
  {label: 'South Georgia and the South Sandwich Islands', value: CountryCode.GS},
  {label: 'South Sudan', value: CountryCode.SS},
  {label: 'Spain', value: CountryCode.ES},
  {label: 'Sri Lanka', value: CountryCode.LK},
  {label: 'Sudan', value: CountryCode.SD},
  {label: 'Suriname', value: CountryCode.SR},
  {label: 'Svalbard and Jan Mayen', value: CountryCode.SJ},
  {label: 'Swaziland', value: CountryCode.SZ},
  {label: 'Sweden', value: CountryCode.SE},
  {label: 'Switzerland', value: CountryCode.CH},
  {label: 'Taiwan', value: CountryCode.TW},
  {label: 'Tajikistan', value: CountryCode.TJ},
  {label: 'Tanzania, United Republic of', value: CountryCode.TZ},
  {label: 'Thailand', value: CountryCode.TH},
  {label: 'Timor-Leste', value: CountryCode.TL},
  {label: 'Togo', value: CountryCode.TG},
  {label: 'Tokelau', value: CountryCode.TK},
  {label: 'Tonga', value: CountryCode.TO},
  {label: 'Trinidad and Tobago', value: CountryCode.TT},
  {label: 'Tunisia', value: CountryCode.TN},
  {label: 'Turkey', value: CountryCode.TR},
  {label: 'Turkmenistan', value: CountryCode.TM},
  {label: 'Turks and Caicos Islands', value: CountryCode.TC},
  {label: 'Tuvalu', value: CountryCode.TV},
  {label: 'Uganda', value: CountryCode.UG},
  {label: 'Ukraine', value: CountryCode.UA},
  {label: 'United Arab Emirates', value: CountryCode.AE},
  {label: 'United Kingdom', value: CountryCode.GB},
  {label: 'United States Minor Outlying Islands', value: CountryCode.UM},
  {label: 'United States', value: CountryCode.US},
  {label: 'Uruguay', value: CountryCode.UY},
  {label: 'Uzbekistan', value: CountryCode.UZ},
  {label: 'Vanuatu', value: CountryCode.VU},
  {label: 'Venezuela, Bolivarian Republic of', value: CountryCode.VE},
  {label: 'Vietnam', value: CountryCode.VN},
  {label: 'Virgin Islands, British', value: CountryCode.VG},
  {label: 'Virgin Islands, U.S.', value: CountryCode.VI},
  {label: 'Wallis and Futuna', value: CountryCode.WF},
  {label: 'Western Sahara', value: CountryCode.EH},
  {label: 'Yemen', value: CountryCode.YE},
  {label: 'Zambia', value: CountryCode.ZM},
  {label: 'Zimbabwe', value: CountryCode.ZW},
]

export const RESTRICTED_COUNTRIES = new Set([
  CountryCode.AE,
  CountryCode.AF,
  CountryCode.AO,
  CountryCode.AZ,
  CountryCode.BD,
  CountryCode.BH,
  CountryCode.BI,
  CountryCode.BN,
  CountryCode.BY,
  CountryCode.CD,
  CountryCode.CF,
  CountryCode.CG,
  CountryCode.CM,
  CountryCode.CN,
  CountryCode.CU,
  CountryCode.DJ,
  CountryCode.DZ,
  CountryCode.EG,
  CountryCode.EH,
  CountryCode.ER,
  CountryCode.ET,
  CountryCode.GA,
  CountryCode.GQ,
  CountryCode.IQ,
  CountryCode.IR,
  CountryCode.KH,
  CountryCode.KZ,
  CountryCode.LA,
  CountryCode.LY,
  CountryCode.MM,
  CountryCode.NI,
  CountryCode.OM,
  CountryCode.QA,
  CountryCode.RU,
  CountryCode.RW,
  CountryCode.SA,
  CountryCode.SD,
  CountryCode.SO,
  CountryCode.SS,
  CountryCode.SZ,
  CountryCode.TD,
  CountryCode.TJ,
  CountryCode.TM,
  CountryCode.TR,
  CountryCode.UG,
  CountryCode.UZ,
  CountryCode.VE,
  CountryCode.VN,
  CountryCode.YE,
])
