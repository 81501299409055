import type {APIPlayer} from '@app/common/api'
import {
  PlayerFlags,
  RelationshipType,
  VoteStatusToString,
  useAdminPlayerSessions,
  useCurrentUser,
  usePlayer,
  usePlayerIPs,
  useRelationships,
} from '@app/common/api'
import {Period, Rank, dateFormatter, numberFormatter} from '@app/common/constants'
import {useOverlayStore, useSettingsStore} from '@app/common/stores'
import {
  canViewChatLogs,
  canViewLinkedAccounts,
  censorIpAddress,
  formatMinutes,
  getAvatarUrl,
  getCreditsToNextTier,
  getFormattedLevel,
  getKdr,
  getSkinUrl,
  getXpToNextLevelColor,
  hasFlag,
  isAdmin,
} from '@app/common/utils'
import {AccountUpdateModal} from '@app/components/Account/AccountUpdateModal'
import appStyles from '@app/components/App.module.css'
import {Avatar} from '@app/components/Avatar/Avatar'
import {FriendRemoveModal} from '@app/components/Friend/FriendRemoveModal'
import {NGStaffIcon} from '@app/components/Logo/NGStaffIcon'
import {Dropdown} from '@app/components/Member/MemberList'
import playerStyles from '@app/components/Player/Player.module.css'
import {PlayerFriendsModal} from '@app/components/Player/PlayerFriendsModal'
import {PlayerLeaderboardModal} from '@app/components/Player/PlayerLeaderboardModal'
import {PlayerStats} from '@app/components/Player/PlayerStats'
import {Tooltip, TooltipPosition} from '@app/components/Tooltip/Tooltip'
import ErrorScreen from '@app/components/UI/ErrorScreen'
import {MetaTags} from '@app/components/UI/MetaTags'
import {api} from '@app/hooks/useApi'
import {ExternalLinkIcon, InfoIcon} from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  Link,
  List,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import {EllipsisVerticalIcon} from '@heroicons/react/24/solid'
import {getLevelFromXp, getXpToReachLevel} from '@nethergames/utils'
import * as Sentry from '@sentry/react'
import {useQueryClient} from '@tanstack/react-query'
import axios from 'axios'
import clsx from 'clsx'
import {format, formatDistance} from 'date-fns'
import emoji from 'emoji-dictionary'
import React from 'react'
import toast from 'react-hot-toast'
import {FaRobot} from 'react-icons/fa'
import ReactMarkdown from 'react-markdown'
import {Link as RouterLink, useParams} from 'react-router-dom'
import remarkGfm from 'remark-gfm'
import * as skinview3d from 'skinview3d'
import {useCopyToClipboard} from 'usehooks-ts'
import {shallow} from 'zustand/shallow'

const SKIN_WIDTH = 150
const SKIN_HEIGHT = SKIN_WIDTH + 100

function formatRate(numerator: number, denominator: number): number | string {
  return denominator > 0 ? `${((numerator / denominator) * 100).toFixed(2)}%` : 0
}

export default function Player(): React.JSX.Element | null {
  const [period, setPeriod] = React.useState(Period.GLOBAL)
  const params = useParams()
  const {data: player, status, error} = usePlayer(params.player!, {period})
  const {data: user} = useCurrentUser()
  const setSplashScreenOpen = useOverlayStore(state => state.setSplashScreenOpen)
  React.useEffect(() => {
    setSplashScreenOpen(status === 'pending')
  }, [setSplashScreenOpen, status])

  if (status === 'error') return <ErrorScreen payload={error} />
  if (!player) return null
  const shouldDisableStats = user?.xuid === player.xuid ? false : hasFlag(player.flags, PlayerFlags.DISABLE_STATS)

  return (
    <>
      <MetaTags
        description={`Kills: ${player.kills} | Deaths: ${player.deaths} | Wins: ${player.wins} | Level: ${player.level} | Credits: ${player.credits}`}
        image={getAvatarUrl(player.skinHash)}
        title={player.name}
      />

      <Box className={playerStyles.wrapper}>
        <Stack
          align="center"
          borderWidth={1}
          className={clsx(appStyles.backdrop, playerStyles.main)}
          direction="column"
          gap={1}
          overflow="visible"
          p={4}
          position="relative"
          rounded="2xl"
        >
          <PlayerOptions player={player} />
          <PlayerHeader player={player} />
          <PlayerBadges player={player} />
          <PlayerStatus player={player} />

          <Stack align="center" gap={2} h="full" w="full">
            <PlayerIPs player={player} />
            <PlayerBio player={player} />
            <PlayerHighlights player={player} shouldDisableStats={shouldDisableStats} />
            <PlayerUsernameHistory player={player} />
            <PlayerHighlightsExtra player={player} shouldDisableStats={shouldDisableStats} />
            <PlayerSkin player={player} />
            <PlayerLeaderboardSection period={period} player={player} shouldDisableStats={shouldDisableStats} />
          </Stack>
        </Stack>

        <PlayerStats period={period} player={player} setPeriod={setPeriod} shouldDisableStats={shouldDisableStats} />
      </Box>
    </>
  )
}

export function PlayerOptions({player}: {player: APIPlayer}): React.JSX.Element {
  const {data: user} = useCurrentUser()
  const {data: relationships} = useRelationships()
  const setAdminPlayer = useOverlayStore(state => state.setAdminPlayer)
  const setAdminPlayerGameIp = useOverlayStore(state => state.setAdminPlayerGameIp)
  const setAdminPlayerWebIp = useOverlayStore(state => state.setAdminPlayerWebIp)
  const setPunishmentPlayer = useOverlayStore(state => state.setPunishmentPlayer)
  const setAdminAltsPlayer = useOverlayStore(state => state.setAdminAltsPlayer)
  const setChatLogsOpen = useOverlayStore(state => state.setChatLogsOpen)

  const isCurrentUser = user?.xuid === player.xuid
  const queryClient = useQueryClient()
  const friendRemoveModal = useDisclosure()
  const friendsModal = useDisclosure()
  const friendsRef = React.useRef()
  const updateModal = useDisclosure()
  const updateRef = React.useRef()
  const canViewIPs = isAdmin(user) || hasFlag(user?.flags as number, PlayerFlags.CAN_VIEW_PORTAL_IPS)

  return (
    <>
      <FriendRemoveModal player={player.name} {...friendRemoveModal} />
      {user && <AccountUpdateModal buttonRef={updateRef} user={user} {...updateModal} />}
      {user && <PlayerFriendsModal buttonRef={friendsRef} player={player.name} {...friendsModal} />}
      <Dropdown
        items={[
          {label: 'Edit Profile', onSelect: updateModal.onOpen, hidden: !isCurrentUser},
          {label: 'Chat Logs (Mod+)', onSelect: () => setChatLogsOpen(player.xuid), hidden: !canViewChatLogs(user)},
          {label: 'Edit Player (Admin)', onSelect: () => setAdminPlayer(player.name), hidden: !isAdmin(user)},
          {label: 'Game IPs (Admin)', onSelect: () => setAdminPlayerGameIp(player.name), hidden: !canViewIPs},
          {label: 'Web IPs (Admin)', onSelect: () => setAdminPlayerWebIp(player.name), hidden: !isAdmin(user)},
          {
            label: 'Linked Accounts',
            onSelect: () => setAdminAltsPlayer(player.name),
            hidden: !canViewLinkedAccounts(user),
          },
          {label: 'Punishments', onSelect: () => setPunishmentPlayer(player.name)},
          {label: 'Mutual Friends', onSelect: friendsModal.onOpen, hidden: !user || isCurrentUser},
          {
            label: 'Add Friend',
            onSelect: async () => {
              toast.loading('Sending friend request...', {id: 'friend_request'})
              try {
                await api.put(`users/@me/relationships/${player.name}`, {type: null})
                await queryClient.invalidateQueries({queryKey: ['user', 'relationships']})
                toast.success('Friend request sent!', {id: 'friend_request'})
              } catch (error) {
                if (axios.isAxiosError(error))
                  toast.error((error.response as any)?.data?.message ?? 'Something went wrong!', {
                    id: 'friend_request',
                  })
              }
            },
            hidden:
              !user ||
              isCurrentUser ||
              relationships?.find(relationship => relationship.player === player.name)?.type ===
                RelationshipType.FRIEND,
          },
          {
            label: 'Remove Friend',
            onSelect: friendRemoveModal.onOpen,
            hidden:
              !user ||
              isCurrentUser ||
              relationships?.find(relationship => relationship.player === player.name)?.type !==
                RelationshipType.FRIEND,
            danger: true,
          },
        ]}
      >
        <IconButton
          aria-label="User Actions"
          backgroundColor="transparent"
          colorScheme="gray"
          icon={<EllipsisVerticalIcon height={24} width={24} />}
          pos="absolute"
          right={4}
          size="sm"
          top={4}
        />
      </Dropdown>
    </>
  )
}

export function PlayerHeader({
  player,
  avatarTopGutter = -42,
}: {
  player: APIPlayer
  avatarTopGutter?: number
}): React.JSX.Element {
  const [, copy] = useCopyToClipboard()
  return (
    <>
      <div style={{marginTop: avatarTopGutter}}>
        <Avatar name={player.name} online={player.online} skinHash={player.skinHash} />
      </div>

      <Flex align="center" fontSize="3xl" fontWeight="bold" gap="8px">
        <Tooltip label="Click to copy XUID" position={TooltipPosition.LEFT}>
          <div
            aria-label="Click to copy XUID"
            onClick={async () => copy(player.xuid)}
            onKeyDown={async event => {
              if (event.key === 'Enter') await copy(player.xuid)
            }}
            role="button"
            style={{userSelect: 'text'}}
            tabIndex={0}
          >
            {player.name}
          </div>
        </Tooltip>
        {player.staff && (
          <Tooltip label="NetherGames Staff" padding={8}>
            <a href="https://ngmc.co/jobs" rel="noreferrer" target="_blank">
              <NGStaffIcon height={24} width={24} />
            </a>
          </Tooltip>
        )}
        {hasFlag(player.flags, PlayerFlags.AUTOMATED) && (
          <Tooltip label="This player is an automated account operated by a third-party developer." padding={8}>
            <span>
              <FaRobot height={24} width={24} />
            </span>
          </Tooltip>
        )}
      </Flex>
    </>
  )
}

export function PlayerBadges({player}: {player: APIPlayer}): React.JSX.Element | null {
  const setPunishmentPlayer = useOverlayStore(state => state.setPunishmentPlayer)
  if (player.ranks.length === 0 && !player.tier && !player.banned && !player.muted) return null

  return (
    <div className={playerStyles.badgeWrapper}>
      {player.ranks.map((value, index) => {
        const badge = (
          <Badge bg={player.rankColors[index]!} className={playerStyles.badge} key={value} px={2} rounded="lg">
            {value === Rank.DEVELOPER ? (
              'Developer'
            ) : value === Rank.TITAN ? (
              <a href="https://ngmc.co/product/cl504wtnx006101t0ln4h1hpa" rel="noreferrer" target="_blank">
                {value}
              </a>
            ) : (
              value
            )}
          </Badge>
        )
        return value === Rank.TITAN && player.titanUntil ? (
          <Tooltip
            label={`Subscriber until ${format(new Date(player.titanUntil), 'MMM dd, yyyy')}`}
            padding={16}
            key={value}
          >
            {badge}
          </Tooltip>
        ) : [Rank.ULTRA, Rank.EMERALD, Rank.LEGEND].includes(value) && player.lastRankTimestamp ? (
          <Tooltip
            label={`Rank since ${format(new Date(player.lastRankTimestamp), 'MMM dd, yyyy')}`}
            padding={16}
            key={value}
          >
            {badge}
          </Tooltip>
        ) : (
          badge
        )
      })}
      {player.tier && (
        <Badge bg={player.tierColor!} className={playerStyles.badge} px={2} rounded="lg">
          {player.tier}
        </Badge>
      )}
      {player.banned && (
        <Tooltip label="View player punishments" padding={8}>
          <Badge
            bg="red.500"
            className={playerStyles.badge}
            onClick={() => setPunishmentPlayer(player.name)}
            px={2}
            role="button"
            rounded="lg"
          >
            Banned
          </Badge>
        </Tooltip>
      )}
      {player.muted && (
        <Tooltip label="View player punishments" padding={8}>
          <Badge
            bg="yellow.300"
            className={playerStyles.badge}
            onClick={() => setPunishmentPlayer(player.name)}
            px={2}
            role="button"
            rounded="lg"
          >
            Muted
          </Badge>
        </Tooltip>
      )}
    </div>
  )
}

export function PlayerStatus({player}: {player: APIPlayer}): React.JSX.Element {
  if (player.online)
    return (
      <Text fontSize="md">
        Playing Now: <strong>{player.lastServerParsed.pretty}</strong>
      </Text>
    )
  const lastSeen = new Date((player.lastQuit > player.lastJoined ? player.lastQuit : player.lastJoined) * 1000)
  return (
    <Text fontSize="sm">
      <Box as="span" color="gray.400">
        Last seen at
      </Box>{' '}
      <Tooltip label={formatDistance(lastSeen, Date.now(), {addSuffix: true})}>
        <strong>{dateFormatter.format(lastSeen)}</strong>
      </Tooltip>{' '}
      <Box as="span" color="gray.400">
        on
      </Box>{' '}
      <strong>{player.lastServerParsed.pretty}</strong>
    </Text>
  )
}

export function PlayerBio({player}: {player: APIPlayer}): React.JSX.Element | null {
  const setExternalLinkUrl = useOverlayStore(state => state.setExternalLinkUrl)
  if (!player.bio) return null
  return (
    <>
      <Divider />
      <Stack direction="column" mb="0.5rem!important" px={1} w="full">
        <Heading fontSize="lg">About Me</Heading>
        <ReactMarkdown
          allowedElements={['a', 'del', 'em', 'li', 'ol', 'p', 'strong', 'u', 'ul']}
          className={playerStyles.bio!}
          components={{
            a: ({node, children}) => {
              if (node === undefined) return null

              const props = node.properties as {href: string}
              const url = new URL(props.href)
              return (
                <Link
                  color="orange.300"
                  href={url.toString()}
                  onClick={event => {
                    if (url.host !== window.location.host) {
                      event.stopPropagation()
                      event.preventDefault()
                      setExternalLinkUrl(url.toString())
                    }
                  }}
                  target="_blank"
                >
                  {children}
                </Link>
              )
            },
            ul: ({children}) => <UnorderedList>{children}</UnorderedList>,
            ol: ({children}) => <OrderedList>{children}</OrderedList>,
            li: ({children}) => <ListItem>{children}</ListItem>,
          }}
          remarkPlugins={[[remarkGfm, {singleTilde: false}]]}
        >
          {player.bio.replaceAll(/:\w+:/gi, (name: any) => emoji.getUnicode(name))}
        </ReactMarkdown>
      </Stack>
    </>
  )
}

function IPSection({value}: {value: string}): React.JSX.Element {
  const [copiedValue, onCopy] = useCopyToClipboard()
  const [isHovering, setIsHovering] = React.useState(false)

  // reset the copiedValue after 2 seconds
  React.useEffect(() => {
    if (!copiedValue) return
    const timeout = window.setTimeout(async () => onCopy(''), 2000)
    return () => window.clearTimeout(timeout)
  }, [copiedValue, onCopy])

  const truncatedValue = value.length > 15 ? `${value.slice(0, 12)}...` : value
  return (
    <Tooltip label={copiedValue ? 'Copied!' : 'Click to copy'} padding={8} position={TooltipPosition.TOP}>
      <Link
        color="orange.300"
        onClick={async () => onCopy(value)}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        role="button"
        tabIndex={0}
      >
        {isHovering ? truncatedValue : censorIpAddress(truncatedValue)}
      </Link>
    </Tooltip>
  )
}

function IPShowMore({onClick, count}: {onClick(): void; count: number}): React.JSX.Element {
  return (
    <Button colorScheme="orange" onClick={onClick} size="xs" variant="outline">
      Show {count} other{count === 1 ? '' : 's'}
    </Button>
  )
}

export function PlayerIPs({player}: {player: APIPlayer}): React.JSX.Element | null {
  const {data: user} = useCurrentUser()
  const {data: gameIps} = usePlayerIPs(player.name, isAdmin(user))
  const {data: webIps} = useAdminPlayerSessions(player.name, isAdmin(user))
  const firstGameIp = gameIps?.[0]?.ip
  const firstWebIp = webIps?.[0]?.clientIp
  const setAdminPlayerGameIp = useOverlayStore(state => state.setAdminPlayerGameIp)
  const setAdminPlayerWebIp = useOverlayStore(state => state.setAdminPlayerWebIp)

  if (!firstGameIp && !firstWebIp) return null
  return (
    <>
      <Divider mt="auto!important" />

      <Stack direction="column" mb="0.5rem!important" px={1} w="full">
        <Heading fontSize="lg">IPs</Heading>
        <List as={Stack} fontSize="md" spacing="4px">
          {firstWebIp && (
            <ListItem align="center" as={Flex} gap="4px" justify="space-between">
              <Box overflow="hidden">
                <strong>Web</strong>: <IPSection value={firstWebIp} />
              </Box>
              <IPShowMore count={webIps!.length - 1} onClick={() => setAdminPlayerWebIp(player.name)} />
            </ListItem>
          )}

          {firstGameIp && (
            <ListItem align="center" as={Flex} gap="4px" justify="space-between">
              <Box overflow="hidden">
                <strong>Game</strong>: <IPSection value={firstGameIp} />
              </Box>
              <IPShowMore count={gameIps!.length - 1} onClick={() => setAdminPlayerGameIp(player.name)} />
            </ListItem>
          )}
        </List>
      </Stack>
    </>
  )
}

export function PlayerHighlights({
  player,
  shouldDisableStats,
}: {
  player: APIPlayer
  shouldDisableStats: boolean
}): React.JSX.Element | null {
  const highlights = [
    {
      label: 'Level',
      value: (
        <>
          {numberFormatter.format(player.level)} ({getFormattedLevel(player.level)})
        </>
      ),
    },
    {label: 'XP', value: player.xp},
    {label: 'Credits', value: player.credits},
    {label: 'Crate Keys', value: player.crateKeys},
    {label: 'Kills', value: player.kills},
    {label: 'Deaths', value: player.deaths},
    {label: 'K/D', value: player.kdr},
    {label: 'All Kills', value: player.killsTotal},
    {label: 'All Deaths', value: player.deathsTotal},
    {label: 'AK/AD', value: player.kdrTotal},
    {label: 'Wins', value: player.wins},
    {label: 'Losses', value: player.losses, wins: true},
    {label: 'W/L', value: player.wlr, wins: true},
    {label: 'W/D', value: getKdr(player.wins, player.deaths), wins: true},
    {label: 'Playtime', value: player.extra ? formatMinutes(player.extra.onlineTime) : 0, playtime: true},
    {label: 'Vote Status', value: VoteStatusToString[player.voteStatus]},
    {label: 'Report Count', value: player.reportCount},
    ...(hasFlag(player.flags, PlayerFlags.DISABLE_TOURNAMENT) ? [{label: 'Tournament Banned', value: 'Yes'}] : []),
  ].filter(value => value.value)

  if (highlights.length === 0) return null
  return (
    <>
      <Divider mt="auto!important" />

      <Stack direction="column" mb="0.5rem!important" px={1} w="full">
        <Heading fontSize="lg">Overview</Heading>
        <List fontSize="md">
          <ListItem>
            <strong>Joined</strong>:{' '}
            <Tooltip label={formatDistance(player.firstJoined * 1000, Date.now(), {addSuffix: true})}>
              <Box as="span" color="gray.400">
                {dateFormatter.format(player.firstJoined * 1000)}
              </Box>
            </Tooltip>
          </ListItem>

          {player.guild && (
            <ListItem>
              <strong>Guild</strong>:{' '}
              <Link as={RouterLink} color="orange.300" to={`/guild/${player.guild}`}>
                {player.guild}
              </Link>
            </ListItem>
          )}

          {player.factionData?.faction && (
            <ListItem>
              <strong>Faction</strong>:{' '}
              <Link as={RouterLink} color="orange.300" to={`/faction/${player.factionData.faction.name}`}>
                {player.factionData.faction.name}
              </Link>
            </ListItem>
          )}

          {player.youtubeChannelUrl && (
            <ListItem>
              <strong>YouTube</strong>:{' '}
              <Link color="orange.300" href={player.youtubeChannelUrl} isExternal>
                Open YouTube channel
                <ExternalLinkIcon color="orange.300" mb={1} ml={1} />
              </Link>
            </ListItem>
          )}

          {player.discordId && player.discordTag && (
            <ListItem>
              <strong>Discord</strong>:{' '}
              <Tooltip label="Open Discord profile (desktop app only)" padding={8} position={TooltipPosition.RIGHT}>
                <Link
                  color="orange.300"
                  href={`discord:/users/${player.discordId}`}
                  overflow="hidden"
                  userSelect="text"
                >
                  {player.discordTag}
                  <ExternalLinkIcon color="orange.300" mb={1} ml={1} />
                </Link>
              </Tooltip>
            </ListItem>
          )}

          {shouldDisableStats
            ? null
            : highlights.map(item => (
                <ListItem alignItems="center" display="flex" gap="4px" key={item.label}>
                  <div>
                    <strong>{item.label}</strong>:{' '}
                    <Box as="span" color="gray.400">
                      {typeof item.value === 'number' ? numberFormatter.format(item.value) : item.value}
                    </Box>
                  </div>
                  {item.wins && (
                    <Tooltip
                      label={`${item.label} only includes Duels, Skywars, and The Bridge.`}
                      padding={8}
                      position={TooltipPosition.RIGHT}
                    >
                      <InfoIcon boxSize="14px" />
                    </Tooltip>
                  )}
                  {item.playtime && (
                    <Tooltip
                      label="The online time counter was released on August 21, 2022."
                      padding={8}
                      position={TooltipPosition.RIGHT}
                    >
                      <InfoIcon boxSize="14px" />
                    </Tooltip>
                  )}
                </ListItem>
              ))}
        </List>
      </Stack>
    </>
  )
}

export function PlayerUsernameHistory({player}: {player: APIPlayer}): React.JSX.Element | null {
  if (player.usernameHistory.length === 0) return null
  return (
    <>
      <Divider mt="auto!important" />
      <Stack direction="column" mb="0.5rem!important" px={1} w="full">
        <Heading fontSize="lg">Previously Known As</Heading>
        <List fontSize="md">
          {player.usernameHistory.map(username => (
            <ListItem key={username} userSelect="text">
              {username}
            </ListItem>
          ))}
        </List>
      </Stack>
    </>
  )
}

export function PlayerHighlightsExtra({
  player,
  shouldDisableStats,
}: {
  player: APIPlayer
  shouldDisableStats: boolean
}): React.JSX.Element | null {
  const [xpRemaining, nextLevelColor] = getXpToNextLevelColor(player.xp)
  const [levelCalculatorValue, setLevelCalculatorValue] = React.useState(player.level + 1)
  const highlightsExtra = [
    {label: 'Kills → Next K/D', value: player.killsUntilNextKdr},
    {label: 'Wins → Next W/L', value: player.winsUntilNextWlr},
    {label: 'XP → Next Level', value: getXpToReachLevel(player.level + 1) - player.xp},
    {
      label: 'XP → Next Level Color',
      value: xpRemaining ? (
        <>
          {numberFormatter.format(xpRemaining)} ({getFormattedLevel(nextLevelColor)})
        </>
      ) : (
        0
      ),
    },
    {label: 'Credits → Next Tier', value: getCreditsToNextTier(player.credits)},
    {label: 'Kill Rate', value: formatRate(player.kills, player.kills + player.deaths)},
    {label: 'Death Rate', value: formatRate(player.deaths, player.kills + player.deaths)},
    {label: 'Win Rate', value: formatRate(player.wins, player.wins + player.losses)},
    {label: 'Loss Rate', value: formatRate(player.losses, player.wins + player.losses)},
  ].filter(value => value.value)

  if (shouldDisableStats || highlightsExtra.length === 0) return null
  return (
    <>
      <Divider mt="auto!important" />
      <Stack direction="column" mb="0.5rem!important" px={1} w="full">
        <Heading fontSize="lg">Progress</Heading>
        <List fontSize="md">
          {highlightsExtra.map(item => (
            <ListItem alignItems="center" display="flex" gap="4px" key={item.label}>
              <div>
                <strong>{item.label}</strong>:{' '}
                <Box as="span" color="gray.400">
                  {typeof item.value === 'string' || React.isValidElement(item.value)
                    ? item.value
                    : numberFormatter.format(item.value as number)}
                </Box>
              </div>
            </ListItem>
          ))}
        </List>
      </Stack>

      <Divider mt="auto!important" />
      <Stack direction="column" mb="0.5rem!important" px={1} w="full">
        <Heading fontSize="lg">Level Calculator</Heading>
        {/* Render text with a number input in between and a resulting value, like so: "To reach level <input here> you need {x} XP" */}
        <Text fontSize="md">
          To reach level{' '}
          <Input
            defaultValue={player.level + 1}
            onChange={event => {
              const value = Number.parseInt(event.target.value, 10)
              if (value < getLevelFromXp(player.xp) || value > 21_863) return
              setLevelCalculatorValue(value)
            }}
            rounded="md"
            size="sm"
            type="number"
            w="64px"
          />{' '}
          you need {numberFormatter.format(getXpToReachLevel(levelCalculatorValue) - player.xp)} XP
        </Text>
      </Stack>
    </>
  )
}

export function PlayerSkin({player}: {player: APIPlayer}): React.JSX.Element | null {
  const settings = useSettingsStore(
    state => ({
      developerMode: state.developerMode,
      renderSkins: state.renderSkins,
      skinProxyUrl: state.skinProxyUrl,
      animatedSkin: !state.reducedMotion,
    }),
    shallow,
  )
  const setSettingsOpen = useOverlayStore(state => state.setSettingsOpen)
  const [failedRender, setFailedRender] = React.useState(false)

  React.useEffect(() => {
    if (!player?.skinVisibility || !settings.renderSkins || failedRender) return
    const canvas = document.querySelector<HTMLCanvasElement>(`#skin-${player.xuid}`)
    if (!canvas) return
    try {
      const skinViewer = new skinview3d.SkinViewer({
        canvas,
        width: SKIN_WIDTH,
        height: SKIN_HEIGHT,
        skin: getSkinUrl(player.skinHash),
      })
      skinViewer.controls.enablePan = false
      skinViewer.controls.enableZoom = false
      skinViewer.controls.enableRotate = true
      if (settings.animatedSkin) {
        skinViewer.autoRotate = true
        skinViewer.animation = new skinview3d.WalkingAnimation()
        skinViewer.animation.speed = 0.3
      }
    } catch (error) {
      setFailedRender(true)
      Sentry.captureException(error)
    }
  }, [player, failedRender, settings.renderSkins, settings.animatedSkin])

  if (!settings.renderSkins || !player.skinVisibility) return null
  return (
    <>
      <Divider mt="auto!important" />
      <Stack align="center" direction="row" justify="space-between" w="full">
        <Heading flex="1" fontSize="lg">
          Player Skin
        </Heading>
        <Button
          as="a"
          href={getSkinUrl(player.skinHash)}
          rightIcon={<ExternalLinkIcon />}
          size="xs"
          target="_blank"
          variant="outline"
        >
          Open Original
        </Button>
      </Stack>

      {settings.developerMode && settings.skinProxyUrl ? (
        <img alt="" height={SKIN_HEIGHT} src={`${settings.skinProxyUrl}${player.name}`} width={SKIN_WIDTH} />
      ) : failedRender ? (
        <Text className={playerStyles.bio} fontSize="md">
          Unable to render skin, please make sure WebGL is enabled in your browser or disable any extensions. You can
          also{' '}
          <Link color="orange.300" onClick={() => setSettingsOpen(true)}>
            disable this feature
          </Link>{' '}
          in the app settings.
        </Text>
      ) : (
        <Box as="canvas" h={SKIN_HEIGHT} id={`skin-${player.xuid}`} w={SKIN_WIDTH} />
      )}
    </>
  )
}

export function PlayerLeaderboardSection({
  player,
  period,
  shouldDisableStats,
  bottomGutter = false,
}: {
  player: APIPlayer
  period: Period
  shouldDisableStats: boolean
  bottomGutter?: boolean
}): React.JSX.Element | null {
  const renderSkins = useSettingsStore(state => state.renderSkins)
  const leaderboardModal = useDisclosure()
  const leaderboardRef = React.useRef()
  if (shouldDisableStats) return null

  return (
    <>
      <PlayerLeaderboardModal buttonRef={leaderboardRef} player={player} {...leaderboardModal} />
      <Divider mt={renderSkins && player.skinVisibility ? 0 : 'auto!important'} />
      <Tooltip
        label={`${player.name} doesn't qualify for any ${period} leaderboards!`}
        padding={8}
        shouldShow={!player.leaderboard}
      >
        <Box w="full">
          <Button
            fontSize="md"
            isDisabled={!player.leaderboard}
            mb={bottomGutter ? '16px' : '0px'}
            onClick={() => leaderboardModal.onOpen()}
            size="sm"
            w="full"
          >
            Leaderboard Positions
          </Button>
        </Box>
      </Tooltip>
    </>
  )
}
