import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {api} from '@app/hooks/useApi'
import {Button, Input, Stack, Text} from '@chakra-ui/react'
import {startRegistration} from '@simplewebauthn/browser'
import type {PublicKeyCredentialCreationOptionsJSON} from '@simplewebauthn/types'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import toast from 'react-hot-toast'

type ModalProps = {
  isOpen: boolean
  onClose(): void
  credentialOptions: PublicKeyCredentialCreationOptionsJSON | null
}

enum WebAuthnRegisterState {
  Waiting = 0,
  Registering = 1,
}

const StateToTitle: Record<WebAuthnRegisterState, string> = {
  [WebAuthnRegisterState.Waiting]: 'Register Security Key',
  [WebAuthnRegisterState.Registering]: 'Interact with your Security Key',
}

const StateToDescription: Record<WebAuthnRegisterState, string> = {
  [WebAuthnRegisterState.Waiting]: 'Please enter a name for your security key.',
  [WebAuthnRegisterState.Registering]: 'You may need to tap your device or confirm a prompt.',
}

export function WebAuthnRegister(props: ModalProps): React.JSX.Element {
  const [state, setState] = React.useState<WebAuthnRegisterState>(WebAuthnRegisterState.Waiting)
  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            isLoading={state === WebAuthnRegisterState.Registering}
            onClick={() => setState(WebAuthnRegisterState.Registering)}
          >
            Let's Go
          </Button>
        </ModalFooter>
      }
      header={StateToTitle[state]}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      {props.credentialOptions && (
        <WebAuthnRegisterContent
          credentialOptions={props.credentialOptions}
          onClose={props.onClose}
          setState={setState}
          state={state}
        />
      )}
    </Modal>
  )
}

type WebAuthnRegisterContentProps = {
  state: WebAuthnRegisterState
  credentialOptions: PublicKeyCredentialCreationOptionsJSON
  onClose(): void
  setState(state: WebAuthnRegisterState): void
}

function WebAuthnRegisterContent(props: WebAuthnRegisterContentProps): React.JSX.Element | null {
  const [name, setName] = React.useState('My Security Key')
  const queryClient = useQueryClient()

  // biome-ignore lint/correctness/useExhaustiveDependencies: this is fine
  React.useEffect(() => {
    if (props.state !== WebAuthnRegisterState.Registering) return
    ;(async () => {
      try {
        const response = await startRegistration(props.credentialOptions)
        await api.post(`/users/@me/mfa/webauthn/credentials/${encodeURIComponent(name)}`, response)
        await queryClient.invalidateQueries({queryKey: ['user', 'webauthn']})
      } catch {
        toast.error('Something went wrong! Please try again later.')
      } finally {
        props.onClose()
        props.setState(WebAuthnRegisterState.Waiting)
      }
    })()
  }, [name, props.credentialOptions, props.state])

  return (
    <Stack as={ModalBody} direction="column" fontSize="md" spacing={3}>
      <Text color="whiteAlpha.800" fontSize="md" letterSpacing="tight">
        {StateToDescription[props.state]}
        {props.state === WebAuthnRegisterState.Waiting && (
          <Input
            maxLength={255}
            mt={2}
            onChange={event => setName(event.target.value)}
            size="md"
            type="text"
            value={name}
          />
        )}
      </Text>
    </Stack>
  )
}
