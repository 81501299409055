import {useRequestsStore} from '@app/common/requests'
import {m as motion} from 'framer-motion'
import type React from 'react'

export function SequenceAnimation({children}: {children: React.ReactNode}): React.JSX.Element {
  const direction = useRequestsStore(state => state.direction)
  return (
    <motion.div
      animate="center"
      custom={direction}
      exit="exit"
      initial="enter"
      transition={{
        x: {type: 'spring', stiffness: 300, damping: 30},
        opacity: {duration: 0.2},
      }}
      variants={{
        enter: (direction: number) => ({
          x: direction > 0 ? 1000 : -1000,
          opacity: 0,
        }),
        center: {zIndex: 1, x: 0, opacity: 1},
        exit: (direction: number) => ({
          zIndex: 0,
          x: direction < 0 ? 1000 : -1000,
          opacity: 0,
        }),
      }}
    >
      {children}
    </motion.div>
  )
}
