import {NOOP} from '@app/common/constants'
import * as styles from '@app/components/Tooltip/Tooltip.css'
import {
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import {AnimatePresence, m as motion} from 'framer-motion'
import React from 'react'
import Balancer from 'react-wrap-balancer'

export enum TooltipPosition {
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
}

const TooltipPositionToStaticSide: Record<TooltipPosition, string> = {
  [TooltipPosition.TOP]: 'bottom',
  [TooltipPosition.BOTTOM]: 'top',
  [TooltipPosition.LEFT]: 'right',
  [TooltipPosition.RIGHT]: 'left',
}

type TooltipProps = {
  label: React.ReactNode
  position?: TooltipPosition
  shouldShow?: boolean
  forceOpen?: boolean
  padding?: number
  children: React.JSX.Element
}

export function Tooltip({
  children,
  label,
  position = TooltipPosition.TOP,
  shouldShow = true,
  forceOpen = false,
  padding = 6,
}: TooltipProps): React.JSX.Element {
  const [open, setOpen] = React.useState(forceOpen)
  const arrowRef = React.useRef<HTMLDivElement>(null)
  const {x, y, reference, floating, strategy, context, refs, update, middlewareData} = useFloating({
    placement: position,
    open,
    onOpenChange: forceOpen ? NOOP : setOpen,
    middleware: [offset(padding), flip(), shift({padding: 8}), arrow({element: arrowRef})],
  })

  const {getReferenceProps, getFloatingProps} = useInteractions([
    useHover(context, {enabled: shouldShow}),
    useFocus(context, {enabled: shouldShow}),
    useRole(context, {role: 'tooltip'}),
    useDismiss(context),
  ])

  React.useEffect(() => {
    if (refs.reference.current && refs.floating.current && open)
      autoUpdate(refs.reference.current, refs.floating.current, update)
  }, [refs.reference, refs.floating, update, open])

  return (
    <>
      {React.cloneElement(children, getReferenceProps({ref: reference, ...children.props}))}
      <FloatingPortal>
        <AnimatePresence>
          {open && (
            <motion.div
              animate={{opacity: 1, scale: 1}}
              exit={{opacity: 0}}
              initial={{opacity: 0, scale: 0.85}}
              transition={{type: 'spring', damping: 20, stiffness: 300}}
              {...getFloatingProps({
                ref: floating,
                className: styles.tooltip,
                style: {position: strategy, top: y ?? '', left: x ?? ''},
              })}
            >
              <div
                className={styles.arrow}
                ref={arrowRef}
                style={{
                  left: middlewareData?.arrow?.x,
                  top: middlewareData?.arrow?.y,
                  [TooltipPositionToStaticSide[position]]: -4,
                }}
              />
              <div className={styles.content}>
                <Balancer>{label}</Balancer>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  )
}
