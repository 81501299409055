import {useAPIFormSubmissionAdmin, useAPIFormSubmissionSimilarsAdmin} from '@app/common/api'
import {
  APPLICATION_FORM_TYPES,
  FormSubmissionFlags,
  FormSubmissionStatusToString,
  FormType,
  FormTypeToString,
  dateFormatter,
} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {hasFlag} from '@app/common/utils'
import {FormFieldPreview} from '@app/components/Modal/FormInputs'
import {InventoryBackup} from '@app/components/Modal/InventoryBackup'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {PlayerInfo} from '@app/components/Modal/PlayerInfo'
import RequestUpdateModal from '@app/components/Modal/RequestUpdateModal'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {Submission} from '@app/components/Modal/Submission'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {useReplaceState} from '@app/hooks/useReplaceState'
import {EditIcon} from '@chakra-ui/icons'
import {Box, Button, Checkbox, Heading, IconButton, Link, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {QuestionMarkCircleIcon, ReceiptRefundIcon, WrenchScrewdriverIcon} from '@heroicons/react/24/solid'
import {useQueryClient} from '@tanstack/react-query'
import axios from 'axios'
import React from 'react'
import {toast} from 'react-hot-toast'
import {FaGavel, FaHistory, FaRobot, FaUserSecret} from 'react-icons/fa'
import {shallow} from 'zustand/shallow'

export default function RequestReviewModal(): React.JSX.Element {
  const [requestId, setRequestId] = useOverlayStore(state => [state.requestId, state.setRequestId], shallow)
  return (
    <Modal header="Review Request" isOpen={Boolean(requestId)} onClose={() => setRequestId(null)}>
      <RequestReviewModalContent requestId={requestId!} />
    </Modal>
  )
}

const SIDE_EFFECT_FORM_TYPES = new Set([
  FormType.ACCOUNT_DELETION,
  FormType.FACTIONS_ROLLBACK,
  FormType.SERVER_BAN_APPEAL,
  FormType.SERVER_MUTE_APPEAL,
  FormType.SKYBLOCK_ROLLBACK,
])

const DisabledFormTypeToAction = {
  [FormType.ACCOUNT_DELETION]: 'schedule an account deletion',
  [FormType.FACTIONS_ROLLBACK]: 'issue a rollback',
  [FormType.SERVER_BAN_APPEAL]: 'revoke the ban',
  [FormType.SERVER_MUTE_APPEAL]: 'revoke the mute',
  [FormType.SKYBLOCK_ROLLBACK]: 'issue a rollback',
}

function getGptScoreBg(score: number): string {
  if (score >= 65) return 'red.500'
  if (score >= 50) return 'orange.400'
  if (score >= 30) return 'yellow.300'
  return 'green.500'
}

function getGptScoreColor(score: number): string {
  if (score >= 30 && score < 50) return 'black'
  return 'white'
}

function getGptScoreLabel(score: number): string {
  if (score >= 65) return 'Very Suspicious'
  if (score >= 50) return 'Highly Suspicious'
  if (score >= 30) return 'Suspicious'
  return 'Not Suspicious'
}

function getGptScoreModifier(score: number): string {
  if (score >= 65) return 'is very likely to be partially or fully generated by ChatGPT'
  if (score >= 50) return 'is highly likely to be partially generated by ChatGPT'
  if (score >= 30) return 'may be partially generated by ChatGPT'
  return 'is not likely to be generated by ChatGPT'
}

function getGptScoreDescription(score: number): React.ReactNode {
  return (
    <>
      Based on our analysis, this application {getGptScoreModifier(score)}. You may use{' '}
      <Link fontWeight="semibold" href="https://copyleaks.com/ai-content-detector" isExternal textDecor="underline">
        AI Content Detector
      </Link>{' '}
      on passages you find overly formal or unnatural.
    </>
  )
}

function RequestReviewModalContent({requestId}: {requestId: string}): React.JSX.Element {
  const [showAllFormTypes, setShowAllFormTypes] = React.useState(false)
  const [isCalculating, setIsCalculating] = React.useState(false)
  const [isRefunding, setIsRefunding] = React.useState(false)
  const {data: submission, status, error} = useAPIFormSubmissionAdmin(requestId!)
  const {data: similars} = useAPIFormSubmissionSimilarsAdmin(requestId!, showAllFormTypes)
  const queryClient = useQueryClient()
  const setAdminRequestsOpen = useOverlayStore(state => state.setAdminRequestsOpen)
  const setAdminRequestsStatus = useOverlayStore(state => state.setAdminRequestsStatus)
  const setPlayerXuid = useOverlayStore(state => state.setAdminRequestsPlayerXuid)
  const setPunishmentPlayer = useOverlayStore(state => state.setPunishmentPlayer)
  const setRequestId = useOverlayStore(state => state.setRequestId)
  const setReviewedBy = useOverlayStore(state => state.setAdminRequestsReviewedBy)
  const updateRequestModal = useDisclosure()
  const updateRequestRef = React.useRef(null)
  useReplaceState(`/admin/requests/${requestId}`)

  if (!submission) {
    if (status === 'error') {
      switch (error.response?.status) {
        case 401:
        case 403: {
          return (
            <ModalBody align="center" as={Stack} h="full" justify="center" my={16} textAlign="center">
              <FaUserSecret color="var(--chakra-colors-orange-200)" size={75} />
              <Heading fontSize="2xl" fontWeight="bold" pt="8px">
                The Maze isn't meant for you.
              </Heading>
              <Text color="whiteAlpha.800" fontSize="md">
                You don't have permission to view this request.
              </Text>
            </ModalBody>
          )
        }
        case 404: {
          return (
            <ModalBody align="center" as={Stack} h="full" justify="center" my={16} textAlign="center">
              <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
              <Heading fontSize="2xl" fontWeight="bold">
                *cricket noises*
              </Heading>
              <Text color="whiteAlpha.800" fontSize="md">
                This request doesn't exist. Did you type the URL correctly?
              </Text>
            </ModalBody>
          )
        }
        default: {
          return (
            <ModalBody align="center" as={Stack} h="full" justify="center" my={16} textAlign="center">
              <WrenchScrewdriverIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
              <Heading fontSize="2xl" fontWeight="bold">
                Well, this is awkward.
              </Heading>
              <Text color="whiteAlpha.800" fontSize="md">
                We've tracked the error and will get right on it.
              </Text>
            </ModalBody>
          )
        }
      }
    }

    return (
      <ModalBody align="center" as={Stack} h="full" justify="center" my={16}>
        <Spinner />
      </ModalBody>
    )
  }

  const isDisabledApproved = SIDE_EFFECT_FORM_TYPES.has(submission.formType)
  const appealPunishments =
    (submission.fields.find(field => field.name === 'appeal_punishments')?.value as string[] | undefined) ?? []
  const appealedPunishmentIds = submission.punishments
    .map(punishment => punishment.id)
    .filter(id => appealPunishments.includes(id))
  const paymentId = submission.fields.find(field => field.name === 'billing_issue_payment')?.value as string | undefined

  return (
    <>
      <ModalBody as={Stack} gap="8px" mb={1}>
        {isDisabledApproved && (
          <Text color="red.300" fontSize="sm" fontWeight="semibold" letterSpacing="tight">
            We automatically {DisabledFormTypeToAction[submission.formType as keyof typeof DisabledFormTypeToAction]}{' '}
            when approved. Changing the status, once approved, won't undo the action taken.
          </Text>
        )}

        <PlayerInfo isDark player={submission.player} />

        <Stack spacing="1">
          <Text fontSize="sm" letterSpacing="tight">
            Form Type: <strong>{FormTypeToString[submission.formType]}</strong>
          </Text>

          <Text fontSize="sm" letterSpacing="tight">
            Submitted on <strong>{dateFormatter.format(new Date(submission.timestamp))}</strong> by{' '}
            <Link color="orange.300" fontWeight="semibold" href={`/player/${submission.player}`} isExternal>
              {submission.player}
            </Link>{' '}
            <Tooltip label="Show all submissions by this player">
              <IconButton
                aria-label="All Submissions by Player"
                icon={<FaHistory size={16} />}
                onClick={() => {
                  setRequestId(null)
                  setAdminRequestsOpen(true)
                  setAdminRequestsStatus([])
                  setPlayerXuid([submission.playerXuid])
                }}
                size="xs"
                variant="ghost"
              />
            </Tooltip>
          </Text>

          {submission.reviewer && (
            <Text fontSize="sm" letterSpacing="tight">
              Reviewed on <strong>{dateFormatter.format(new Date(submission.reviewedAt!))}</strong> by{' '}
              <Link color="orange.300" fontWeight="semibold" href={`/player/${submission.reviewer}`} isExternal>
                {submission.reviewer}
              </Link>{' '}
              <Tooltip label="Show all reviews by this player">
                <IconButton
                  aria-label="All Reviews by Player"
                  icon={<FaHistory size={16} />}
                  onClick={() => {
                    setRequestId(null)
                    setAdminRequestsOpen(true)
                    setAdminRequestsStatus([])
                    setReviewedBy([submission.reviewedBy!])
                  }}
                  size="xs"
                  variant="ghost"
                />
              </Tooltip>
            </Text>
          )}

          <Text fontSize="sm" letterSpacing="tight">
            Status: <strong>{FormSubmissionStatusToString[submission.status]}</strong>
          </Text>

          {APPLICATION_FORM_TYPES.has(submission.formType) &&
            !hasFlag(submission.flags, FormSubmissionFlags.GPT_SCORE_PENDING) && (
              <Stack
                bg={getGptScoreBg(submission.gptScore)}
                color={getGptScoreColor(submission.gptScore)}
                direction="column"
                fontSize="sm"
                letterSpacing="tight"
                p={4}
                rounded="md"
              >
                <Text>
                  GPT Score: <strong>{submission.gptScore}% Generated</strong> ({getGptScoreLabel(submission.gptScore)})
                </Text>
                <Text>{getGptScoreDescription(submission.gptScore)}</Text>
              </Stack>
            )}
        </Stack>

        {APPLICATION_FORM_TYPES.has(submission.formType) &&
          hasFlag(submission.flags, FormSubmissionFlags.GPT_SCORE_PENDING) && (
            <Box>
              <Button
                colorScheme="orange"
                isLoading={isCalculating}
                leftIcon={<FaRobot />}
                onClick={async () => {
                  try {
                    setIsCalculating(true)
                    await api.post(`/admin/form-submissions/${submission.id}/calculate-gpt-score`)
                    await queryClient.invalidateQueries({queryKey: ['admin', 'form-submissions', submission.id]})
                  } catch (error) {
                    if (axios.isAxiosError(error)) toast.error((error.response as any).data.message)
                  } finally {
                    setIsCalculating(false)
                  }
                }}
                size="sm"
                w="full"
              >
                Calculate GPT Score
              </Button>
            </Box>
          )}

        <Box>
          <Button
            colorScheme="orange"
            leftIcon={<FaGavel />}
            onClick={() => setPunishmentPlayer(submission.player, appealedPunishmentIds)}
            size="sm"
            w="full"
          >
            View Punishments
          </Button>
        </Box>

        {paymentId && (
          <Box>
            <Button
              colorScheme="orange"
              isLoading={isRefunding}
              leftIcon={<ReceiptRefundIcon height={16} width={16} />}
              onClick={async () => {
                try {
                  setIsRefunding(true)
                  await api.post(`/admin/billing/payments/${paymentId}/refund`)
                  toast.success('Refunded payment successfully!')
                } catch {
                  toast.error('Failed to refund payment!')
                } finally {
                  setIsRefunding(false)
                }
              }}
              size="sm"
              w="full"
            >
              Refund Payment
            </Button>
          </Box>
        )}

        <Box>
          <Button
            colorScheme="orange"
            leftIcon={<EditIcon />}
            onClick={() => updateRequestModal.onOpen()}
            ref={updateRequestRef}
            size="sm"
            w="full"
          >
            Update Request
          </Button>
        </Box>

        {APPLICATION_FORM_TYPES.has(submission.formType) && (
          <Stack gap="4px">
            <SmallHeading>Similar Applications</SmallHeading>
            <Stack gap="4px">
              <Checkbox isChecked={showAllFormTypes} onChange={event => setShowAllFormTypes(event.target.checked)}>
                Show all form types
              </Checkbox>
            </Stack>
            {hasFlag(submission.flags, FormSubmissionFlags.SIMILARITY_SCORES_PENDING) ? (
              <Text fontSize="sm" letterSpacing="tight">
                Similarity scores are being calculated, please check back later.
              </Text>
            ) : similars && similars.submissions.length > 0 ? (
              similars.submissions.map(submission => (
                <Submission
                  key={submission.id}
                  onClick={() => setRequestId(submission.id)}
                  player={similars.players.find(player => player.xuid === submission.playerXuid)!}
                  submission={submission}
                />
              ))
            ) : (
              <Text fontSize="sm" letterSpacing="tight">
                No similar applications found.
              </Text>
            )}
          </Stack>
        )}

        {(submission.formType === FormType.FACTIONS_ROLLBACK || submission.formType === FormType.SKYBLOCK_ROLLBACK) && (
          <InventoryBackup
            admin
            formType={submission.formType}
            inventoryId={submission.fields.find(field => field.name === 'rollback_inventory_id')?.value as string}
          />
        )}

        {submission.reason && (
          <Stack gap="4px">
            <SmallHeading>Review Message</SmallHeading>
            <Box bg="#f97316" fontSize="sm" gap="4px" p={4} rounded="md">
              <Text fontSize="sm" letterSpacing="tight" overflow="hidden" userSelect="text" whiteSpace="break-spaces">
                {submission.reason.trim()}
              </Text>
            </Box>
          </Stack>
        )}

        <Stack gap="4px">
          {submission.fields.map(field => (
            <FormFieldPreview admin field={field} key={field.name} />
          ))}
        </Stack>
      </ModalBody>
      <RequestUpdateModal {...updateRequestModal} buttonRef={updateRequestRef} submission={submission} />
    </>
  )
}
