import type {AdminAuditLogChange, AdminAuditLogEntry} from '@app/common/api'
import {
  AdminAuditLogActionType,
  AdminAuditLogActionTypeToString,
  AdminAuditLogChangeStatus,
  PlayerFlagsDatabase,
  useAuditLogAdmin,
} from '@app/common/api'
import {AdminAPIPermissions, dateFormatter} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {hasFlag} from '@app/common/utils'
import {PlayerSelect} from '@app/components/Modal/AdminRequestsModal'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {useReplaceState} from '@app/hooks/useReplaceState'
import {Heading, Input, Stack, Text} from '@chakra-ui/react'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MinusIcon,
  PencilSquareIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid'
import type {GroupBase} from 'chakra-react-select'
import {Select} from 'chakra-react-select'
import {formatDistance} from 'date-fns'
import {matchSorter} from 'match-sorter'
import React from 'react'
import invariant from 'tiny-invariant'
import {shallow} from 'zustand/shallow'

export default function AdminAuditLogModal(): React.JSX.Element {
  const [adminAuditLogOpen, setAdminAuditLogOpen] = useOverlayStore(
    state => [state.adminAuditLogOpen, state.setAdminAuditLogOpen],
    shallow,
  )

  return (
    <Modal header="Audit Log" isOpen={adminAuditLogOpen} onClose={() => setAdminAuditLogOpen(false)}>
      <AdminAuditLogModalContent />
    </Modal>
  )
}

type ActionTypeOption = {
  label: string
  value: AdminAuditLogActionType
}

const ACTION_TYPE_OPTIONS = Object.entries(AdminAuditLogActionTypeToString).map(([key, value]) => ({
  label: value,
  value: Number(key) as AdminAuditLogActionType,
}))

function AdminAuditLogModalContent(): React.JSX.Element {
  const [filter, setFilter] = React.useState('')
  const [actionType, setActionType] = React.useState<AdminAuditLogActionType | null>(null)
  const [actorXuid, setActorXuid] = React.useState<string[]>([])
  const [targetXuid, setTargetXuid] = React.useState<string[]>([])
  const {data: auditLog} = useAuditLogAdmin({
    actionType,
    actor: actorXuid[0] ?? null,
    target: targetXuid[0] ?? null,
  })
  useReplaceState('/admin/audit-log')

  const filteredData = matchSorter(auditLog?.entries ?? [], filter, {
    keys: [
      'actorName',
      'changes.*.currentValue',
      'changes.*.previousValue',
      'changes.*.property',
      'flagNames',
      'targetName',
    ],
    sorter: rankedItems => rankedItems,
  })

  if (!auditLog)
    return (
      <ModalBody align="center" as={Stack} h="full" justify="center" my={16}>
        <Spinner />
      </ModalBody>
    )

  return (
    <ModalBody as={Stack} gap="8px" h="full" overflowY="auto">
      <Stack>
        <SmallHeading>Action Type</SmallHeading>
        <Select<ActionTypeOption, false, GroupBase<ActionTypeOption>>
          className="react-select"
          classNamePrefix="react-select"
          defaultValue={null}
          isClearable
          menuPortalTarget={document.body}
          onChange={option => setActionType(option ? option.value : null)}
          options={ACTION_TYPE_OPTIONS}
          selectedOptionStyle="check"
          size="sm"
          useBasicStyles
          value={ACTION_TYPE_OPTIONS.find(option => option.value === actionType) ?? null}
        />
      </Stack>

      <PlayerSelect label="Actor" onChange={setActorXuid} players={auditLog.actors} value={actorXuid} />
      <PlayerSelect label="Target" onChange={setTargetXuid} players={auditLog.players} value={targetXuid} />

      <Stack>
        <SmallHeading>Fuzzy Search</SmallHeading>
        <Input
          onChange={event => setFilter(event.target.value)}
          placeholder="e.g. +DISABLE_TOURNAMENT for added flags"
          rounded="md"
          size="sm"
        />
      </Stack>

      {filteredData.length === 0 && (
        <Stack align="center" h="full" justify="center" py={16} textAlign="center">
          <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
          <Heading fontSize="2xl" fontWeight="bold">
            *cricket noises*
          </Heading>
          <Text color="whiteAlpha.800" fontSize="md">
            There are no audit logs to show for the given filters.
          </Text>
        </Stack>
      )}

      <Stack spacing="16px">
        {filteredData.map(auditLogEntry => (
          <AuditLogEntry auditLogEntry={auditLogEntry} key={auditLogEntry.id} />
        ))}
      </Stack>
    </ModalBody>
  )
}

function AuditLogEntryDescription({auditLogEntry}: {auditLogEntry: AdminAuditLogEntry}): React.JSX.Element {
  switch (auditLogEntry.actionType) {
    case AdminAuditLogActionType.ENTITLEMENT_CREATE: {
      return (
        <Text>
          <strong>{auditLogEntry.actorName}</strong> created an entitlement for{' '}
          <strong>{auditLogEntry.targetName}</strong>
        </Text>
      )
    }

    case AdminAuditLogActionType.PLAYER_BIO_DELETE: {
      return (
        <Text>
          <strong>{auditLogEntry.actorName}</strong> cleared the bio of <strong>{auditLogEntry.targetName}</strong>
        </Text>
      )
    }

    case AdminAuditLogActionType.PLAYER_DELETE: {
      return (
        <Text>
          <strong>{auditLogEntry.actorName}</strong> deleted <strong>{auditLogEntry.targetName}</strong>
        </Text>
      )
    }

    case AdminAuditLogActionType.PLAYER_SKIN_DELETE: {
      return (
        <Text>
          <strong>{auditLogEntry.actorName}</strong> cleared the skin of <strong>{auditLogEntry.targetName}</strong>
        </Text>
      )
    }

    case AdminAuditLogActionType.PLAYER_UPDATE: {
      return (
        <Text>
          <strong>{auditLogEntry.actorName}</strong> updated <strong>{auditLogEntry.targetName}</strong>
        </Text>
      )
    }

    case AdminAuditLogActionType.PLAYER_STATS_DELETE: {
      return (
        <Text>
          <strong>{auditLogEntry.actorName}</strong> cleared the stats of <strong>{auditLogEntry.targetName}</strong>
        </Text>
      )
    }

    case AdminAuditLogActionType.PLAYER_MFA_DISABLE: {
      return (
        <Text>
          <strong>{auditLogEntry.actorName}</strong> disabled MFA for <strong>{auditLogEntry.targetName}</strong>
        </Text>
      )
    }

    case AdminAuditLogActionType.PAYMENT_REFUND: {
      return (
        <Text>
          <strong>{auditLogEntry.actorName}</strong> refunded a payment for <strong>{auditLogEntry.targetName}</strong>
        </Text>
      )
    }

    case AdminAuditLogActionType.PUNISHMENT_WHITELIST_CREATE: {
      invariant(auditLogEntry.version === 2, 'Audit log entry version must be 2')
      return (
        <Text>
          <strong>{auditLogEntry.actorName}</strong> unlinked <strong>{auditLogEntry.targetName}</strong> from{' '}
          <strong>{auditLogEntry.changes.find(value => value.property === 'xuid')?.previousValue}</strong>
        </Text>
      )
    }

    case AdminAuditLogActionType.PLAYER_ALT_UNLINK: {
      invariant(auditLogEntry.version === 2, 'Audit log entry version must be 2')
      return (
        <Text>
          <strong>{auditLogEntry.actorName}</strong> unlinked <strong>{auditLogEntry.targetName}</strong> from{' '}
          <strong>{auditLogEntry.changes.find(value => value.property === 'altXuid')?.previousValue}</strong>
        </Text>
      )
    }

    case AdminAuditLogActionType.PLAYER_SYSTEM_MESSAGE: {
      return (
        <Text>
          <strong>{auditLogEntry.actorName}</strong> sent a system message to{' '}
          <strong>{auditLogEntry.targetName}</strong>
        </Text>
      )
    }
  }
}

function AdminAuditLogChangesV1({auditLogEntry}: {auditLogEntry: AdminAuditLogEntry}): React.JSX.Element {
  invariant(auditLogEntry.version === 1, 'Audit log entry version must be 1')
  return (
    <Stack spacing="4px">
      {Object.entries(auditLogEntry.changes.added).map(([key, value]) => (
        <Stack align="center" direction="row" key={key}>
          <PlusIcon color="var(--chakra-colors-green-400)" height={16} width={16} />
          <Text>
            Set <strong>{key}</strong> to <strong>{JSON.stringify(value)}</strong>
          </Text>
        </Stack>
      ))}

      {Object.entries(auditLogEntry.changes.deleted).map(([key, value]) => (
        <Stack align="center" direction="row" key={key}>
          <MinusIcon color="var(--chakra-colors-red-400)" height={16} width={16} />
          <Text>
            Deleted the <strong>{key}</strong> with value <strong>{JSON.stringify(value)}</strong>
          </Text>
        </Stack>
      ))}

      {Object.entries(auditLogEntry.changes.updated).map(([key, value]) => (
        <Stack align="center" direction="row" key={key}>
          <PencilSquareIcon color="var(--chakra-colors-yellow-400)" height={16} width={16} />
          <Text>
            Updated the <strong>{key}</strong> to <strong>{JSON.stringify(value)}</strong>
          </Text>
        </Stack>
      ))}
    </Stack>
  )
}

function AdminAuditLogChangeStatusIcon({status}: {status: AdminAuditLogChangeStatus}): React.JSX.Element {
  switch (status) {
    case AdminAuditLogChangeStatus.ADDED: {
      return <PlusIcon color="var(--chakra-colors-green-400)" height={16} width={16} />
    }
    case AdminAuditLogChangeStatus.DELETED: {
      return <MinusIcon color="var(--chakra-colors-red-400)" height={16} width={16} />
    }
    case AdminAuditLogChangeStatus.UPDATED: {
      return <PencilSquareIcon color="var(--chakra-colors-yellow-400)" height={16} width={16} />
    }
  }
}

const PLAYER_FLAG_PROPS = new Set(['flags', 'apiPermissions'])
const PLAYER_IGNORED_PROPS = new Set(['cosmetics'])
const PLAYER_RANKS_PROP = 'rank' // comma-separated list of ranks

function AdminAuditLogChangeDescription({change}: {change: AdminAuditLogChange}): React.JSX.Element {
  switch (change.status) {
    case AdminAuditLogChangeStatus.ADDED: {
      return (
        <Text>
          Set <strong>{change.property}</strong> to <strong>{JSON.stringify(change.currentValue)}</strong>
        </Text>
      )
    }

    case AdminAuditLogChangeStatus.DELETED: {
      return (
        <Text>
          Deleted the <strong>{change.property}</strong> with value{' '}
          <strong>{JSON.stringify(change.previousValue)}</strong>
        </Text>
      )
    }

    case AdminAuditLogChangeStatus.UPDATED: {
      return (
        <Text>
          Changed the <strong>{change.property}</strong> from <strong>{JSON.stringify(change.previousValue)}</strong> to{' '}
          <strong>{JSON.stringify(change.currentValue)}</strong>
        </Text>
      )
    }
  }
}

function AdminAuditLogChangeFlagsRenderer({change}: {change: AdminAuditLogChange}): React.JSX.Element {
  const allFlags = change.property === 'flags' ? PlayerFlagsDatabase : AdminAPIPermissions
  return (
    <Stack direction="column">
      {Object.entries(allFlags).map(([flagName, flag]) => {
        const flagAdded = hasFlag(change.currentValue, flag)
        const flagRemoved = hasFlag(change.previousValue, flag)
        if ((flagAdded && flagRemoved) || (!flagAdded && !flagRemoved)) {
          return null
        }

        return (
          <Stack align="center" direction="row" key={flagName}>
            <AdminAuditLogChangeStatusIcon
              status={flagAdded ? AdminAuditLogChangeStatus.ADDED : AdminAuditLogChangeStatus.DELETED}
            />
            <Text>
              {flagAdded ? 'Added' : 'Removed'} the <strong>{flagName}</strong> flag
            </Text>
          </Stack>
        )
      })}
    </Stack>
  )
}

function AdminAuditLogChangeRanksRenderer({change}: {change: AdminAuditLogChange}): React.JSX.Element {
  const previousRanks = change.previousValue.split(',').filter(Boolean) as string[]
  const currentRanks = change.currentValue.split(',').filter(Boolean) as string[]
  const addedRanks = currentRanks.filter(rank => !previousRanks.includes(rank))
  const removedRanks = previousRanks.filter(rank => !currentRanks.includes(rank))

  return (
    <Stack spacing="4px">
      {addedRanks.map(rank => (
        <Stack align="center" direction="row" key={rank}>
          <AdminAuditLogChangeStatusIcon status={AdminAuditLogChangeStatus.ADDED} />
          <Text>
            Added the <strong>{rank}</strong> rank
          </Text>
        </Stack>
      ))}
      {removedRanks.map(rank => (
        <Stack align="center" direction="row" key={rank}>
          <AdminAuditLogChangeStatusIcon status={AdminAuditLogChangeStatus.DELETED} />
          <Text>
            Removed the <strong>{rank}</strong> rank
          </Text>
        </Stack>
      ))}
    </Stack>
  )
}

function AdminAuditLogChangeRenderer({
  auditLogEntry,
  change,
}: {
  auditLogEntry: AdminAuditLogEntry
  change: AdminAuditLogChange
}): React.JSX.Element | null {
  if (PLAYER_IGNORED_PROPS.has(change.property)) return null
  return (
    <Stack direction="column">
      {change.property === PLAYER_RANKS_PROP ? (
        <Stack direction="column">
          <Stack align="center" direction="row">
            <AdminAuditLogChangeStatusIcon status={AdminAuditLogChangeStatus.UPDATED} />
            <Text>Changed the ranks</Text>
          </Stack>
          <AdminAuditLogChangeRanksRenderer change={change} />
        </Stack>
      ) : (
        <Stack align="center" direction="row">
          <AdminAuditLogChangeStatusIcon status={change.status} />
          <AdminAuditLogChangeDescription change={change} />
        </Stack>
      )}

      {auditLogEntry.actionType === AdminAuditLogActionType.PLAYER_UPDATE && PLAYER_FLAG_PROPS.has(change.property) && (
        <AdminAuditLogChangeFlagsRenderer change={change} />
      )}
    </Stack>
  )
}

function AdminAuditLogChangesV2({auditLogEntry}: {auditLogEntry: AdminAuditLogEntry}): React.JSX.Element {
  invariant(auditLogEntry.version === 2, 'Audit log entry version must be 2')
  return (
    <Stack spacing="8px">
      {auditLogEntry.changes.map(change => (
        <AdminAuditLogChangeRenderer auditLogEntry={auditLogEntry} change={change} key={change.property} />
      ))}
    </Stack>
  )
}

function AdminAuditLogChanges({auditLogEntry}: {auditLogEntry: AdminAuditLogEntry}): React.JSX.Element {
  switch (auditLogEntry.version) {
    case 1: {
      return <AdminAuditLogChangesV1 auditLogEntry={auditLogEntry} />
    }
    case 2: {
      return <AdminAuditLogChangesV2 auditLogEntry={auditLogEntry} />
    }
    default: {
      return <Text>Unknown audit log entry version</Text>
    }
  }
}

function AuditLogEntry({auditLogEntry}: {auditLogEntry: AdminAuditLogEntry}): React.JSX.Element {
  const [showChanges, setShowChanges] = React.useState(false)
  return (
    <Stack
      align="center"
      bgColor="gray.900"
      boxShadow="lg"
      direction="row"
      fontSize="sm"
      letterSpacing="tight"
      overflow="hidden"
      rounded="lg"
      userSelect="text"
    >
      <Stack direction="column" flex="1" spacing="16px">
        <Stack
          align="center"
          bgColor="gray.800"
          cursor="pointer"
          direction="row"
          justify="space-between"
          onClick={() => setShowChanges(!showChanges)}
          p={4}
          spacing="16px"
          tabIndex={0}
        >
          <Stack direction="column" spacing="4px">
            <AuditLogEntryDescription auditLogEntry={auditLogEntry} />
            <Tooltip label={formatDistance(new Date(auditLogEntry.timestamp), Date.now(), {addSuffix: true})}>
              <Text fontWeight="light">{dateFormatter.format(new Date(auditLogEntry.timestamp))}</Text>
            </Tooltip>
          </Stack>

          {showChanges ? <ChevronUpIcon height={24} width={24} /> : <ChevronDownIcon height={24} width={24} />}
        </Stack>

        {showChanges && (
          <Stack p={4} pt={0}>
            <AdminAuditLogChanges auditLogEntry={auditLogEntry} />
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
