import type {APIStaffDirectoryEntry} from '@app/common/api'
import {useCurrentUser, useYoutubeDirectory} from '@app/common/api'
import {Rank, dateFormatter} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {isAdmin} from '@app/common/utils'
import {Avatar} from '@app/components/Avatar/Avatar'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {EditIcon} from '@chakra-ui/icons'
import {Box, Flex, Heading, IconButton, Link, Stack, Text} from '@chakra-ui/react'
import {LockClosedIcon, PaperAirplaneIcon} from '@heroicons/react/24/solid'
import {formatDistance} from 'date-fns'
import type React from 'react'
import {FaDiscord} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import {shallow} from 'zustand/shallow'

export default function YoutubeDirectoryModal(): React.JSX.Element {
  const [youtubeDirectoryOpen, setYoutubeDirectoryOpen] = useOverlayStore(
    state => [state.youtubeDirectoryOpen, state.setYoutubeDirectoryOpen],
    shallow,
  )
  return (
    <Modal header="Youtube Directory" isOpen={youtubeDirectoryOpen} onClose={() => setYoutubeDirectoryOpen(false)}>
      <YoutubeDirectoryModalContent />
    </Modal>
  )
}

function YoutubeDirectoryModalContent(): React.JSX.Element {
  const {data: directory} = useYoutubeDirectory()
  if (!directory)
    return (
      <ModalBody my={16}>
        <Spinner />
      </ModalBody>
    )

  return (
    <ModalBody as={Stack} direction="column" gap="4px">
      {Object.entries(directory.staff).map(([group, staff]) => {
        if (staff.length === 0) return null
        return (
          <Stack direction="column" fontSize="sm" gap="4px" key={group}>
            <Heading size="sm">
              {group} — {Object.values(staff).length}
            </Heading>
            <Stack direction="column" fontSize="sm" gap="4px">
              {staff.map(entry => (
                <YoutubeDirectoryEntry entry={entry} key={entry.player} />
              ))}
            </Stack>
          </Stack>
        )
      })}
    </ModalBody>
  )
}

function YoutubeDirectoryEntry({entry}: {entry: APIStaffDirectoryEntry}): React.JSX.Element {
  const {data: user} = useCurrentUser()
  const navigate = useNavigate()
  const setAdminPlayer = useOverlayStore(state => state.setAdminPlayer)
  const setAdminSystemMessagePlayer = useOverlayStore(state => state.setAdminSystemMessagePlayer)
  const setYoutubeDirectoryOpen = useOverlayStore(state => state.setYoutubeDirectoryOpen)

  return (
    <Flex align="center">
      <Avatar name={entry.player} online={entry.online} size={32} skinHash={entry.skinHash} />
      <Box flex="1" ml={3}>
        <Stack align="center" direction="row">
          <Link
            onClick={event => {
              if (event.metaKey || event.ctrlKey) {
                window.open(`/player/${entry.player}`, '_blank')
              } else {
                navigate(`/player/${entry.player}`)
                setYoutubeDirectoryOpen(false)
              }
            }}
          >
            <Heading fontSize="md">{entry.player}</Heading>
          </Link>
          {entry.discordId && entry.discordTag && (
            <Tooltip label={`Open ${entry.discordTag} (desktop app only)`} padding={8}>
              <a href={`discord:/users/${entry.discordId}`}>
                <FaDiscord fill="#5865f2" size={16} />
              </a>
            </Tooltip>
          )}
          {entry.mfaEnabled && (
            <Tooltip label="MFA Enabled" padding={8}>
              <LockClosedIcon fill="var(--chakra-colors-green-300)" height={16} width={16} />
            </Tooltip>
          )}
        </Stack>
        {entry.online ? (
          <Text color="gray.400" fontSize="sm">
            Currently online at <strong>{entry.lastServer}</strong>
          </Text>
        ) : (
          <Text color="gray.400" fontSize="sm">
            Last seen at{' '}
            <Tooltip label={formatDistance(new Date(entry.lastSeen), Date.now(), {addSuffix: true})}>
              <strong>{dateFormatter.format(new Date(entry.lastSeen))}</strong>
            </Tooltip>
          </Text>
        )}
      </Box>
      <Stack direction="row">
        {(user?.ranks.includes(Rank.SUPERVISOR) || isAdmin(user)) && (
          <Box>
            <Tooltip label="Send System Message (Supervisor+)" padding={8}>
              <IconButton
                aria-label="Send System Message (Supervisor+)"
                icon={<PaperAirplaneIcon height={16} width={16} />}
                onClick={() => setAdminSystemMessagePlayer(entry.player)}
                rounded="full"
                size="sm"
              />
            </Tooltip>
          </Box>
        )}
        {isAdmin(user) && (
          <Box>
            <Tooltip label="Edit Player (Admin)" padding={8}>
              <IconButton
                aria-label="Edit Player (Admin)"
                icon={<EditIcon />}
                onClick={() => setAdminPlayer(entry.player)}
                rounded="full"
                size="sm"
              />
            </Tooltip>
          </Box>
        )}
      </Stack>
    </Flex>
  )
}
