import {useRankTierSummary} from '@app/common/api'
import {numberFormatter} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {Heading, Stack, Text} from '@chakra-ui/react'
import type React from 'react'
import {shallow} from 'zustand/shallow'

export default function RankTierSummaryModal(): React.JSX.Element {
  const [summaryOpen, setSummaryOpen] = useOverlayStore(
    state => [state.rankTierSummaryOpen, state.setRankTierSummaryOpen],
    shallow,
  )

  return (
    <Modal header="Tiers Summary" isOpen={summaryOpen} onClose={() => setSummaryOpen(false)}>
      <RankTierSummaryModalContent />
    </Modal>
  )
}

function RankTierSummaryModalContent(): React.JSX.Element {
  const {data: summary} = useRankTierSummary()
  return (
    <ModalBody as={Stack} fontSize="md" gap="4px" userSelect="text">
      <Stack>
        <Heading fontSize="md">Tiers</Heading>
        {Object.entries(summary?.tiers ?? {}).map(([tier, count]) => (
          <Stack align="center" direction="row" key={tier}>
            <Text flex="1" fontSize="sm" mb={0}>
              {tier}
            </Text>
            <Text flex="1" fontSize="sm" mb={0} textAlign="right">
              {numberFormatter.format(count)}
            </Text>
          </Stack>
        ))}
      </Stack>
    </ModalBody>
  )
}
