import {usePlayerAlts, usePlayerIPAltSummary, usePunishmentsBulk} from '@app/common/api'
import {useOverlayStore} from '@app/common/stores'
import {RelationshipPlayer} from '@app/components/Friend/FriendModal'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Button, Checkbox, Heading, Input, Stack, Text} from '@chakra-ui/react'
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid'
import type {TCountryCode} from 'countries-list'
import {getEmojiFlag} from 'countries-list'
import {matchSorter} from 'match-sorter'
import React from 'react'
import {useCopyToClipboard} from 'usehooks-ts'
import {shallow} from 'zustand/shallow'

export default function PlayerAltsModal(): React.JSX.Element {
  const [player, setPlayer] = useOverlayStore(state => [state.adminAltsPlayer, state.setAdminAltsPlayer], shallow)
  return (
    <Modal
      header={player ? `Alts for '${player}'` : 'Player Alts'}
      isOpen={Boolean(player)}
      onClose={() => setPlayer(null)}
    >
      <PlayerAltsModalContent player={player!} />
    </Modal>
  )
}

function CopyButton({alts}: {alts: string[]}): React.JSX.Element {
  const [copiedValue, copy] = useCopyToClipboard()
  return (
    <Button
      leftIcon={
        copiedValue ? (
          <ClipboardDocumentCheckIcon height={16} width={16} />
        ) : (
          <ClipboardDocumentIcon height={16} width={16} />
        )
      }
      onClick={async () => copy(`"${alts.join('", "')}"`)}
      size="sm"
      variant="ghost"
    >
      {copiedValue ? 'Copied' : 'Copy'}
    </Button>
  )
}

function PlayerAltsModalContent({player}: {player: string}): React.JSX.Element {
  const [groupBy, setGroupBy] = React.useState<'ip' | 'location'>('location')
  const [showIpAlts, setShowIpAlts] = React.useState(false)
  const [filter, setFilter] = React.useState('')
  const {data: alts} = usePlayerAlts(player)
  const {data: ipAltSummary} = usePlayerIPAltSummary(player, groupBy)
  const {data: punishments} = usePunishmentsBulk(alts?.map(alt => alt.xuid) ?? [])
  const setPlayer = useOverlayStore(state => state.setAdminAltsPlayer)
  const shouldShowIpAlts = (alts && alts.filter(alt => alt.type === 'IP').length < 100) || showIpAlts
  const filteredData = matchSorter(alts ?? [], filter, {
    keys: ['player', 'xuid'],
    sorter: rankedItems => rankedItems,
  })

  if (!alts)
    return (
      <ModalBody align="center" as={Stack} h="full" justify="center" my={16}>
        <Spinner />
      </ModalBody>
    )

  const deviceIdAlts = filteredData.filter(alt => alt.type === 'DEVICE_ID')
  const selfSignedIdAlts = filteredData.filter(alt => alt.type === 'SELF_SIGNED_ID')
  const ipAlts = filteredData.filter(alt => alt.type === 'IP')

  return (
    <ModalBody as={Stack} gap="8px" mb={1}>
      <Stack gap="4px">
        <Checkbox
          isChecked={groupBy === 'location'}
          onChange={event => setGroupBy(event.target.checked ? 'location' : 'ip')}
        >
          Group IP alts by location rather than IP
        </Checkbox>

        <Text color="red.300" fontSize="sm" fontWeight="semibold" letterSpacing="tight">
          WARNING: Unchecking this box may cause your browser to freeze or crash if there are too many unique IP
          addresses.
        </Text>
      </Stack>

      <Stack>
        <SmallHeading>Fuzzy Search</SmallHeading>
        <Input
          onChange={event => setFilter(event.target.value)}
          placeholder="Enter a player name or XUID"
          rounded="md"
          size="sm"
        />
      </Stack>

      <Stack spacing="16px">
        {deviceIdAlts.length === 0 && selfSignedIdAlts.length === 0 && ipAlts.length === 0 && (
          <Stack align="center" h="full" justify="center" py={16} textAlign="center">
            <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
            <Heading fontSize="2xl" fontWeight="bold">
              *cricket noises*
            </Heading>
            <Text color="whiteAlpha.800" fontSize="md">
              No alts found. Like, where's the caveman?
            </Text>
          </Stack>
        )}

        {deviceIdAlts.length > 0 && (
          <Stack spacing="4px">
            <Stack align="center" direction="row" justify="space-between" spacing="4px">
              <SmallHeading>Device ID — {deviceIdAlts.length}</SmallHeading>
              <CopyButton alts={deviceIdAlts.map(alt => alt.player)} />
            </Stack>
            <Stack spacing="8px">
              {deviceIdAlts.map(alt => (
                <RelationshipPlayer
                  key={alt.xuid}
                  onClose={() => setPlayer(null)}
                  originPlayer={player}
                  punishment={punishments?.[alt.xuid] ?? null}
                  relationship={alt}
                />
              ))}
            </Stack>
          </Stack>
        )}

        {selfSignedIdAlts.length > 0 && (
          <Stack spacing="4px">
            <Stack align="center" direction="row" justify="space-between" spacing="4px">
              <SmallHeading>Self-Signed ID — {selfSignedIdAlts.length}</SmallHeading>
              <CopyButton alts={selfSignedIdAlts.map(alt => alt.player)} />
            </Stack>
            <Stack spacing="8px">
              {selfSignedIdAlts.map(alt => (
                <RelationshipPlayer
                  key={alt.xuid}
                  onClose={() => setPlayer(null)}
                  originPlayer={player}
                  punishment={punishments?.[alt.xuid] ?? null}
                  relationship={alt}
                />
              ))}
            </Stack>
          </Stack>
        )}

        {ipAlts.length > 0 && (
          <Stack spacing="4px">
            <Stack
              align="center"
              bgColor="red.500"
              direction="column"
              letterSpacing="tight"
              p={4}
              rounded="md"
              textAlign="center"
            >
              <Stack align="center" direction="row" justify="center">
                <ExclamationTriangleIcon height={24} width={24} />
                <Heading size="sm" textTransform="uppercase">
                  IP alts are not always reliable!
                </Heading>
              </Stack>
              <Text fontSize="sm" fontWeight="normal">
                Many IP alts typically indicate a shared IP, used by some ISPs, or a VPN. In that case, you should only
                use these results if you find similar shared names. If the player has relatively few IP alts or you find
                name similarities, they are probably alts.
              </Text>
            </Stack>

            <Stack align="center" direction="row" justify="space-between" spacing="4px">
              <SmallHeading>IP — {ipAlts.length}</SmallHeading>
              <CopyButton alts={ipAlts.map(alt => alt.player)} />
            </Stack>

            {ipAlts.length >= 100 && (
              <Stack pb={3}>
                <Text color="whiteAlpha.800" fontSize="sm" fontWeight="bold" textAlign="center">
                  Whew, that's a lot of IP alts. Read the warning above before using these results. Beware that your
                  browser may freeze or crash if you try to render too many alts.
                </Text>
                <Button onClick={() => setShowIpAlts(!showIpAlts)} size="sm" variant="outline">
                  {showIpAlts ? 'Hide' : 'Show'} IP alts
                </Button>
              </Stack>
            )}

            {shouldShowIpAlts && (
              <Stack spacing="8px">
                {ipAltSummary ? (
                  Object.entries(ipAltSummary).map(([key, summaries]) => {
                    if (groupBy === 'ip')
                      return (
                        <Stack key={key} spacing="8px">
                          <SmallHeading>IP {key.slice(0, 10)}</SmallHeading>
                          <Stack>
                            {summaries.map(summary => (
                              <Stack key={summary.key} spacing="8px">
                                <SmallHeading>
                                  {getEmojiFlag(summary.countryCode as TCountryCode)} {summary.city}, {summary.country}
                                </SmallHeading>
                                <Stack spacing="8px">
                                  {ipAlts
                                    .filter(alt => summary.xuids.includes(alt.xuid))
                                    .map(alt => (
                                      <RelationshipPlayer
                                        key={alt.xuid}
                                        onClose={() => setPlayer(null)}
                                        originPlayer={player}
                                        punishment={punishments?.[alt.xuid] ?? null}
                                        relationship={alt}
                                      />
                                    ))}
                                </Stack>
                              </Stack>
                            ))}
                          </Stack>
                        </Stack>
                      )

                    return (
                      <Stack key={key} spacing="8px">
                        <SmallHeading>
                          {getEmojiFlag(summaries[0]!.countryCode as TCountryCode)} {summaries[0]!.city},{' '}
                          {summaries[0]!.country}
                        </SmallHeading>
                        <Stack>
                          {summaries.map(summary => (
                            <Stack key={summary.key} spacing="8px">
                              {ipAlts
                                .filter(alt => summary.xuids.includes(alt.xuid))
                                .map(alt => (
                                  <RelationshipPlayer
                                    key={alt.xuid}
                                    onClose={() => setPlayer(null)}
                                    originPlayer={player}
                                    punishment={punishments?.[alt.xuid] ?? null}
                                    relationship={alt}
                                  />
                                ))}
                            </Stack>
                          ))}
                        </Stack>
                      </Stack>
                    )
                  })
                ) : (
                  <Stack align="center" h="full" justify="center" py={16} textAlign="center">
                    <Spinner />
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </ModalBody>
  )
}
