import type {FormType, PromotionType} from '@app/common/constants'
import {PromotionTypeToBackground} from '@app/common/constants'
import {api} from '@app/hooks/useApi'
import {create} from 'zustand'
import {devtools, persist} from 'zustand/middleware'

export enum ExperimentId {
  COLLAGE_CLIPS = '2023-04_collage_clips',
  EVIDENCE_V2 = '2023-02_evidence_v2',
  GUILD_ICONS = '2023-02_guild_icons',
  LINKED_ACCOUNTS_APPEAL = '2023-04_linked_accounts_appeal',
  PAUSE_FORM_SUBMISSIONS = '2023-02_pause_form_submissions',
  PORTAL_NAGBAR = '2023-01_portal_nagbar',
  PROMO_BACKGROUND = '2023-01_promo_background',
  PROMO_LOCAL_OVERRIDE = '2022-12_promo_local_override',
  REGIONAL_PAYMENT_METHODS = '2022-12_regional_payment_methods',
  STRIPE_EXPRESS_CHECKOUT_ELEMENT = '2023-02_stripe_express_checkout_element',
  UNRELEASED_PRODUCTS = '2023-01_unreleased_products',
  BUILDING_COMPETITION = '2024-04_building_competition',
}

export class Bucket<ConfigType = unknown> {
  public id: string
  public default: boolean
  public title: string
  public config: ConfigType

  public constructor(id: string, isDefault: boolean, title: string, config: ConfigType) {
    this.id = id
    this.default = isDefault
    this.title = title
    this.config = config
  }
}

export class Experiment<ConfigType = unknown> {
  public id: ExperimentId
  public title: string
  public buckets: Bucket<ConfigType>[] | [Bucket<ConfigType>]
  public bucketOverride: string | null

  public constructor(id: ExperimentId, title: string, buckets: Bucket<ConfigType>[], bucketOverride: string | null) {
    this.id = id
    this.title = title
    this.buckets = buckets
    this.bucketOverride = bucketOverride
  }
}

type ExperimentStore = {
  experiments: Experiment[]
  setExperiments(experiments: Experiment[]): void
  setExperimentOverride(experimentId: ExperimentId, bucketId: string | null): void
  loadExperiments(): Promise<void>
}

export const useExperimentStore = create<ExperimentStore>()(
  persist(
    devtools((set, get) => ({
      experiments: [],
      setExperiments: experiments => set({experiments}),
      setExperimentOverride: (experimentId, bucketId) => {
        const experiments = get().experiments.map(experiment => {
          if (experiment.id === experimentId)
            return new Experiment(experiment.id, experiment.title, experiment.buckets, bucketId)
          return experiment
        })
        set({experiments})
      },
      loadExperiments: async () => {
        const {data} = await api.get<Experiment[]>('experiments')
        set({
          experiments: data.map(experiment => {
            const existingExperiment = get().experiments.find(exp => exp.id === experiment.id)
            return new Experiment(
              experiment.id,
              experiment.title,
              experiment.buckets,
              existingExperiment?.bucketOverride ?? null,
            )
          }),
        })
      },
    })),
    {name: 'ExperimentStore', version: 1},
  ),
)

function useExperiment<ConfigType>(experimentId: ExperimentId): Experiment<ConfigType> | undefined {
  const experiments = useExperimentStore(state => state.experiments)
  return experiments.find(experiment => experiment.id === experimentId) as Experiment<ConfigType>
}

export function useEvidenceV2(): boolean {
  const experiment = useExperiment<{enabled: boolean}>(ExperimentId.EVIDENCE_V2)
  if (!experiment) return false
  const bucket = experiment.buckets.find(bucket => {
    return experiment.bucketOverride ? bucket.id === experiment.bucketOverride : bucket.default
  })
  return bucket?.config.enabled ?? false
}

export function usePauseFormSubmissions(): FormType[] {
  const experiment = useExperiment<{formTypes: FormType[]}>(ExperimentId.PAUSE_FORM_SUBMISSIONS)
  if (!experiment) return []
  const bucket = experiment.buckets.find(bucket => {
    return experiment.bucketOverride ? bucket.id === experiment.bucketOverride : bucket.default
  })
  return bucket?.config.formTypes ?? []
}

export function usePortalNagbar(): string | null {
  const experiment = useExperiment<{content: string}>(ExperimentId.PORTAL_NAGBAR)
  if (!experiment) return null
  const bucket = experiment.buckets.find(bucket => {
    return experiment.bucketOverride ? bucket.id === experiment.bucketOverride : bucket.default
  })
  return bucket?.config.content ?? null
}

export function usePromoBackground(): string | null {
  const experiment = useExperiment<{type: PromotionType}>(ExperimentId.PROMO_BACKGROUND)
  if (!experiment) return null
  const bucket = experiment.buckets.find(bucket => {
    return experiment.bucketOverride ? bucket.id === experiment.bucketOverride : bucket.default
  })
  return bucket?.config.type ? PromotionTypeToBackground[bucket.config.type] : null
}

export function useGuildIcons(): boolean {
  const experiment = useExperiment<{enabled: boolean}>(ExperimentId.GUILD_ICONS)
  if (!experiment) return false
  const bucket = experiment.buckets.find(bucket => {
    return experiment.bucketOverride ? bucket.id === experiment.bucketOverride : bucket.default
  })
  return bucket?.config.enabled ?? false
}

export function useCollageClips(): boolean {
  const experiment = useExperiment<{enabled: boolean}>(ExperimentId.COLLAGE_CLIPS)
  if (!experiment) return false
  const bucket = experiment.buckets.find(bucket => {
    return experiment.bucketOverride ? bucket.id === experiment.bucketOverride : bucket.default
  })
  return bucket?.config.enabled ?? false
}

export function useBuildCompetition(): boolean {
  const experiment = useExperiment<{enabled: boolean}>(ExperimentId.BUILDING_COMPETITION)
  if (!experiment) return false
  const bucket = experiment.buckets.find(bucket => {
    return experiment.bucketOverride ? bucket.id === experiment.bucketOverride : bucket.default
  })
  return bucket?.config.enabled ?? false
}

export function useLinkedAccountsAppeal(): boolean {
  const experiment = useExperiment<{enabled: boolean}>(ExperimentId.LINKED_ACCOUNTS_APPEAL)
  if (!experiment) return false
  const bucket = experiment.buckets.find(bucket => {
    return experiment.bucketOverride ? bucket.id === experiment.bucketOverride : bucket.default
  })
  return bucket?.config.enabled ?? false
}
