import type {APIFormSubmissionResponse, APIPlayer} from '@app/common/api'
import {useAPIFormSubmissionsAdmin, useCurrentUser} from '@app/common/api'
import {
  FormSubmissionStatus,
  FormSubmissionStatusToString,
  FormType,
  FormTypeToString,
  FormTypeToStringBase,
} from '@app/common/constants'
import {useOverlayStore} from '@app/common/stores'
import {getAvatarUrl} from '@app/common/utils'
import {Modal, ModalBody} from '@app/components/Modal/Modal'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {Submission} from '@app/components/Modal/Submission'
import {Spinner} from '@app/components/Spinner/Spinner'
import {useReplaceState} from '@app/hooks/useReplaceState'
import {Avatar, Checkbox, Heading, Stack, Text} from '@chakra-ui/react'
import {QuestionMarkCircleIcon} from '@heroicons/react/24/solid'
import type {GroupBase, OptionProps} from 'chakra-react-select'
import {Select, chakraComponents} from 'chakra-react-select'
import React from 'react'
import {shallow} from 'zustand/shallow'

const APPEAL_FORM_TYPES = new Set([FormType.SERVER_BAN_APPEAL, FormType.SERVER_MUTE_APPEAL])

export default function AdminRequestsModal(): React.JSX.Element {
  const [adminRequestsOpen, setAdminRequestsOpen] = useOverlayStore(
    state => [state.adminRequestsOpen, state.setAdminRequestsOpen],
    shallow,
  )

  return (
    <Modal header="Requests" isOpen={adminRequestsOpen} onClose={() => setAdminRequestsOpen(false)}>
      <AdminRequestsModalContent />
    </Modal>
  )
}

type FormTypeOption = {
  label: string
  value: FormType
}

const FORM_TYPE_OPTIONS = Object.entries(FormTypeToStringBase).map(([key, value]) => ({
  label: value,
  value: Number(key) as FormType,
}))

type StatusOption = {
  label: string
  value: FormSubmissionStatus
}

const STATUS_OPTIONS = Object.entries(FormSubmissionStatusToString).map(([key, value]) => ({
  label: value,
  value: Number(key) as FormSubmissionStatus,
}))

type PlayerOption = {
  label: string
  value: string
  skinHash: string | null
}

const DEFAULT_STATUSES = new Set([FormSubmissionStatus.ESCALATED, FormSubmissionStatus.PENDING])
export const DEFAULT_STATUS_VALUE = STATUS_OPTIONS.map(option => option.value).filter(option =>
  DEFAULT_STATUSES.has(option),
)

function PlayerSelectOption(props: OptionProps<PlayerOption>): React.JSX.Element {
  return (
    <chakraComponents.Option {...props}>
      <Stack align="center" direction="row" fontSize="sm" letterSpacing="tight" spacing="1.5">
        <Avatar name={props.data.label} size="xs" src={getAvatarUrl(props.data.skinHash)} />
        <Text fontWeight="semibold">{props.data.label}</Text>
      </Stack>
    </chakraComponents.Option>
  )
}

export function PlayerSelect({
  label,
  onChange,
  value,
  players,
}: {
  label: string
  onChange(value: string[]): void
  value: string[]
  players: Pick<APIPlayer, 'name' | 'skinHash' | 'xuid'>[]
}): React.JSX.Element {
  return (
    <Stack>
      <SmallHeading>{label}</SmallHeading>
      <Select<PlayerOption, false, GroupBase<PlayerOption>>
        className="react-select"
        classNamePrefix="react-select"
        components={{Option: PlayerSelectOption}}
        defaultValue={
          value
            .map(value => {
              const player = players.find(player => player.xuid === value)
              if (!player) return null
              return {label: player.name, value: player.xuid, skinHash: player.skinHash}
            })
            .filter(Boolean) as PlayerOption[]
        }
        isClearable
        menuPortalTarget={document.body}
        onChange={option => {
          if (!option) {
            onChange([])
            return
          }

          if (value.includes(option.value)) onChange(value.filter(value => value !== option.value))
          else onChange([...value, option.value])
        }}
        options={players.map(player => ({
          label: player.name,
          value: player.xuid,
          skinHash: player.skinHash,
        }))}
        selectedOptionStyle="check"
        size="sm"
        useBasicStyles
      />
    </Stack>
  )
}

function AdminRequestsModalContent(): React.JSX.Element {
  const setRequestId = useOverlayStore(state => state.setRequestId)
  const {
    formType,
    offenderXuid,
    playerXuid,
    reviewedBy,
    setFormType,
    setOffenderXuid,
    setPlayerXuid,
    setReviewedBy,
    setStatus,
    status,
  } = useOverlayStore(state => ({
    formType: state.adminRequestsType,
    offenderXuid: state.adminRequestsOffenderXuid,
    playerXuid: state.adminRequestsPlayerXuid,
    reviewedBy: state.adminRequestsReviewedBy,
    setFormType: state.setAdminRequestsType,
    setOffenderXuid: state.setAdminRequestsOffenderXuid,
    setPlayerXuid: state.setAdminRequestsPlayerXuid,
    setReviewedBy: state.setAdminRequestsReviewedBy,
    setStatus: state.setAdminRequestsStatus,
    status: state.adminRequestsStatus,
  }))
  const {data: user} = useCurrentUser()
  const [includeMyPunishments, setIncludeMyPunishments] = React.useState(true)
  const [punisherXuids, setPunisherXuids] = React.useState<string[]>([])
  const {data: response} = useAPIFormSubmissionsAdmin({
    formType,
    status,
    playerXuid,
    offenderXuid,
    reviewedBy,
    punisherXuids,
  })
  useReplaceState('/admin/requests')

  if (!response)
    return (
      <ModalBody align="center" as={Stack} h="full" justify="center" my={16}>
        <Spinner />
      </ModalBody>
    )

  const categories = response.submissions.reduce<Record<string, APIFormSubmissionResponse[]>>((acc, submission) => {
    const category = FormTypeToString[submission.formType]
    if (!acc[category]) acc[category] = []
    acc[category]?.push(submission)
    return acc
  }, {})

  return (
    <ModalBody as={Stack} gap="8px" h="full" overflowY="auto">
      <Stack gap="4px">
        <Checkbox isChecked={includeMyPunishments} onChange={event => setIncludeMyPunishments(event.target.checked)}>
          Include appeals for my punishments
        </Checkbox>
      </Stack>

      <Stack>
        <SmallHeading>Form Type</SmallHeading>
        <Select<FormTypeOption, false, GroupBase<FormTypeOption>>
          className="react-select"
          classNamePrefix="react-select"
          defaultValue={[]}
          isClearable
          menuPortalTarget={document.body}
          onChange={option => {
            if (!option) {
              setFormType([])
              return
            }

            if (formType.includes(option.value)) setFormType(formType.filter(value => value !== option.value))
            else setFormType([...formType, option.value])
          }}
          options={FORM_TYPE_OPTIONS}
          selectedOptionStyle="check"
          size="sm"
          useBasicStyles
          value={FORM_TYPE_OPTIONS.filter(option => formType.includes(option.value))}
        />
      </Stack>

      <Stack>
        <SmallHeading>Status</SmallHeading>
        <Select<StatusOption, false, GroupBase<StatusOption>>
          className="react-select"
          classNamePrefix="react-select"
          defaultValue={status.map(value => STATUS_OPTIONS.find(option => option.value === value)!)}
          isClearable
          menuPortalTarget={document.body}
          onChange={option => {
            if (!option) {
              setStatus([])
              return
            }

            if (status.includes(option.value)) setStatus(status.filter(value => value !== option.value))
            else setStatus([...status, option.value])
          }}
          options={STATUS_OPTIONS}
          selectedOptionStyle="check"
          size="sm"
          useBasicStyles
          value={STATUS_OPTIONS.filter(option => status.includes(option.value))}
        />
      </Stack>

      <PlayerSelect label="Player" onChange={setPlayerXuid} players={response.players} value={playerXuid} />
      {response.offenders.length > 0 && (
        <PlayerSelect label="Offender" onChange={setOffenderXuid} players={response.offenders} value={offenderXuid} />
      )}
      {response.reviewers.length > 0 && (
        <PlayerSelect label="Reviewer" onChange={setReviewedBy} players={response.reviewers} value={reviewedBy} />
      )}
      {response.punishers.length > 0 && (
        <PlayerSelect label="Punisher" onChange={setPunisherXuids} players={response.punishers} value={punisherXuids} />
      )}

      {response.submissions.length === 0 && (
        <Stack align="center" h="full" justify="center" py={16} textAlign="center">
          <QuestionMarkCircleIcon color="var(--chakra-colors-orange-200)" height={75} width={75} />
          <Heading fontSize="2xl" fontWeight="bold">
            *cricket noises*
          </Heading>
          <Text color="whiteAlpha.800" fontSize="md">
            No requests found for the selected filter.
          </Text>
        </Stack>
      )}

      <Stack spacing="16px">
        {Object.entries(categories)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([category, submissions]) => (
            <Stack key={category} spacing="8px">
              <SmallHeading>
                {category} — {submissions.length}
              </SmallHeading>
              <Stack spacing="12px">
                {submissions
                  .filter(submission => {
                    if (!APPEAL_FORM_TYPES.has(submission.formType)) return true
                    if (!includeMyPunishments) return !submission.punisherXuids?.includes(user?.xuid ?? '')
                    return true
                  })
                  .map(submission => (
                    <Submission
                      key={submission.id}
                      onClick={() => setRequestId(submission.id)}
                      player={response.players.find(player => player.xuid === submission.playerXuid)!}
                      reviewer={response.reviewers.find(reviewer => reviewer.xuid === submission.reviewedBy)!}
                      submission={submission}
                    />
                  ))}
              </Stack>
            </Stack>
          ))}
      </Stack>
    </ModalBody>
  )
}
